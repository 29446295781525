import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { InputField } from '@profesia/adamui/components/common/input';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_CONTACT_PERSON_ACADEMIC_DEGREE, FORM_CONTACT_PERSON_FIRST_NAME, FORM_CONTACT_PERSON_LAST_NAME, FORM_CONTACT_PERSON_EMAIL, FORM_CONTACT_PERSON_PHONE, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { ACADEMIC_DEGREE_MAX_LENGTH, FIRST_NAME_MAX_LENGTH, LAST_NAME_MAX_LENGTH, EMAIL_MAX_LENGTH, PHONE_MAX_LENGTH, } from '@profesia/mark-offer-form/components/content/form/constants';
const ContactForm = () => {
    const t = useGetFixedT('contactDetails');
    const { setValue, control, formState: { errors }, } = useFormContext();
    const academicDegree = useWatch({
        name: FORM_CONTACT_PERSON_ACADEMIC_DEGREE,
        control,
    });
    const firstName = useWatch({
        name: FORM_CONTACT_PERSON_FIRST_NAME,
        control,
    });
    const lastName = useWatch({
        name: FORM_CONTACT_PERSON_LAST_NAME,
        control,
    });
    const email = useWatch({
        name: FORM_CONTACT_PERSON_EMAIL,
        control,
    });
    const phone = useWatch({
        name: FORM_CONTACT_PERSON_PHONE,
        control,
    });
    return (_jsxs(Grid, { container: true, spacing: 2, "data-testid": "contact-details-form", children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Grid, { container: true, spacing: 2, children: _jsx(Grid, { item: true, xs: 6, children: _jsx(InputField, { label: t('academicDegree.label'), value: academicDegree, inputProps: { maxLength: ACADEMIC_DEGREE_MAX_LENGTH }, onChange: ({ target }) => setValue(FORM_CONTACT_PERSON_ACADEMIC_DEGREE, target.value) }) }) }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(InputField, { label: t('firstName.label'), value: firstName, inputProps: { maxLength: FIRST_NAME_MAX_LENGTH }, onChange: ({ target }) => setValue(FORM_CONTACT_PERSON_FIRST_NAME, target.value) }) }), _jsx(Grid, { item: true, xs: 12, md: 6, children: _jsx(InputField, { label: t('lastName.label'), value: lastName, inputProps: { maxLength: LAST_NAME_MAX_LENGTH }, onChange: ({ target }) => setValue(FORM_CONTACT_PERSON_LAST_NAME, target.value) }) }), _jsx(Grid, { item: true, xs: 12, "data-testid": "contact-person-email-field-wrapper", children: _jsx(InputField, { label: t('email.label'), value: email, inputProps: { maxLength: EMAIL_MAX_LENGTH }, onChange: ({ target }) => setValue(FORM_CONTACT_PERSON_EMAIL, target.value), type: "email", isError: Boolean(errors.contactPerson?.email), bottomLabel: errors.contactPerson?.email?.message, inputFieldLabelProps: { required: true } }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(InputField, { label: t('phone.label'), value: phone, inputProps: { maxLength: PHONE_MAX_LENGTH }, onChange: ({ target }) => setValue(FORM_CONTACT_PERSON_PHONE, target.value) }) })] }));
};
export default ContactForm;
