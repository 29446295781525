import ReplaceChannelStrings from './../translations/replaceChannelStrings';
import MultiChannelStrings from './../translations/multiChannelStrings';

const CHANNEL_SK: number = 1;
const CHANNEL_CZ: number = 3;

class Channel {
    static get CHANNEL_SK(): number {
        return CHANNEL_SK;
    }

    static get CHANNEL_CZ(): number {
        return CHANNEL_CZ;
    }

    static getChannel(): number {
        if (window.location.hostname.indexOf('profesia.cz') !== -1) {
            return CHANNEL_CZ;
        }

        return CHANNEL_SK;
    }

    static replaceCompleteStringByChannel(stringKey: string): string {
        if (MultiChannelStrings.indexOf(stringKey) > -1) {
            switch (Channel.getChannel()) {
                case CHANNEL_CZ:
                    return stringKey + 'Cz';

                default:
                    return stringKey;
            }
        }

        return stringKey;
    }

    static replaceSubstringInStringByChannel(stringKey: string, string: string): string {
        let replace: string[] = [];

        const search: string[] = ['Profesia.sk', 'profesia.sk', 'Profesia', 'profesia'];

        if (ReplaceChannelStrings.indexOf(stringKey) > -1) {
            switch (Channel.getChannel()) {
                case CHANNEL_CZ:
                    replace = ['Profesia.cz', 'profesia.cz', 'Profesia', 'profesia'];

                    for (let i = 0; i < search.length; i++) {
                        if (search[i] && replace[i]) {
                            string = string.replace(search[i] ?? '', replace[i] ?? '');
                        }
                    }

                    return string;

                default:
                    return string;
            }
        }

        return string;
    }
}

export default Channel;
