import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Form from '../Form';
import Grid from '../Grid';
import Divider from '../Divider';
import Heading from '../Heading';
import PropTypes from 'prop-types';
import EmailEditor from '../Email/EmailEditor';
import TemplateAssistantSettings from '../TemplateAssistant/TemplateAssistantSettings';
import TemplateDropdownSelector from '../TemplateAssistant/TemplateDropdownSelector';
import {
    requestSendEmailModel,
    updateEmailTemplate,
    setEmailRecipients,
    setSendEmailAt,
    setEmailSender,
    setEmailBcc,
    getVideoInterviewData,
} from '../../actions/SendEmailActions';
import { fetchEmailTemplates } from '../../actions/TemplateDropdownSelectorActions';
import {
    saveAsChangedEmailTemplate,
    saveAsNewEmailTemplate,
} from '../../actions/TemplateAssistantActions';
import AttachmentAssistant from '../AttachmentsAssistant/AttachmentsAssistant';
import MultiAttachmentAssistant from '../AttachmentsAssistant/MultiAttachmentAssistant';
import AttachmentAssistantHelper from '../../helpers/components/AttachmentAssistantHelper';
import Translations from '../../helpers/Translations';
import Language from '../../helpers/Language';
import Constants from '../../helpers/Constants';
import UserRights from '../../helpers/UserRights';
import User from '../../helpers/User';
import FormSkeleton from '../../skeletons/components/FormSkeleton';
import SendEmailActionsWrapper from './SendEmailActionsWrapper';
import SendEmailVideoInterviewInfo from './SendEmailVideoInterviewInfo';
import SendEmailQuestionnaireInfo from './SendEmailQuestionnaireInfo';

const SendEmail = (props) => {
    const {
        defaultEmailTemplateId,
        sendEmailModel,
        loginManagerModel,
        recipients,
        bcc,
        emailTemplateType,
        eventType,
        messageHistory,
        isMassForwarding,
        candidateIds,
        isFetchingSendEmailModel,
        isFetchingTemplatesForDropdownSelector,
        templateAssistant,
        isRemovingColleagueContact,
        visibleFields,
        errors,
        requestSendEmailModel,
        fetchEmailTemplates,
        updateEmailTemplate,
        saveAsChangedEmailTemplate,
        saveAsNewEmailTemplate,
        setEmailRecipients,
        setEmailBcc,
        setEmailSender,
        setSendEmailAt,
        getVideoInterviewData,
        isGettingVideoInterviewData,
        noSpacing,
    } = props;

    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState(
        defaultEmailTemplateId ?? sendEmailModel?.emailTemplateData?.id ?? null
    );

    const [languageId, setLanguageId] = useState(
        loginManagerModel.languages.find((language) => language.languageCode === Language.get())
            ?.languageId
    );

    const prevSendEmailModelRef = useRef(sendEmailModel);
    const prevIsFetchingSendEmailModelRef = useRef(isFetchingSendEmailModel);
    const prevEventTypeRef = useRef(eventType);

    useEffect(() => {
        if (
            sendEmailModel.hasOwnProperty('emailTemplateData') === false ||
            JSON.stringify(recipients) !== JSON.stringify(sendEmailModel.recipients) ||
            JSON.stringify(bcc) !== JSON.stringify(sendEmailModel.bcc)
        ) {
            requestSendEmailModel(
                selectedEmailTemplate,
                User.getUserId(loginManagerModel),
                recipients,
                bcc,
                languageId,
                false,
                emailTemplateType,
                messageHistory,
                isMassForwarding
            );
        }
    }, []);

    useEffect(() => {
        const prevSendEmailModel = prevSendEmailModelRef.current;
        const prevIsFetchingSendEmailModel = prevIsFetchingSendEmailModelRef.current;
        const prevEventType = prevEventTypeRef.current;

        const shouldRequestSendEmailModel =
            (prevSendEmailModel.hasOwnProperty('emailTemplateData') === false &&
                sendEmailModel.hasOwnProperty('emailTemplateData') === true &&
                selectedEmailTemplate === null &&
                prevIsFetchingSendEmailModel === false) ||
            (sendEmailModel.hasOwnProperty('emailTemplateData') === true &&
                prevEventType !== eventType &&
                eventType === Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW);

        if (shouldRequestSendEmailModel === true) {
            const newLanguageCode = loginManagerModel.languages.find(
                (language) => language.languageId === sendEmailModel.emailTemplateData.languageId
            ).languageCode;

            const currSelectedEmailTemplate =
                eventType === Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW
                    ? Constants.CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_IDS.find(
                          (template) => template.languageCode === newLanguageCode
                      ).templateId
                    : sendEmailModel.emailTemplateData.id;

            if (currSelectedEmailTemplate !== selectedEmailTemplate) {
                setSelectedEmailTemplate(currSelectedEmailTemplate);

                requestSendEmailModel(
                    selectedEmailTemplate,
                    User.getUserId(loginManagerModel),
                    recipients,
                    bcc,
                    languageId,
                    false,
                    emailTemplateType,
                    messageHistory,
                    isMassForwarding
                );
            }
        }

        if (
            sendEmailModel.hasOwnProperty('emailTemplateData') === true &&
            sendEmailModel.emailTemplateData.languageId !== languageId
        ) {
            setLanguageId(sendEmailModel.emailTemplateData.languageId);
        }

        if (
            sendEmailModel.hasOwnProperty('settings') === true &&
            sendEmailModel.settings.hasOwnProperty('videoInterview') === false &&
            isGettingVideoInterviewData === false
        ) {
            getVideoInterviewData();
        }

        prevSendEmailModelRef.current = sendEmailModel;
        prevIsFetchingSendEmailModelRef.current = isFetchingSendEmailModel;
        prevEventTypeRef.current = eventType;
    }, [sendEmailModel, isFetchingSendEmailModel, eventType]);

    const onSetEmailRecipient = (email) => {
        setEmailRecipients([
            {
                value: email,
                text: email,
                isEditable: true,
            },
        ]);
    };

    const selectEmailTemplate = (emailTemplateId) => {
        if (parseInt(emailTemplateId) !== parseInt(selectedEmailTemplate)) {
            setSelectedEmailTemplate(emailTemplateId);

            requestSendEmailModel(
                emailTemplateId,
                User.getUserId(loginManagerModel),
                recipients,
                bcc,
                languageId,
                false,
                emailTemplateType,
                messageHistory,
                isMassForwarding
            );
            saveAsChangedEmailTemplate(false);
            saveAsNewEmailTemplate(false);
        }
    };

    const changeLanguage = (languageId) => {
        const languageCode = loginManagerModel.languages.find(
            (language) => language.languageId === languageId
        ).languageCode;

        const selectedEmailTemplate =
            eventType === Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW
                ? Constants.CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_IDS.find(
                      (template) => template.languageCode === languageCode
                  ).templateId
                : 0;

        setSelectedEmailTemplate(selectedEmailTemplate);
        setLanguageId(languageId);

        requestSendEmailModel(
            selectedEmailTemplate,
            User.getUserId(loginManagerModel),
            recipients,
            bcc,
            languageId,
            false,
            emailTemplateType,
            messageHistory,
            isMassForwarding
        );
        saveAsChangedEmailTemplate(false);
        saveAsNewEmailTemplate(false);
    };

    let autoAnswerEditorContent = null;
    let hasChanges = false;

    if (isFetchingSendEmailModel === true || isFetchingTemplatesForDropdownSelector === true) {
        return <FormSkeleton />;
    }

    if (sendEmailModel.hasOwnProperty('emailTemplateData') === true) {
        const withMessageHistory = messageHistory !== null;

        const settings = (
            <TemplateAssistantSettings
                isModal={true}
                templateData={sendEmailModel.emailTemplateData}
                strings={{
                    saveChangesInTemplate: Translations.getStatic('saveChangesInEmailTemplate'),
                    saveAsNewTemplate: Translations.getStatic('saveAsNewEmailTemplate'),
                    doNotSaveChangesInTemplate: Translations.getStatic(
                        'doNotSaveChangesInEmailTemplate'
                    ),
                }}
                isChangedTemplate={templateAssistant.emailTemplate.isChangedTemplate}
                isNewTemplate={templateAssistant.emailTemplate.isNewTemplate}
                onUpdateTemplate={updateEmailTemplate}
                onSaveAsChangedTemplate={saveAsChangedEmailTemplate}
                onSaveAsNewTemplate={saveAsNewEmailTemplate}
                customMessageForTemplateChangesWillSaveAfterAction={Translations.getStatic(
                    'emailTemplateChangesWillSaveAfterAction'
                )}
                templateType={Constants.TEMPLATE_TYPE_EMAIL}
                settings={sendEmailModel.settings}
            />
        );

        hasChanges =
            sendEmailModel.emailTemplateData.hasOwnProperty('currentChangeHash') === true &&
            sendEmailModel.emailTemplateData.currentChangeHash !==
                sendEmailModel.emailTemplateData.changeHash;

        autoAnswerEditorContent = (
            <>
                <SendEmailActionsWrapper
                    hasChanges={hasChanges}
                    hasAllowedEditableEmailTemplates={
                        UserRights.hasAllowedEditableEmailTemplates(
                            loginManagerModel.activePackage
                        ) === true
                    }
                >
                    {settings}
                </SendEmailActionsWrapper>

                <Divider className="column" />

                <Heading as="h3">{Translations.getStatic('message')}</Heading>

                <Grid className="padding-top_none">
                    <EmailEditor
                        cursorOnStart={withMessageHistory}
                        languageId={languageId}
                        errors={errors}
                        recipients={sendEmailModel.recipients}
                        bcc={sendEmailModel.bcc}
                        sender={sendEmailModel.sender}
                        visibleFields={visibleFields}
                        sendEmailAt={sendEmailModel.sendAt}
                        emailData={sendEmailModel.emailTemplateData}
                        settings={sendEmailModel.settings}
                        loginManagerModel={loginManagerModel}
                        isRemovingColleagueContact={isRemovingColleagueContact}
                        onUpdateEmailTemplate={updateEmailTemplate}
                        onSetSendEmailAt={setSendEmailAt}
                        onSetEmailRecipient={onSetEmailRecipient}
                        onSetEmailBcc={setEmailBcc}
                        onSetEmailRecipients={setEmailRecipients}
                        onSetEmailSender={setEmailSender}
                    />

                    <Grid.Row>
                        <Grid.Column>
                            {candidateIds.length > 1 ? (
                                <MultiAttachmentAssistant
                                    candidateIds={candidateIds}
                                    templateAttachments={
                                        sendEmailModel.hasOwnProperty('emailTemplateData') === true
                                            ? sendEmailModel.emailTemplateData.attachments
                                            : []
                                    }
                                    modelHashId={AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()}
                                />
                            ) : (
                                <AttachmentAssistant
                                    candidateId={candidateIds.length === 1 ? candidateIds[0] : 0}
                                    templateAttachments={
                                        sendEmailModel.hasOwnProperty('emailTemplateData') === true
                                            ? sendEmailModel.emailTemplateData.attachments
                                            : []
                                    }
                                    modelHashId={AttachmentAssistantHelper.createAttachmentAssistantHashIdForEmail()}
                                    isFileUploadOnly={true}
                                    isEmailSending={true}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }

    return (
        <>
            {eventType === Constants.CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW && (
                <SendEmailVideoInterviewInfo />
            )}

            {emailTemplateType === Constants.EMAIL_TEMPLATE_TYPE_QUESTIONNAIRE_REQUEST && (
                <SendEmailQuestionnaireInfo />
            )}

            <Heading as="h3">{Translations.getStatic('emailTemplate')}</Heading>

            {isFetchingSendEmailModel === false &&
                sendEmailModel.hasOwnProperty('emailTemplateData') === true && (
                    <TemplateDropdownSelector
                        onStartFetchingData={() =>
                            fetchEmailTemplates(User.getUserId(loginManagerModel), languageId)
                        }
                        onSelectedValue={selectEmailTemplate}
                        emptyItem={0}
                        changeSelectedItem={hasChanges}
                        defaultValue={selectedEmailTemplate}
                        noSpacing={noSpacing}
                        languageFilter={{
                            languages: loginManagerModel.languages,
                            languageId: languageId,
                            onChangeLanguage: changeLanguage,
                        }}
                        emailTemplateType={emailTemplateType}
                        templatesDropdownLabel={Translations.getStatic('emailTemplate')}
                        templateType={Constants.TEMPLATE_TYPE_EMAIL}
                    />
                )}

            <Form>{autoAnswerEditorContent}</Form>
            <Divider hidden className="small" />
        </>
    );
};

const mapStateToProps = (state) => ({
    isFetchingSendEmailModel: state.isFetchingSendEmailModel,
    sendEmailModel: state.sendEmailModel,
    loginManagerModel: state.loginManagerModel,
    templateAssistant: state.templateAssistant,
    isAddingNewColleagueContact: state.isAddingNewColleagueContact,
    isRemovingColleagueContact: state.isRemovingColleagueContact,
    isGettingVideoInterviewData: state.isGettingVideoInterviewData,
    isFetchingTemplatesForDropdownSelector: state.isFetchingTemplatesForDropdownSelector,
});

const mapDispatchToProps = {
    requestSendEmailModel,
    updateEmailTemplate,
    setEmailRecipients,
    setEmailBcc,
    setEmailSender,
    fetchEmailTemplates,
    saveAsNewEmailTemplate,
    saveAsChangedEmailTemplate,
    setSendEmailAt,
    getVideoInterviewData,
};

const SendEmailContainer = connect(mapStateToProps, mapDispatchToProps)(SendEmail);

SendEmailContainer.propTypes = {
    recipients: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,

    bcc: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ),

    candidateIds: PropTypes.array,
    visibleFields: PropTypes.array,
    errors: PropTypes.array,
    emailTemplateType: PropTypes.number.isRequired,
    eventType: PropTypes.number,
    messageHistory: PropTypes.object,
    isMassForwarding: PropTypes.bool,
};

SendEmailContainer.defaultProps = {
    candidateIds: [],
    bcc: [],
    errors: [],
    messageHistory: null,
    isMassForwarding: false,
};

export default SendEmailContainer;
