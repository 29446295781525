export var PositionPlaceTypeId;
(function (PositionPlaceTypeId) {
    PositionPlaceTypeId[PositionPlaceTypeId["Onsite"] = 1] = "Onsite";
    PositionPlaceTypeId[PositionPlaceTypeId["Travel"] = 2] = "Travel";
    PositionPlaceTypeId[PositionPlaceTypeId["Remote"] = 3] = "Remote";
    PositionPlaceTypeId[PositionPlaceTypeId["Hybrid"] = 4] = "Hybrid";
})(PositionPlaceTypeId || (PositionPlaceTypeId = {}));
export var ValidationSchema;
(function (ValidationSchema) {
    ValidationSchema["Job"] = "job";
    ValidationSchema["ContactPerson"] = "contactPerson";
    ValidationSchema["Company"] = "company";
})(ValidationSchema || (ValidationSchema = {}));
