import { jsx as _jsx } from "react/jsx-runtime";
import { useFormState } from 'react-hook-form';
import { Stack as StackUI, styled } from '@mui/material';
import { ContentActions } from '@profesia/adamui/components/common/modal';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import { useHandleCloseModal } from '@profesia/mark-offer-form/utils';
const Footer = ({ isPending }) => {
    const { form } = getConfiguration();
    const t = useTranslationInPageLanguage('buttons');
    const { isDirty } = useFormState();
    const handleCloseModal = useHandleCloseModal(form.onCancel);
    return (_jsx(Stack, { children: _jsx(ContentActions, { cancelButtonProps: {
                label: t('cancel'),
                onCancel: () => handleCloseModal(isDirty),
            }, submitButtonProps: {
                isTypeSubmit: true,
                label: t('saveAndContinue'),
                isLoading: isPending,
            } }) }));
};
const Stack = styled(StackUI)(({ theme: { spacing } }) => ({
    flexDirection: 'row',
    maxWidth: '860px',
    justifyContent: 'end',
    gap: spacing(2),
    marginBottom: spacing(2),
    ['@media (max-width: 1130px)']: {
        '.modal-content-actions': {
            position: 'relative !important',
        },
    },
}));
export default Footer;
