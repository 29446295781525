import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Box, styled } from '@mui/material';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { HINT_HIDE_BREAKPOINT, HINT_TOOLTIP_BREAKPOINT } from '../Content/Tooltip';
const Layout = ({ Content, hint, ml, mt }) => {
    const [isHintActive, setIsHintActive] = useState(false);
    const { isEditMode, isSingleStep } = useOptionsContext();
    const showHint = !isEditMode && isSingleStep;
    return (_jsxs(Box, { position: "relative", children: [_jsx(Content, { setIsHintActive: setIsHintActive }), hint && showHint ? (_jsx(HintContainer, { ml: ml, mt: mt, isActive: isHintActive, children: hint })) : null] }));
};
const HintContainer = styled(Box)(({ isActive, theme: { breakpoints } }) => ({
    opacity: isActive ? 1 : 0.5,
    width: 226,
    position: 'absolute',
    left: '100%',
    top: 0,
    [breakpoints.down(HINT_TOOLTIP_BREAKPOINT)]: {
        opacity: 0.5,
        width: 30,
        '&:hover': {
            opacity: 1,
        },
    },
    [breakpoints.down(HINT_HIDE_BREAKPOINT)]: {
        display: 'none',
    },
}));
export default Layout;
