import Cookie from './Cookie';
import { CommonObjectType } from '../../interfaces/CommonTypes';

const B2B_DATA_LAYER_COOKIE_NAME: string = 'B2BDataLayer';

type DataLayerParameters = {
    paid_mark: boolean,
    has_ever_refused_candidates_mark: boolean,
    has_ever_added_candidate_note_mark: boolean,
    has_ever_sent_email_to_candidate_mark: boolean,
}

class DataLayer {
    static get(): CommonObjectType[] {
        window.dataLayer = window.dataLayer ?? [];

        return window.dataLayer;
    }

    static setDataFromProfesiaCookie(): void {
        window.dataLayer = this.get();

        const profesiaDataLayerData: CommonObjectType | null = this.getProfesiaDataLayerData();

        if (profesiaDataLayerData !== null) {
            window.dataLayer.push({
                ...profesiaDataLayerData,
                paid_mark: false,
            });
        }
    }

    static isSetDataFromProfesiaDataLayer(): boolean {
        const dataLayerData: CommonObjectType[] = this.get();

        if (dataLayerData.length === 0) {
            return false;
        }

        let profesiaDataLayerData: CommonObjectType | null = this.getProfesiaDataLayerData();

        if (profesiaDataLayerData === null) {
            return true;
        }

        const profesiaDataLayerDataJson: string = JSON.stringify(profesiaDataLayerData);

        for (let i = 0; i < dataLayerData.length; i++) {
            if (JSON.stringify(dataLayerData[i]) === profesiaDataLayerDataJson) {
                return true;
            }
        }

        return false;
    }

    static getProfesiaDataLayerData(): CommonObjectType | null {
        const dataLayerCookie: string | null = this.getProfesiaDataLayerCookie();

        return dataLayerCookie === null ? null : JSON.parse(decodeURIComponent(dataLayerCookie));
    }

    static getProfesiaDataLayerCookie(): string | null {
        return Cookie.get(B2B_DATA_LAYER_COOKIE_NAME);
    }

    static setParameters(parameters: DataLayerParameters) {
        window.dataLayer = this.get();

        for (let i = 0; i < window.dataLayer.length; i++) {
            if (window.dataLayer[i]?.hasOwnProperty('paid_mark')) {
                window.dataLayer[i] = {
                    ...window.dataLayer[i],
                    ...parameters,
                };
            }
        }
    }
}

export default DataLayer;
