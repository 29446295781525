import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, styled } from '@mui/material';
import { tabletDown } from '@profesia/adamui/styles/theme';
import Autocomplete from '@profesia/adamui/components/common/input/autocomplete/Autocomplete';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_CONTACT_PERSON_ID } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import transformContactPersons from '@profesia/mark-offer-form/services/contactPersons/transformContactPersons/index';
import getContactPersonById from '@profesia/mark-offer-form/services/contactPersons/getContactPersonById/index';
const ContactPersonSelect = ({ contactPersons }) => {
    const t = useGetFixedT('contactDetails');
    const { getValues, setValue } = useFormContext();
    const contactPersonId = getValues(FORM_CONTACT_PERSON_ID);
    const [formValue, setFormValue] = useState(null);
    const [transformedContactPersons, setTransformedContactPersons] = useState([]);
    useEffect(() => {
        const transformedContactPersons = transformContactPersons(contactPersons, t('nameNotSet'));
        setTransformedContactPersons(transformedContactPersons);
        setFormValue(getContactPersonById(transformedContactPersons, contactPersonId));
    }, [contactPersons]);
    const handleSelectChange = (value) => {
        setFormValue(value);
        setValue(FORM_CONTACT_PERSON_ID, value?.id);
    };
    return (_jsx(SelectBox, { "data-testid": "select-box", children: _jsx(Autocomplete, { label: t('contactPerson.label'), placeholder: "", options: transformedContactPersons, value: formValue, onChange: (event, value) => handleSelectChange(value) }) }));
};
const SelectBox = styled(Box)(({ theme }) => ({
    width: '440px',
    marginBottom: theme.spacing(3),
    [tabletDown]: {
        width: '100%',
    },
}));
export default ContactPersonSelect;
