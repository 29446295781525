import { Currency, OfferLanguage, Period } from '@profesia/mark-offer-form/codebooks';
const commonSalaryData = {
    currency: Currency.Eur,
    period: Period.Month,
};
export const defaultSalaryByType = {
    fixed: {
        type: 'fixed',
        value: null,
        ...commonSalaryData,
    },
    range: {
        type: 'range',
        from: null,
        to: null,
        showTo: false,
        ...commonSalaryData,
    },
};
const defaultFixedSalary = 1000;
const defaultFromSalary = 1000;
const defaultToSalary = 2000;
export const DEFAULT_FIXED_SALARY_PREVIEW = {
    type: 'fixed',
    value: defaultFixedSalary,
    ...commonSalaryData,
};
export const DEFAULT_RANGE_SALARY_PREVIEW = {
    type: 'range',
    from: defaultFromSalary,
    to: defaultToSalary,
    ...commonSalaryData,
};
export const DEFAULT_FORM_DATA = {
    language: OfferLanguage.Sk,
    jobTitle: '',
    jobPositions: [],
    employmentTypes: [],
    jobDescription: '',
    benefits: '',
    positionPlace: {
        type: null,
        address: [],
        regions: [],
    },
    salary: null,
    educationLevel: null,
    languages: {
        items: [],
    },
    skills: {
        others: [],
        drivingLicence: [],
    },
    noteForCandidate: '',
    numberOfOpenings: '',
    applicableTill: '',
    isApplicableWithoutCv: false,
    shouldBeNotifiedAboutNewResumes: false,
    referenceNumber: '',
    company: {
        id: null,
        characteristics: '',
    },
};
