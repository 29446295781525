import { FORM_LANGUAGES_ITEMS } from '@profesia/mark-offer-form/components/content/form/valuePaths';
const updateLanguageValues = (setValue, languages, languageValues) => {
    if (languageValues.length === 0) {
        return;
    }
    const languagesMap = new Map(languages.map((language) => [language.id, { ...language, level: '' }]));
    const updatedValues = languageValues
        .map((value) => ({ ...languagesMap.get(value?.id), level: value?.level }))
        .filter((value) => value !== undefined);
    setValue(FORM_LANGUAGES_ITEMS, updatedValues, { shouldDirty: false });
};
export default updateLanguageValues;
