import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormProvider, useForm } from 'react-hook-form';
import { FormFooter } from '@profesia/adamui/components/common/modal';
import { Typography } from '@profesia/adamui/components/common/typography';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import StyledModal from '@profesia/mark-offer-form/components/content/form/components/StyledModal';
const EditLogoModal = ({ isOpen, onClose, isModalSecond, onSubmit }) => {
    const t = useTranslationInPageLanguage('companyDetails.editLogoModal');
    const buttonsT = useTranslationInPageLanguage('buttons');
    const formData = useForm();
    const { handleSubmit } = formData;
    const onFormSubmit = (data) => {
        onSubmit();
    };
    return (_jsx(StyledModal, { isOpen: isOpen, onClose: onClose, spacing: 4, isModalSecond: isModalSecond, header: _jsx(Typography, { variant: "h3Medium", ml: 3, children: t('title') }), content: _jsxs(FormProvider, { ...formData, children: ["Edit logo", _jsx(FormFooter, { leftSideActions: [], rightSideActions: [
                        {
                            //@TODO implement save changes
                            onClick: handleSubmit(onFormSubmit),
                            variant: 'primary',
                            label: buttonsT('save'),
                        },
                        {
                            onClick: onClose,
                            variant: 'secondary',
                            label: buttonsT('cancel'),
                        },
                    ] })] }) }));
};
export default EditLogoModal;
