import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/types';

type ModalThirdState = {
    isOpen: boolean;
};

const ModalThirdManager = () => {
    const currentModal = useSelector((state: RootState) => state.modalLogic);
    const currentSecondModal = useSelector((state: RootState) => state.modalSecondLogic);
    const currentThirdModal = useSelector(
        (state: RootState) => state.modalThirdLogic
    ) as ModalThirdState;

    const prevThirdModalRef = useRef(currentThirdModal);

    useEffect(() => {
        const bodyElement = document.getElementsByTagName('body')[0];
        const prevThirdModal = prevThirdModalRef.current;

        if (currentThirdModal.isOpen) {
            bodyElement?.classList.add('openingModalThird');
            bodyElement?.classList.remove('closingModalThird');
        } else if (prevThirdModal.isOpen && !currentThirdModal.isOpen) {
            bodyElement?.classList.add('closingModalThird');
            bodyElement?.classList.remove('openingModalThird');
        }

        if (!currentSecondModal.isOpen && currentModal.isOpen) {
            bodyElement?.classList.remove('closingModalThird');
        }

        if (!currentModal.isOpen && !currentThirdModal.isOpen) {
            bodyElement?.classList.remove('openingModalThird');
            bodyElement?.classList.remove('closingModalThird');
        }

        prevThirdModalRef.current = currentThirdModal;
    }, [currentThirdModal, currentSecondModal, currentModal]);

    return null;
};

export default ModalThirdManager;
