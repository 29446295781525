import React from 'react';
import { useSelector } from 'react-redux';
import { Stack, styled } from '@mui/material';
import { getColor, palette } from '@profesia/adamui/styles/colors';
import { DisplaySizeType } from '../../../../../interfaces/DisplaySizeType';
import SkeletonRows from '../../skeletonRows';

const Content = () => {
    const displaySize = useSelector((state: { displaySize: DisplaySizeType }) => state.displaySize);

    return (
        <Stack
            py={4}
            height={'100%'}
            direction={displaySize.isMobile ? 'column' : 'row'}
            data-testid="job-statistics-content-skeleton"
        >
            <SkeletonRows rows={8} px={4} />

            {!displaySize?.isMobile && (
                <StyledStack data-testid="sidebar-skeleton-rows">
                    <SkeletonRows />
                </StyledStack>
            )}
        </Stack>
    );
};

const StyledStack = styled(Stack)(({ theme: { spacing } }) => ({
    width: '260px',
    borderLeft: `1px solid ${getColor(palette.neutralLight4)}`,
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
}));

export default Content;
