import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Stack, styled } from '@mui/material';
import { SimpleLink } from '@profesia/adamui/components/common/Link';
import { Typography } from '@profesia/adamui/components/common/typography';
import { getColor } from '@profesia/adamui/styles/colors';
import Label from '@profesia/adamui/components/common/Label';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import { FORM_COMPANY_LOGO_IMAGE } from '@profesia/mark-offer-form/components/content/form/valuePaths';
const CompanyLogo = ({ handleOpenLogoModal }) => {
    const t = useTranslationInPageLanguage('companyDetails.companyLogo');
    const { control } = useFormContext();
    const logo = useWatch({ name: FORM_COMPANY_LOGO_IMAGE, control });
    return (_jsxs(Box, { "data-testid": "company-details-logo", children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsx(Label, { children: t('label') }), logo ? (_jsx(SimpleLink, { variantType: "primary", variant: "body2", onClick: handleOpenLogoModal, children: t('editLink') })) : null] }), _jsx(StyledStack, { "data-testid": "company-details-logo-wrapper", children: logo ? (_jsx(StyledImage, { src: logo, alt: t('label') })) : (_jsxs(_Fragment, { children: [_jsx(SimpleLink, { variantType: "primary", fontWeight: "bold", onClick: handleOpenLogoModal, mb: 0.5, children: t('uploadLogo') }), _jsx(Typography, { fontSize: 12, color: "neutralDark2", children: t('uploadLogoDescription') })] })) }), _jsx(Typography, { fontSize: 12, color: "neutralDark2", children: t('description') })] }));
};
const StyledImage = styled('img')(() => ({
    maxWidth: '240px',
    maxHeight: '100px',
    width: 'auto',
    height: 'auto',
}));
const StyledStack = styled(Stack)(({ theme: { palette, spacing } }) => ({
    height: '140px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: getColor(palette.neutralLight0),
    border: `1px dotted ${getColor(palette.neutralLight5)}`,
    borderRadius: 4,
    marginTop: spacing(0.5),
    marginBottom: spacing(0.5),
}));
export default CompanyLogo;
