import 'core-js';
import './../js/polyfills';
import 'semantic-ui-css/semantic.min.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import './../less/mark.less';

import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter, Routes, Route, Navigate, useLocation} from 'react-router-dom';
import {SemanticToastContainer} from 'react-semantic-toasts';
import * as Sentry from '@sentry/react';
import {ThemeProvider} from '@mui/material';
import themeAdamUi from '@profesia/adamui/styles/theme';

import Url from './helpers/Url';
import Browser from './helpers/Browser';
import {Notifications} from 'react-push-notification';

import ExternalUserLoginPage from './components/ExternalUserLoginPage';
import Header from './components/Header';
import LoginPage from './components/LoginPage';
import LogoutPage from './components/LogoutPage';
import UpdateDimensions from './components/UpdateDimensions';
import Transition from './components/Transition';

import CurrentlyAcceptedCandidatesManager from './components/CurrentlyAcceptedCandidatesManager';
import GdprManager from './components/Gdpr/GdprManager';
import LoginManager from './components/Login/LoginManager';
import MetaTagsManager from './components/MetaTagsManager';
import ModalManager from './components/Modal/ModalManager';
import ModalSecondManager from './components/Modal/ModalSecondManager';
import NoModalManager from './components/Modal/NoModalManager';
import NoModalSecondManager from './components/Modal/NoModalSecondManager';
import NoModalDashboardManager from './components/Modal/NoModalDashboardManager';

import AllRecipientsWithoutEmailsDialog from './components/Dialogs/AllRecipientsWithoutEmailsDialog';
import CancelCloseJobWizardDialog from './components/Dialogs/CancelCloseJobWizardDialog';
import ConfirmCloseJobUncontactedDialog from './components/Dialogs/ConfirmCloseJobUncontactedDialog';
import ConfirmCloseJobDialog from './components/Dialogs/ConfirmCloseJobDialog';
import CopyIntoTalentPoolDialog from './components/Dialogs/CopyIntoTalentPoolDialog';
import NoCandidateAccessDialog from './components/Dialogs/NoCandidateAccessDialog';
import NoJobAccessDialog from './components/Dialogs/NoJobAccessDialog';
import NoOrderAccessDialog from './components/Dialogs/NoOrderAccessDialog';
import NotAllowedFeatureDialog from './components/Dialogs/NotAllowedFeatureDialog';
import RemoveFromTalentPoolDialog from './components/Dialogs/RemoveFromTalentPoolDialog';
import SomeRecipientsWithoutEmailsDialog from './components/Dialogs/SomeRecipientsWithoutEmailsDialog';
import GdprAgreementFilterTestingDialog from './components/Dialogs/UserTestings/GdprAgreementFilterTestingDialog';
import GdprAgreementFilterTestingNewsletterDialog from './components/Dialogs/UserTestings/GdprAgreementFilterTestingNewsletterDialog';
import DashboardStatisticsTestingDialog from './components/Dialogs/UserTestings/DashboardStatisticsTestingDialog';
import DashboardStatisticsTestingNewsletterDialog from './components/Dialogs/UserTestings/DashboardStatisticsTestingNewsletterDialog';
import DashboardNewsTestingNewsletterDialog from './components/Dialogs/UserTestings/DashboardNewsTestingNewsletterDialog';
import DashboardNewsTestingDialog from './components/Dialogs/UserTestings/DashboardNewsTestingDialog';

import WebSocketsListener from './components/WebSockets/WebSocketsListener';

import lazyWithReload from './helpers/Load';

const Homepage = lazyWithReload(() => import('./components/WebSite/Homepage'));
const FeaturePage = lazyWithReload(() => import('./components/WebSite/FeaturePage'));
const PricingPage = lazyWithReload(() => import('./components/WebSite/PricingPage'));
const PackagesPage = lazyWithReload(() => import('./components/WebSite/PackagesPage'));
const ContactPage = lazyWithReload(() => import('./components/WebSite/ContactPage'));
const FaqPage = lazyWithReload(() => import('./components/WebSite/FaqPage'));
const ArticlePage = lazyWithReload(() => import('./components/WebSite/ArticlePage'));
const AdminPages = lazyWithReload(() => import('./components/MainParts/AdminPages'));
const AppPages = lazyWithReload(() => import('./components/MainParts/AppPages'));

const Maintenance = lazyWithReload(() => import('./components/Errors/Maintenance'));

const Error404 = lazyWithReload(() => import('./components/Errors/Error404'));

import appStore from './stores/AppStore';
import InternalInfoController from './components/InternalInfo/InternalInfoController';
import SuspenseFallbackSkeleton from './skeletons/components/SuspenseFallbackSkeleton';
import B2bDashboard from './components/B2b/Dashboard/Dashboard';
import ModalThirdManager from "./components/Modal/ModalThirdManager";

if (Url.isLocal() === false) {
    Sentry.init({
        dsn: 'https://83e7c194b7e64feb9b773d1e1d1eef29@sentry.io/1853626',
        maxBreadcrumbs: 10,
    });
}

Browser.removeBrowserTabId();

const container = document.getElementById('container');
const root = createRoot(container);

const CustomRouter = () => {
    const location = useLocation();

    return (
        <MetaTagsManager customClassName={location.pathname.indexOf('candidate-card') > -1 ? 'candidate-card-container' : ''}>
            <UpdateDimensions>
                <ThemeProvider theme={themeAdamUi}>
                    <Transition>
                        <Header/>

                        <Suspense fallback={<SuspenseFallbackSkeleton/>}>
                            <Routes>
                                <Route path={'/dashboard'} element={<B2bDashboard/>}/>

                                {/*Login Pages*/}
                                <Route path={Url.MARK_SERVER_PREFIX + '/login'} element={<LoginPage/>}/>
                                <Route path={Url.MARK_SERVER_PREFIX + '/login/external-user/:externalUserAuthLogin'} element={<ExternalUserLoginPage/>}/>
                                <Route path={Url.MARK_SERVER_PREFIX + '/login/external-user/:externalUserAuthLogin/email/:email'} element={<ExternalUserLoginPage/>}/>
                                <Route path={Url.MARK_SERVER_PREFIX + '/logout'} element={<LogoutPage/>}/>

                                {/* Web Site / Home page / Landing page Pages */}
                                <Route path={Url.MARK_SERVER_PREFIX + '/'} element={<Homepage/>}/>
                                <Route path={Url.MARK_SERVER_PREFIX + '/features'} element={<FeaturePage/>}/>
                                <Route path={Url.MARK_SERVER_PREFIX + '/pricing'} element={<PricingPage/>}/>
                                <Route path={Url.MARK_SERVER_PREFIX + '/packages'} element={<PackagesPage/>}/>
                                <Route path={Url.MARK_SERVER_PREFIX + '/contact'} element={<ContactPage/>}/>
                                <Route path={Url.MARK_SERVER_PREFIX + '/faq'} element={<FaqPage/>}/>
                                <Route path={Url.MARK_SERVER_PREFIX + '/articles/article/:alias'} element={<ArticlePage/>}/>

                                {/* Admin Pages */}
                                <Route path={Url.MARK_SERVER_PREFIX + '/admin'} element={<Navigate to={Url.MARK_SERVER_PREFIX + '/admin/company-email-templates'}/>}/>
                                <Route path={Url.MARK_SERVER_PREFIX + '/admin/*'} element={<AdminPages/>}/>

                                {/* App Pages */}
                                <Route path={Url.MARK_SERVER_PREFIX + '/*'} element={<AppPages/>}/>

                                <Route path="*" element={<Error404/>}/>
                            </Routes>
                            <ModalManager/>
                            <ModalSecondManager/>
                            <ModalThirdManager/>
                            <NoModalManager/>
                            <NoModalSecondManager/>
                            <NoModalDashboardManager/>

                            <SemanticToastContainer position="bottom-right"/>

                            <AllRecipientsWithoutEmailsDialog/>
                            <CancelCloseJobWizardDialog/>
                            <ConfirmCloseJobDialog/>
                            <ConfirmCloseJobUncontactedDialog/>
                            <CopyIntoTalentPoolDialog/>
                            <NoCandidateAccessDialog/>
                            <NoJobAccessDialog/>
                            <NoOrderAccessDialog/>
                            <NotAllowedFeatureDialog/>
                            <SomeRecipientsWithoutEmailsDialog/>
                            <RemoveFromTalentPoolDialog/>
                            <GdprAgreementFilterTestingDialog/>
                            <GdprAgreementFilterTestingNewsletterDialog/>
                            <DashboardStatisticsTestingDialog/>
                            <DashboardStatisticsTestingNewsletterDialog/>
                            <DashboardNewsTestingDialog/>
                            <DashboardNewsTestingNewsletterDialog/>

                            <WebSocketsListener/>
                        </Suspense>
                    </Transition>
                </ThemeProvider>
            </UpdateDimensions>
        </MetaTagsManager>
    );
};

root.render((
    <Sentry.ErrorBoundary fallback={<SuspenseFallbackSkeleton/>}>
        <Notifications/>

        <Provider store={appStore}>
            <BrowserRouter>
                <Routes>
                    <Route exact path={Url.MARK_SERVER_PREFIX + '/maintenance'} element={<Maintenance/>}/>
                </Routes>
                <>
                    <LoginManager/>

                    <InternalInfoController>
                        <GdprManager>
                            <CustomRouter/>
                        </GdprManager>
                    </InternalInfoController>
                </>
            </BrowserRouter>
        </Provider>
    </Sentry.ErrorBoundary>
));
