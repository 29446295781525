const sortContactPersons = (contactPersons) => {
    return contactPersons?.sort((a, b) => {
        const labelA = a.lastName.toLowerCase().trim();
        const labelB = b.lastName.toLowerCase().trim();
        if (labelA < labelB)
            return -1;
        if (labelA > labelB)
            return 1;
        return 0;
    });
};
const getContactPersonFullName = (contactPerson, defaultName) => {
    const fullName = `${contactPerson.academicDegree} ${contactPerson.lastName} ${contactPerson.firstName}`.trim();
    return fullName || `${defaultName} (ID: ${contactPerson.id})`;
};
const transformContactPersons = (contactPersons, defaultName) => {
    const sortedContactPersons = sortContactPersons(contactPersons);
    const mappedContactPersons = sortedContactPersons?.map((contactPerson) => ({
        id: contactPerson.id,
        name: getContactPersonFullName(contactPerson, defaultName),
    }));
    return mappedContactPersons;
};
export default transformContactPersons;
