import {
    CHECK_LOGIN, CHECK_LOGIN_FAILURE, CHECK_LOGIN_SUCCESS, REQUEST_LOGIN_MANAGER_MODEL, RECEIVE_LOGIN_MANAGER_MODEL,
    REQUEST_LOGIN_MANAGER_MODEL_BASIC, RECEIVE_LOGIN_MANAGER_MODEL_BASIC, RESET_LOGIN_MANAGER_MODEL_BASIC, CHECK_CANDIDATE_FROM_EMAIL, CHECK_CANDIDATE_FROM_EMAIL_SUCCESS, CHECK_CANDIDATE_FROM_EMAIL_FAILURE, SET_USER_DATA
} from '../actions/LoginActions';
import Authentication from '../helpers/Authentication';
import { CONFIRM_GDPR_AGREEMENT_SUCCESS } from '../actions/GdprActions';
import { CANCEL_SUBSCRIPTION_SUCCESS, CANCEL_TRIAL_SUBSCRIPTION_SUCCESS, DEACTIVATE_MARK_SUCCESS, RESTORE_SUBSCRIPTION_SUCCESS } from '../actions/CompanySettingsActions';
import { SET_USER_DEFAULT_JOB_VIEW_SUCCESS, SET_USER_DEFAULT_PUBLIC_NOTES_SUCCESS } from '../actions/SettingsActions';
import { COPY_CANDIDATES_INTO_TALENT_POOL_SUCCESS } from '../actions/ActionWithCandidateActions';
import { ADD_NOTIFICATION_MESSAGE, SET_ALL_NOTIFICATIONS_AS_READ, SET_NOTIFICATION_AS_READ, SET_NOTIFICATION_AS_READ_FAILURE } from '../actions/NotificationsCenterActions';
import { SET_CANDIDATE_INBOX_MESSAGE_AS_READ, SET_CANDIDATE_INBOX_MESSAGE_AS_READ_FAILURE } from '../actions/CandidateActions';
import { SAVE_USER_TESTING_SUCCESS } from '../actions/UserTestingActions';
import Storages from '../helpers/Storages';
import Constants from '../helpers/Constants';

export const loginState = (state = false, action) => {
    switch (action.type) {
        case CHECK_LOGIN:
            return false;

        case CHECK_LOGIN_SUCCESS:
            Authentication.setAuthenticationData(action.payload.accessToken, action.payload.refreshToken, action.payload.applicationId, action.payload.language);
            Storages.removeSession(Constants.B2B_DASHBOARD_USER_STORAGE_KEY);
            return true;

        case CHECK_LOGIN_FAILURE:
            return -1;

        default:
            return state;
    }
};

export const loginManagerModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_LOGIN_MANAGER_MODEL:
            return {};

        case RECEIVE_LOGIN_MANAGER_MODEL:
            return {
                ...state,
                ...action.payload,
            };

        case SET_USER_DEFAULT_JOB_VIEW_SUCCESS:
        case SET_USER_DEFAULT_PUBLIC_NOTES_SUCCESS:
            return {
                ...state,
                ...action.payload.loginManagerModel,
            };

        case CANCEL_SUBSCRIPTION_SUCCESS:
        case CANCEL_TRIAL_SUBSCRIPTION_SUCCESS:
        case CONFIRM_GDPR_AGREEMENT_SUCCESS:
        case DEACTIVATE_MARK_SUCCESS:
        case RESTORE_SUBSCRIPTION_SUCCESS:
            if (action.payload.hasOwnProperty('loginData') === false) {
                return state;
            }

            return {
                ...state,
                ...action.payload.loginData,
            };

        case COPY_CANDIDATES_INTO_TALENT_POOL_SUCCESS:
            if (state.talentPool.hasUserTalentPool === true) {
                return state;
            }

            return {
                ...state,
                talentPool: action.payload.talentPool,
            };

        case ADD_NOTIFICATION_MESSAGE:
            if (state.hasOwnProperty('notifications') === false) {
                return state;
            }

            return {
                ...state,
                notifications: [action.payload.notificationData].concat(state.notifications),
            };

        case SET_CANDIDATE_INBOX_MESSAGE_AS_READ:
            if (state.hasOwnProperty('notifications') === false) {
                return state;
            }

            return {
                ...state,
                notifications: state.notifications.map(notification => notification.id === action.payload.messageId && notification.candidate.id === action.payload.candidateId
                    ? {
                        ...notification,
                        removed: true,
                    }
                    : notification,
                ),
            };

        case SET_CANDIDATE_INBOX_MESSAGE_AS_READ_FAILURE:
            if (state.hasOwnProperty('notifications') === false) {
                return state;
            }

            return {
                ...state,
                notifications: state.notifications.map(notification => notification.id === action.meta.requestPayload.messageId && notification.candidate.id === action.meta.requestPayload.candidateId && notification.hasOwnProperty('removed') === true && notification.removed === true
                    ? {
                        ...notification,
                        removed: false,
                    }
                    : notification,
                ),
            };

        case SET_ALL_NOTIFICATIONS_AS_READ:
            if (state.hasOwnProperty('notifications') === false) {
                return state;
            }

            return {
                ...state,
                notifications: state.notifications.map(notification => ({
                    ...notification,
                    removed: true,
                })),
            };

        case SET_NOTIFICATION_AS_READ:
            if (state.hasOwnProperty('notifications') === false) {
                return state;
            }

            return {
                ...state,
                notifications: state.notifications.map(notification => notification.id === action.payload.notificationId
                    ? {
                        ...notification,
                        removed: true,
                    }
                    : notification,
                ),
            };

        case SET_NOTIFICATION_AS_READ_FAILURE:
            if (state.hasOwnProperty('notifications') === false) {
                return state;
            }

            return {
                ...state,
                notifications: state.notifications.map(notification => notification.id === action.meta.requestPayload.notificationId && notification.hasOwnProperty('removed') === true && notification.removed === true
                    ? {
                        ...notification,
                        removed: false,
                    }
                    : notification,
                ),
            };

        case SAVE_USER_TESTING_SUCCESS:
            const userTestings = state.userTestings;

            userTestings.push(action.payload.userTesting);

            return {
                ...state,
                userTestings,
            };

        case SET_USER_DATA:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                }
            };

        default:
            return state;
    }
};

export const loginManagerModelBasic = (state = null, action) => {
    switch (action.type) {
        case REQUEST_LOGIN_MANAGER_MODEL_BASIC:
        case RESET_LOGIN_MANAGER_MODEL_BASIC:
            return null;

        case RECEIVE_LOGIN_MANAGER_MODEL_BASIC:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};

export const isFetchingLoginManagerModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_LOGIN_MANAGER_MODEL:
            return true;

        case RECEIVE_LOGIN_MANAGER_MODEL:
            return false;

        default:
            return state;
    }
};

export const isFetchingLoginManagerModelBasic = (state = false, action) => {
    switch (action.type) {
        case REQUEST_LOGIN_MANAGER_MODEL_BASIC:
            return true;

        case RECEIVE_LOGIN_MANAGER_MODEL_BASIC:
            return false;

        default:
            return state;
    }
};

export const candidateFromEmailCheckResult = (state = false, action) => {
    switch (action.type) {
        case CHECK_CANDIDATE_FROM_EMAIL:
            return {};

        case CHECK_CANDIDATE_FROM_EMAIL_SUCCESS:
            return {
                ...state,
                redirect: action.payload.redirect,
                redirectTo: action.payload.redirectTo,
            };

        default:
            return state;
    }
};

export const isCheckingCandidateFromEmail = (state = false, action) => {
    switch (action.type) {
        case CHECK_CANDIDATE_FROM_EMAIL:
            return true;

        case CHECK_CANDIDATE_FROM_EMAIL_SUCCESS:
        case CHECK_CANDIDATE_FROM_EMAIL_FAILURE:
            return false;

        default:
            return state;
    }
};
