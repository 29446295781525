import Constants from '../helpers/Constants';

export const REQUEST_COPY_CANDIDATE_MODEL                  = 'REQUEST_COPY_CANDIDATE_MODEL';
export const RECEIVE_COPY_CANDIDATE_MODEL                  = 'RECEIVE_COPY_CANDIDATE_MODEL';
export const SET_COPY_PARAMS                               = 'SET_COPY_PARAMS';
export const COPY_CANDIDATES                               = 'COPY_CANDIDATES';
export const COPY_CANDIDATES_INTO_TALENT_POOL              = 'COPY_CANDIDATES_INTO_TALENT_POOL';
export const COPY_CANDIDATES_INTO_TALENT_POOL_SUCCESS      = 'COPY_CANDIDATES_INTO_TALENT_POOL_SUCCESS';
export const COPY_CANDIDATES_INTO_TALENT_POOL_FAILURE      = 'COPY_CANDIDATES_INTO_TALENT_POOL_FAILURE';
export const COPY_CANDIDATES_SUCCESS                       = 'COPY_CANDIDATES_SUCCESS';
export const COPY_CANDIDATES_FAILURE                       = 'COPY_CANDIDATES_FAILURE';
export const SEND_EMAIL                                    = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS                            = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE                            = 'SEND_EMAIL_FAILURE';
export const CHANGE_CANDIDATES_STAGE                       = 'CHANGE_CANDIDATES_STAGE';
export const CHANGE_CANDIDATES_STAGE_SUCCESS               = 'CHANGE_CANDIDATES_STAGE_SUCCESS';
export const CHANGE_CANDIDATES_STAGE_FAILURE               = 'CHANGE_CANDIDATES_STAGE_FAILURE';
export const SET_CHANGE_CANDIDATES_STAGE_PARAMS            = 'SET_CHANGE_CANDIDATES_STAGE_PARAMS';
export const SCHEDULE_CANDIDATE_EVENT                      = 'SCHEDULE_CANDIDATE_EVENT';
export const SCHEDULE_CANDIDATE_EVENT_SUCCESS              = 'SCHEDULE_CANDIDATE_EVENT_SUCCESS';
export const SCHEDULE_CANDIDATE_EVENT_FAILURE              = 'SCHEDULE_CANDIDATE_EVENT_FAILURE';
export const DELETE_CANDIDATE_EVENT                        = 'DELETE_CANDIDATE_EVENT';
export const DELETE_CANDIDATE_EVENT_SUCCESS                = 'DELETE_CANDIDATE_EVENT_SUCCESS';
export const DELETE_CANDIDATE_EVENT_FAILURE                = 'DELETE_CANDIDATE_EVENT_FAILURE';
export const SET_SCHEDULE_EVENT_PARAMS                     = 'SET_SCHEDULE_EVENT_PARAMS';
export const REQUEST_CANDIDATE_EVENT_MODEL                 = 'REQUEST_CANDIDATE_EVENT_MODEL';
export const RECEIVE_CANDIDATE_EVENT_MODEL                 = 'RECEIVE_CANDIDATE_EVENT_MODEL';
export const GET_CANDIDATES_FOR_ACTION                     = 'GET_CANDIDATES_FOR_ACTION';
export const GET_CANDIDATES_FOR_ACTION_SUCCESS             = 'GET_CANDIDATES_FOR_ACTION_SUCCESS';
export const GET_CANDIDATES_FOR_ACTION_FAILURE             = 'GET_CANDIDATES_FOR_ACTION_FAILURE';
export const ANONYMIZE_CANDIDATES                          = 'ANONYMIZE_CANDIDATES';
export const ANONYMIZE_CANDIDATES_SUCCESS                  = 'ANONYMIZE_CANDIDATES_SUCCESS';
export const ANONYMIZE_CANDIDATES_FAILURE                  = 'ANONYMIZE_CANDIDATES_FAILURE';
export const REQUEST_CHANGE_STAGE_MODEL                    = 'REQUEST_CHANGE_STAGE_MODEL';
export const RECEIVE_CHANGE_STAGE_MODEL                    = 'RECEIVE_CHANGE_STAGE_MODEL';
export const REFUSE_CANDIDATE_AND_SEND_EMAIL               = 'REFUSE_CANDIDATE_AND_SEND_EMAIL';
export const REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS       = 'REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS';
export const REFUSE_CANDIDATE_AND_SEND_EMAIL_FAILURE       = 'REFUSE_CANDIDATE_AND_SEND_EMAIL_FAILURE';
export const REMOVE_CANDIDATES_FROM_TALENT_POOL            = 'REMOVE_CANDIDATES_FROM_TALENT_POOL';
export const REMOVE_CANDIDATES_FROM_TALENT_POOL_SUCCESS    = 'REMOVE_CANDIDATES_FROM_TALENT_POOL_SUCCESS';
export const REMOVE_CANDIDATES_FROM_TALENT_POOL_FAILURE    = 'REMOVE_CANDIDATES_FROM_TALENT_POOL_FAILURE';
export const SET_REFUSE_CANDIDATE_PARAMS                   = 'SET_REFUSE_CANDIDATE_PARAMS';
export const REQUEST_REFUSE_CANDIDATES_MODEL               = 'REQUEST_REFUSE_CANDIDATES_MODEL';
export const RECEIVE_REFUSE_CANDIDATES_MODEL               = 'RECEIVE_REFUSE_CANDIDATES_MODEL';
export const CANDIDATES_EXPORT_REQUEST                     = 'CANDIDATES_EXPORT_REQUEST';
export const CANDIDATES_EXPORT_REQUEST_SUCCESS             = 'CANDIDATES_EXPORT_REQUEST_SUCCESS';
export const CANDIDATES_EXPORT_REQUEST_FAILURE             = 'CANDIDATES_EXPORT_REQUEST_FAILURE';
export const SAVE_CANDIDATE_GDPR_AGREEMENT                 = 'SAVE_CANDIDATE_GDPR_AGREEMENT';
export const SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS         = 'SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS';
export const SAVE_CANDIDATE_GDPR_AGREEMENT_FAILURE         = 'SAVE_CANDIDATE_GDPR_AGREEMENT_FAILURE';
export const SET_CANDIDATE_GDPR_AGREEMENT_PARAMS           = 'SET_CANDIDATE_GDPR_AGREEMENT_PARAMS';
export const SEND_CANDIDATES_QUESTIONNAIRE_REQUEST         = 'SEND_CANDIDATES_QUESTIONNAIRE_REQUEST';
export const SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_SUCCESS = 'SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_SUCCESS';
export const SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_FAILURE = 'SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_FAILURE';
export const REQUEST_MOVE_CANDIDATE_MODEL                  = 'REQUEST_MOVE_CANDIDATE_MODEL';
export const RECEIVE_MOVE_CANDIDATE_MODEL                  = 'RECEIVE_MOVE_CANDIDATE_MODEL';
export const MOVE_CANDIDATES                               = 'MOVE_CANDIDATES';
export const MOVE_CANDIDATES_SUCCESS                       = 'MOVE_CANDIDATES_SUCCESS';
export const MOVE_CANDIDATES_FAILURE                       = 'MOVE_CANDIDATES_FAILURE';
export const SET_MOVE_PARAMS                               = 'SET_MOVE_PARAMS';
export const REQUEST_ADD_TAGS_MODEL                        = 'REQUEST_ADD_TAGS_MODEL';
export const RECEIVE_ADD_TAGS_MODEL                        = 'RECEIVE_ADD_TAGS_MODEL';
export const SET_SEND_EMAIL_PARAMS                         = 'SET_SEND_EMAIL_PARAMS';

export const getCandidatesForAction = (candidateIds, outputFlags = Constants.ALL_CANDIDATE_OUTPUT_FLAGS) => ({
    type: GET_CANDIDATES_FOR_ACTION,
    payload: {
        candidateIds: candidateIds,
    },
    meta: {
        requestRoute: 'v1/candidate/data/get-candidates',
        requestMethod: 'GET',
        requestData: {
            candidateIds: JSON.stringify(candidateIds),
            outputFlags: JSON.stringify(outputFlags),
        },
        onRequestSuccess: GET_CANDIDATES_FOR_ACTION_SUCCESS,
        onRequestFailure: GET_CANDIDATES_FOR_ACTION_FAILURE,
    },
});

export const requestCopyCandidateModel = () => ({
    type: REQUEST_COPY_CANDIDATE_MODEL,
    meta: {
        requestRoute: 'v1/candidates/model/bulk-action/copy',
        onRequestSuccess: RECEIVE_COPY_CANDIDATE_MODEL,
    },
});

export const requestMoveCandidateModel = () => ({
    type: REQUEST_MOVE_CANDIDATE_MODEL,
    meta: {
        requestRoute: 'v1/candidates/model/bulk-action/move',
        onRequestSuccess: RECEIVE_MOVE_CANDIDATE_MODEL,
    },
});

export const requestChangeStageModel = (jobId = 0) => ({
    type: REQUEST_CHANGE_STAGE_MODEL,
    meta: {
        requestRoute: 'v1/candidates/model/bulk-action/change-stage',
        requestData: {
            jobId,
        },
        onRequestSuccess: RECEIVE_CHANGE_STAGE_MODEL,
    },
});

export const requestAddTagsModel = () => ({
    type: REQUEST_ADD_TAGS_MODEL,
    meta: {
        requestRoute: 'v1/candidates/model/bulk-action/add-tags',
        onRequestSuccess: RECEIVE_ADD_TAGS_MODEL,
    },
});

export const requestRefuseCandidatesModel = () => ({
    type: REQUEST_REFUSE_CANDIDATES_MODEL,
    meta: {
        requestRoute: 'v1/candidates/model/bulk-action/refuse',
        onRequestSuccess: RECEIVE_REFUSE_CANDIDATES_MODEL,
    },
});

export const setCopyParams = paramsObject => ({
    type: SET_COPY_PARAMS,
    payload: {
        params: paramsObject,
    },
});

export const copyCandidates = (actionData, candidateIds, copyParams, reducerType) => ({
    type: COPY_CANDIDATES,

    payload: {
        copyParams,
        candidateIds,
        reducerType,
    },

    meta: {
        requestRoute: 'v1/candidates/action/copy',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: COPY_CANDIDATES_SUCCESS,
        onRequestFailure: COPY_CANDIDATES_FAILURE,
    },
});

export const moveCandidates = (actionData, candidateIds, copyParams, reducerType) => ({
    type: MOVE_CANDIDATES,

    payload: {
        copyParams,
        candidateIds,
        reducerType,
    },

    meta: {
        requestRoute: 'v1/candidates/action/move',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: MOVE_CANDIDATES_SUCCESS,
        onRequestFailure: MOVE_CANDIDATES_FAILURE,
    },
});

export const setMoveParams = paramsObject => ({
    type: SET_MOVE_PARAMS,
    payload: {
        params: paramsObject,
    },
});

export const copyCandidateIntoTalentPool = (actionData, candidateId, reducerType) => ({
    type: COPY_CANDIDATES_INTO_TALENT_POOL,

    payload: {
        candidateId,
        reducerType,
    },

    meta: {
        requestRoute: 'v1/candidate/action/copy-into-talent-pool',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: COPY_CANDIDATES_INTO_TALENT_POOL_SUCCESS,
        onRequestFailure: COPY_CANDIDATES_INTO_TALENT_POOL_FAILURE,
    },
});

export const removeCandidateFromTalentPool = (actionData, candidateId, reducerType) => ({
    type: REMOVE_CANDIDATES_FROM_TALENT_POOL,

    payload: {
        candidateId,
        reducerType,
    },

    meta: {
        requestRoute: 'v1/candidate/action/remove-from-talent-pool',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: REMOVE_CANDIDATES_FROM_TALENT_POOL_SUCCESS,
        onRequestFailure: REMOVE_CANDIDATES_FROM_TALENT_POOL_FAILURE,
    },
});

export const sendEmail = (actionData, candidateIds, sendToCandidates = false, ignoreChunks = false) => ({
    type: SEND_EMAIL,

    payload: {
        sendToCandidates,
        candidateIds,
    },

    meta: {
        requestRoute: 'v1/candidates/action/send-email',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: SEND_EMAIL_SUCCESS,
        onRequestFailure: SEND_EMAIL_FAILURE,
    },
});

export const changeCandidatesStage = (actionData, candidateIds, stage, sendToCandidates = false, positionInStage = 0) => ({
    type: CHANGE_CANDIDATES_STAGE,

    payload: {
        candidateIds,
        stage,
        sendToCandidates,
        positionInStage,
        stageId: stage.stageId,
        stageName: stage.stageName,
    },

    meta: {
        requestRoute: 'v1/candidates/action/set-stage',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: CHANGE_CANDIDATES_STAGE_SUCCESS,
        onRequestFailure: CHANGE_CANDIDATES_STAGE_FAILURE,
    },
});

export const setChangeCandidatesStageParams = (paramsObject) => ({
    type: SET_CHANGE_CANDIDATES_STAGE_PARAMS,
    payload: {
        params: paramsObject,
    },
});

export const setScheduleEventParams = paramsObject => ({
    type: SET_SCHEDULE_EVENT_PARAMS,
    payload: {
        params: paramsObject,
    },
});

export const requestCandidateEventModel = (candidateIds, eventId = 0, eventType = Constants.CANDIDATE_EVENT_TYPE_INTERVIEW, outputFlags = Constants.ALL_CANDIDATE_OUTPUT_FLAGS) => ({
    type: REQUEST_CANDIDATE_EVENT_MODEL,

    payload: {
        candidateIds: candidateIds,
        eventType,
    },

    meta: {
        requestData: {
            candidateIds: JSON.stringify(candidateIds),
            eventType,
            outputFlags: JSON.stringify(outputFlags),
        },

        requestRoute: 'v1/candidate/model/event/' + eventId,
        onRequestSuccess: RECEIVE_CANDIDATE_EVENT_MODEL,
    },
});

export const scheduleCandidateEvent = (actionData, eventId, candidateIds, sendToCandidates = false) => ({
    type: SCHEDULE_CANDIDATE_EVENT,

    payload: {
        eventId,
        candidateIds,
        sendToCandidates,
    },

    meta: {
        requestRoute: 'v1/candidate/action/schedule-event',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: SCHEDULE_CANDIDATE_EVENT_SUCCESS,
        onRequestFailure: SCHEDULE_CANDIDATE_EVENT_FAILURE,
    },
});

export const deleteCandidateEvent = (actionData, eventId, candidateId) => ({
    type: DELETE_CANDIDATE_EVENT,
    payload: {
        eventId,
        candidateId,
    },
    meta: {
        requestRoute: 'v1/candidate/action/delete-event',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: DELETE_CANDIDATE_EVENT_SUCCESS,
        onRequestFailure: DELETE_CANDIDATE_EVENT_FAILURE,
    },
});

export const anonymizeCandidates = (actionData, candidateIds) => ({
    type: ANONYMIZE_CANDIDATES,

    payload: {
        candidateIds,
    },

    meta: {
        requestRoute: 'v1/candidate/action/anonymize',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: ANONYMIZE_CANDIDATES_SUCCESS,
        onRequestFailure: ANONYMIZE_CANDIDATES_FAILURE,
    },
});

export const candidatesExportRequest = exportCandidateIds => ({
    type: CANDIDATES_EXPORT_REQUEST,

    payload: {
        exportCandidateIds,
    },

    meta: {
        requestRoute: 'v1/candidate/action/export-request',
        requestMethod: 'POST',
        requestBody: JSON.stringify({exportCandidateIds}),

        onRequestSuccess: CANDIDATES_EXPORT_REQUEST_SUCCESS,
        onRequestFailure: CANDIDATES_EXPORT_REQUEST_FAILURE,
    },
});

export const refuseCandidateAndSendEmail = (actionData, candidateIds, sendToCandidates = false, reducerType) => ({
    type: REFUSE_CANDIDATE_AND_SEND_EMAIL,
    payload: {
        candidateIds,
        sendToCandidates,
        reducerType,
    },
    meta: {
        requestRoute: 'v1/candidate/action/refuse-and-send',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: REFUSE_CANDIDATE_AND_SEND_EMAIL_SUCCESS,
        onRequestFailure: REFUSE_CANDIDATE_AND_SEND_EMAIL_FAILURE,
    },
});

export const setRefuseCandidatesParams = (paramsObject) => ({
    type: SET_REFUSE_CANDIDATE_PARAMS,
    payload: {
        params: paramsObject,
    },
});

export const saveCandidateGdprAgreement = (actionData, candidateId, hashId) => ({
    type: SAVE_CANDIDATE_GDPR_AGREEMENT,
    payload: {
        candidateId,
        hashId,
    },
    meta: {
        requestRoute: 'v1/candidate/action/save-gdpr-agreement',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: SAVE_CANDIDATE_GDPR_AGREEMENT_SUCCESS,
        onRequestFailure: SAVE_CANDIDATE_GDPR_AGREEMENT_FAILURE,
    },
});

export const setCandidateGdprAgreementParams = (paramsObject) => ({
    type: SET_CANDIDATE_GDPR_AGREEMENT_PARAMS,
    payload: {
        params: paramsObject,
    },
});

export const sendCandidatesQuestionnaireRequest = (actionData, candidateIds) => ({
    type: SEND_CANDIDATES_QUESTIONNAIRE_REQUEST,
    payload: {
        candidateIds,
    },
    meta: {
        requestRoute: 'v1/candidate/action/send-candidates-questionnaire-request',
        requestMethod: 'POST',
        requestBody: actionData,
        onRequestSuccess: SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_SUCCESS,
        onRequestFailure: SEND_CANDIDATES_QUESTIONNAIRE_REQUEST_FAILURE,
    },
});

export const setSendEmailParams = paramsObject => ({
    type: SET_SEND_EMAIL_PARAMS,
    payload: {
        params: paramsObject,
    },
});
