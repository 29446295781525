export const FORM_LANGUAGE = 'language';
export const FORM_JOB_TITLE = 'jobTitle';
export const FORM_JOB_POSITIONS = 'jobPositions';
export const FORM_EMPLOYMENT_TYPES = 'employmentTypes';
export const FORM_OFFER_SUITABLE_FOR = 'offerSuitableFor';
export const FORM_JOB_DESCRIPTION = 'jobDescription';
export const FORM_BENEFITS = 'benefits';
export const FORM_POSITION_PLACE_TYPE = 'positionPlace.type';
export const FORM_POSITION_PLACE_ADDRESS = 'positionPlace.address';
export const FORM_POSITION_PLACE_REGIONS = 'positionPlace.regions';
export const FORM_POSITION_START_DATE = 'positionPlace.startDate';
export const FORM_SALARY = 'salary';
export const FORM_SALARY_TYPE = 'salary.type';
export const FORM_SALARY_SHOW_TO = 'salary.showTo';
export const FORM_SALARY_VALUE = 'salary.value';
export const FORM_SALARY_FROM = 'salary.from';
export const FORM_SALARY_TO = 'salary.to';
export const FORM_SALARY_CURRENCY = 'salary.currency';
export const FORM_SALARY_PERIOD = 'salary.period';
export const FORM_SALARY_OTHER_INFORMATION = 'salary.otherInformation';
export const FORM_SKILLS_OTHERS = 'skills.others';
export const FORM_SKILLS_DRIVING_LICENCE = 'skills.drivingLicence';
export const FORM_SKILLS_YEARS_OF_EXPERIENCE = 'skills.yearsOfExperience';
export const FORM_SKILLS_EXPERIENCE_AT_POSITION = 'skills.experienceAtPosition';
export const FORM_SKILLS_PERSONAL = 'skills.personalSkills';
export const FORM_EDUCATION_LEVEL = 'educationLevel';
export const FORM_EDUCATION_SPECIALIZATION = 'educationSpecialization';
export const FORM_LANGUAGES_ITEMS = 'languages.items';
export const FORM_LANGUAGES_REQUIRED = 'languages.required';
export const FORM_PREREQUISITES = 'prerequisites';
export const FORM_NOTE_FOR_CANDIDATE = 'noteForCandidate';
export const FORM_NUMBER_OF_OPENINGS = 'numberOfOpenings';
export const FORM_APPLICABLE_TILL = 'applicableTill';
export const FORM_IS_APPLICABLE_WITHOUT_CV = 'isApplicableWithoutCv';
export const FORM_SHOULD_BE_NOTIFIED_ABOUT_NEW_RESUMES = 'shouldBeNotifiedAboutNewResumes';
export const FORM_REFERENCE_NUMBER = 'referenceNumber';
export const FORM_CONTACT_PERSON_ID = 'contactPersonId';
export const FORM_CONTACT_PERSON_FIRST_NAME = 'contactPerson.firstName';
export const FORM_CONTACT_PERSON_LAST_NAME = 'contactPerson.lastName';
export const FORM_CONTACT_PERSON_EMAIL = 'contactPerson.email';
export const FORM_CONTACT_PERSON_PHONE = 'contactPerson.phone';
export const FORM_CONTACT_PERSON_ACADEMIC_DEGREE = 'contactPerson.academicDegree';
export const FORM_COMPANY_ID = 'company.id';
export const FORM_COMPANY_BUSINESS_AREA_ID = 'company.businessAreaId';
export const FORM_COMPANY_CHARACTERISTICS = 'company.characteristics';
export const FORM_COMPANY_LOGO_IMAGE = 'company.logoImage';
export const getLevelOptionsPath = (index, valuePaths) => `${valuePaths}[${index}].level`;
