import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
export var LanguageLevel;
(function (LanguageLevel) {
    LanguageLevel["A1"] = "a1";
    LanguageLevel["A2"] = "a2";
    LanguageLevel["B1"] = "b1";
    LanguageLevel["B2"] = "b2";
    LanguageLevel["C1"] = "c1";
    LanguageLevel["C2"] = "c2";
})(LanguageLevel || (LanguageLevel = {}));
export var SkillLevel;
(function (SkillLevel) {
    SkillLevel["Beginner"] = "beginner";
    SkillLevel["Basic"] = "basic";
    SkillLevel["SkillFull"] = "skillFull";
    SkillLevel["Advanced"] = "advanced";
    SkillLevel["Expert"] = "expert";
})(SkillLevel || (SkillLevel = {}));
const languageLevels = Object.values(LanguageLevel);
const skillLevels = Object.values(SkillLevel);
export const useGetLanguageLevelOptions = () => {
    const t = useGetFixedT('codebooks.languageLevel');
    return languageLevels.map((level, index) => ({ id: `${index + 1}`, label: t(level) }));
};
export const useGetOtherSkillLevelOptions = () => {
    const t = useGetFixedT('codebooks.otherSkillLevel');
    return skillLevels.map((level, index) => ({ id: `${index + 1}`, label: t(level) }));
};
