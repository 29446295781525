import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import HiddenFields from '@profesia/adamui/components/common/HiddenFields';
import { NumberField } from '@profesia/adamui/components/common/input';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { trackEvent } from '@profesia/mark-offer-form/utils/track/track';
import { LOCATION_JOB_AD, NAME_NUMBER_OF_OPENINGS, } from '@profesia/mark-offer-form/utils/track/constants';
import { FORM_NUMBER_OF_OPENINGS } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { NARROW_INPUT_WIDTH, NUMBER_OF_OPENINGS_MAX_LENGTH, } from '@profesia/mark-offer-form/components/content/form/constants';
const NumberOfOpenings = () => {
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_NUMBER_OF_OPENINGS, control });
    const t = useGetFixedT('selectionProcessInfo.numberOfOpenings');
    return (_jsx(HiddenFields, { buttonLabel: t('label'), shouldBeHidden: !value, children: _jsx(NumberField, { label: t('label'), value: value ?? undefined, inputProps: { maxLength: NUMBER_OF_OPENINGS_MAX_LENGTH }, onChange: (newValue) => {
                setValue(FORM_NUMBER_OF_OPENINGS, newValue);
            }, inputWidth: NARROW_INPUT_WIDTH, disableZeroValue: true }), onClick: () => trackEvent(LOCATION_JOB_AD, NAME_NUMBER_OF_OPENINGS, null) }));
};
export default NumberOfOpenings;
