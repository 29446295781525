import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled, Divider } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import ContactPersonDetails from './ContactPersonDetails';
import ContactPersonSelect from './ContactPersonSelect';
const Card = ({ contactPersons, selectedContactPerson }) => {
    return (_jsxs(Box, { "data-testid": "contact-details-content", children: [_jsx(ContactPersonSelect, { contactPersons: contactPersons }), _jsx(StyledDivider, { "data-testid": "divider" }), _jsx(ContactPersonDetails, { contactPerson: selectedContactPerson })] }));
};
const StyledDivider = styled(Divider)(({ theme: { spacing, palette } }) => ({
    maxWidth: '500px',
    marginTop: spacing(2.75),
    marginBottom: spacing(2.25),
    borderColor: getColor(palette.neutralLight4),
}));
export default Card;
