import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState, } from 'react';
import { Stack } from '@mui/material';
import useTagsState from '@profesia/adamui/components/common/input/textField/tagField/hooks/useTagsState';
import useOptionsState from '@profesia/adamui/components/common/input/textField/tagField/hooks/useOptionsState';
import { getPlaceholder, MoreTags, NoOptionsText, TagFieldContext, } from '@profesia/adamui/components/common/input/textField/tagField/common';
import ValidationMessage from '@profesia/adamui/components/common/input/ValidationMessage';
import { getSizeClass } from '@profesia/adamui/components/common/input/textField/tagField/common/helpers';
import BottomLabel from '@profesia/adamui/components/common/input/textField/BottomLabel';
import AutocompleteInput from '@profesia/adamui/components/common/input/autocomplete/AutocompleteInput';
import BaseAutocomplete from '@profesia/adamui/components/common/input/autocomplete/BaseAutocomplete';
const minimalInputLengthForSearch = 2;
const checkSearchable = (input, minimalLength = minimalInputLengthForSearch) => input.length >= minimalLength;
const getAutocompleteState = (state, lastInput) => ({ ...state, inputValue: lastInput });
const wasEnterPressed = (e) => e.key === 'Enter';
const TagField = ({ value = [], label, limit, inputFieldLabelProps, onChange, placeholder, options = [], optionsProps = {}, noOptionsText, onLimitReached, invalidCharsPattern, shouldKeepLastFilteredOptions, allowEnter, groupProps, enterKeyHint, dataCy, disabled, filterSelectedOptions, errorMessage, minimalInputLength, isHighlightingEnabled = true, size = 'medium', shouldCloseOnLimitReached = false, displayUnwrapped, bottomLabel, onMouseLeave, onMouseOver, }) => {
    const [internalValue, setInternalValue] = useState(value);
    const hasReachedLimit = internalValue.length === limit;
    const canTriggerToast = useRef(false);
    const { getOptions, filterOptions, renderOption, ...otherOptionsProps } = optionsProps;
    useEffect(() => {
        setInternalValue(value);
    }, [value]);
    useEffect(() => {
        if (hasReachedLimit && canTriggerToast.current) {
            onLimitReached?.();
        }
        if (shouldCloseOnLimitReached && hasReachedLimit) {
            onPopperClose(true);
        }
    }, [hasReachedLimit]);
    const isGroupType = Boolean(groupProps?.groupValues);
    const { groupValues = (values) => values, groups, renderTagsModifier, } = groupProps ?? {};
    const [groupCount, selectedGroup] = useMemo(() => {
        if (!isGroupType) {
            return [null, null];
        }
        const groupCountMap = new Map();
        let selectedGroup = null;
        internalValue.forEach(({ categoryId }) => {
            if (categoryId) {
                const newCount = (groupCountMap.get(categoryId) ?? 0) + 1;
                groupCountMap.set(categoryId, newCount);
                if (newCount === groups?.[categoryId]?.count) {
                    selectedGroup = categoryId;
                }
            }
        });
        return [groupCountMap, selectedGroup];
    }, [internalValue.length]);
    const { renderTags, onInputChange, resetInput, input, lastInput, limitTags, ref, showPlaceholder, resetLastInput, ...focusControl } = useTagsState(size, internalValue, hasReachedLimit, onChange, disabled, invalidCharsPattern, renderTagsModifier, groupValues, selectedGroup, displayUnwrapped);
    const handleOpen = (inputValue = input) => {
        return ((!hasReachedLimit || isGroupType) &&
            (!getOptions || checkSearchable(inputValue, minimalInputLength)) &&
            (minimalInputLength === undefined || checkSearchable(inputValue, minimalInputLength)));
    };
    const { clearOptions, debouncedGetAndSetOptions, onPopperOpen, onPopperClose, internalOptions, isOpen, isLoading, } = useOptionsState(options, getOptions, () => onChange(internalValue), handleOpen, minimalInputLength);
    useEffect(() => {
        if (!hasReachedLimit && !isOpen && input !== '') {
            resetInput();
        }
    }, [hasReachedLimit]);
    useEffect(() => {
        if (checkSearchable(input, minimalInputLength) && debouncedGetAndSetOptions) {
            debouncedGetAndSetOptions(input);
        }
        else {
            clearOptions();
        }
    }, [input]);
    const [contextInput, filterOptionsInternal, renderOptionInternal] = shouldKeepLastFilteredOptions
        ? [
            lastInput,
            filterOptions
                ? (options, state) => filterOptions(options, getAutocompleteState(state, lastInput))
                : filterOptions,
            renderOption
                ? (_, option, state) => renderOption(_, option, getAutocompleteState(state, lastInput))
                : renderOption,
        ]
        : [input, filterOptions, renderOption];
    const context = {
        onPaperButtonClick: onPopperClose,
        onChange: (value) => {
            canTriggerToast.current = true;
            setInternalValue(() => value);
            resetInput();
            if (getOptions) {
                onChange(value);
                onPopperClose();
            }
        },
        values: internalValue,
        input: contextInput,
        isLoading,
        hasReachedLimit,
        groupCount,
        selectedGroup,
        isHighlightingEnabled,
    };
    const hasError = Boolean(errorMessage);
    const className = getSizeClass(size);
    return (_jsx(TagFieldContext.Provider, { value: context, children: _jsxs(Stack, { onMouseOver: onMouseOver, onMouseLeave: onMouseLeave, children: [_jsx(BaseAutocomplete, { className: className, multiple: true, includeInputInList: true, disableClearable: true, clearOnBlur: true, filterSelectedOptions: filterSelectedOptions || Boolean(getOptions), open: isOpen, onOpen: onPopperOpen, onClose: (e) => {
                        onPopperClose(!wasEnterPressed(e));
                    }, "data-cy": dataCy, forcePopupIcon: false, ref: ref, inputValue: input, onInputChange: onInputChange, value: internalValue, limitTags: displayUnwrapped ? -1 : limitTags, renderInput: ({ inputProps, ...otherParams }) => (_jsx(AutocompleteInput, { displayUnwrapped: displayUnwrapped, className: className, label: label, inputFieldLabelProps: inputFieldLabelProps, hasTags: true, placeholder: getPlaceholder(hasReachedLimit, showPlaceholder, internalValue.length, placeholder), isEmpty: internalValue?.length === 0, error: hasError, inputProps: {
                            ...inputProps,
                            readOnly: hasReachedLimit,
                            enterKeyHint: enterKeyHint,
                        }, hasReachedLimit: hasReachedLimit, onKeyDown: (e) => {
                            if (allowEnter && wasEnterPressed(e)) {
                                if (input) {
                                    const newValue = [...internalValue, { value: input }];
                                    setInternalValue(newValue);
                                    onChange(newValue);
                                }
                                else {
                                    onPopperClose(true);
                                }
                                resetInput();
                                resetLastInput();
                            }
                        }, ...otherParams })), onChange: (e, newVal) => {
                        if (wasEnterPressed(e) && !newVal) {
                            return;
                        }
                        const value = newVal ?? [];
                        setInternalValue(value);
                        onChange(value);
                    }, renderTags: renderTags, options: internalOptions, noOptionsText: _jsx(NoOptionsText, { children: noOptionsText }), getLimitTagsText: (more) => _jsx(MoreTags, { more: more, size: size }), isOptionEqualToValue: ({ id: id1 }, { id: id2 }) => id1 === id2, filterOptions: filterOptionsInternal, renderOption: renderOptionInternal, disabled: disabled, ...otherOptionsProps, ...focusControl }), hasError ? _jsx(ValidationMessage, { children: errorMessage }) : null, bottomLabel && !hasError ? (_jsx(BottomLabel, { fontSize: "medium", children: bottomLabel })) : null] }) }));
};
export default TagField;
