import React from 'react';

interface SendEmailActionsWrapperProps {
    hasChanges: boolean;
    hasAllowedEditableEmailTemplates: boolean;
    children: React.ReactNode;
}

const SendEmailActionsWrapper: React.FC<SendEmailActionsWrapperProps> = ({
    hasChanges,
    hasAllowedEditableEmailTemplates,
    children,
}) => {
    return hasChanges === true && hasAllowedEditableEmailTemplates ? (
        <div className={'template-assistant-settings'}>{children}</div>
    ) : null;
};

export default SendEmailActionsWrapper;
