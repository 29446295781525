import { useMutation } from '@tanstack/react-query';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import { UPDATE_CONTACT_PERSON } from '../../keys';
export const useContactPersonUpdate = (onSuccess, onError) => {
    const { api } = getConfiguration();
    return useMutation({
        mutationKey: [UPDATE_CONTACT_PERSON],
        mutationFn: async (contactPerson) => {
            await api.updateContactPerson(contactPerson);
            return contactPerson;
        },
        onSuccess,
        onError,
    });
};
