import { get } from 'react-hook-form';
const checkErrorExists = (errors, name) => {
    const error = get(errors, name);
    return !Array.isArray(error) && error !== undefined;
};
const getSetValue = (formSetValue, clearErrors, errors) => (...props) => {
    const [name, value, options] = props;
    formSetValue(name, value, { shouldDirty: true, ...options });
    if (checkErrorExists(errors, name)) {
        clearErrors(name);
    }
};
export default getSetValue;
