import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Autocomplete } from '@profesia/adamui/components/common/input';
import { useGetFixedT, useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import { useGetBusinessAreas } from '@profesia/mark-offer-form/services/codebooks';
import useToastWithTranslation from '@profesia/mark-offer-form/services/useToastWithTranslation';
import { FORM_COMPANY_BUSINESS_AREA_ID } from '@profesia/mark-offer-form/components/content/form/valuePaths';
const BusinessArea = () => {
    const pageT = useTranslationInPageLanguage();
    const t = useGetFixedT('companyDetails');
    const { setValue, control, formState: { errors }, } = useFormContext();
    const businessAreaId = useWatch({ name: FORM_COMPANY_BUSINESS_AREA_ID, control });
    const { data, isLoading, isError } = useGetBusinessAreas();
    const addToast = useToastWithTranslation();
    const transformedData = data?.flatMap((category) => category.items.map((item) => ({
        id: Number(item.id),
        name: item.name,
        categoryName: category.categoryName,
    })));
    const value = transformedData?.find((item) => item.id === businessAreaId);
    useEffect(() => {
        if (isError) {
            addToast({ message: 'common.responseError.codebooks', variant: 'error' });
        }
    }, [isError]);
    return (_jsx(Autocomplete, { label: t('mainFocus'), value: value ?? null, options: transformedData ?? [], placeholder: isLoading ? pageT('common.loading') : '', onChange: (event, value) => setValue(FORM_COMPANY_BUSINESS_AREA_ID, value?.id), required: true, isDisabled: isLoading, hasError: Boolean(errors?.company?.businessAreaId), errorMessage: errors?.company?.businessAreaId?.message }));
};
export default BusinessArea;
