import { FORM_JOB_POSITIONS } from '@profesia/mark-offer-form/components/content/form/valuePaths';
const updatePositionValues = (setValue, positions, positionValues) => {
    if (positionValues.length === 0) {
        return;
    }
    const positionsMap = new Map(positions.map((pos) => [pos.id, pos]));
    const updatedValues = positionValues
        .map((value) => positionsMap.get(value?.id))
        .filter((value) => value !== undefined);
    setValue(FORM_JOB_POSITIONS, updatedValues, { shouldDirty: false });
};
export default updatePositionValues;
