import { ValidationSchema, } from '@profesia/mark-offer-form/components/content/form/types';
import getJobErrors from './getJobErrors';
import getContactPersonErrors from './getContactPersonErrors';
import getCompanyErrors from './getCompanyErrors';
const validationErrorHandler = {
    [ValidationSchema.Job]: ({ jobTitle, jobPositions, employmentTypes, jobDescription, positionPlace, salary, educationLevel, languages, skills, }, t) => {
        const formData = {
            jobTitle,
            jobPositions,
            employmentTypes,
            jobDescription,
            positionPlace,
            salary,
            educationLevel,
            languages,
            skills,
        };
        return getJobErrors(formData, t);
    },
    [ValidationSchema.ContactPerson]: ({ contactPerson }, t) => {
        return getContactPersonErrors({ contactPerson }, t);
    },
    [ValidationSchema.Company]: ({ company }, t) => {
        return getCompanyErrors({ company }, t);
    },
};
export default validationErrorHandler;
