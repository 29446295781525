import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TagField } from '@profesia/adamui/components/common/input';
import { useGetFixedT, useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import { getError } from '@profesia/mark-offer-form/components/content/form';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { useTagFieldAdapter } from '@profesia/mark-offer-form/components/content/common/tagFieldAdapter';
import { FORM_POSITION_PLACE_REGIONS } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import useTagsLimitLabel from '@profesia/mark-offer-form/components/content/common/useTagsLimitLabel';
const regionPlaceLimit = 3;
const RegionPlaceAutocomplete = ({ options, tPrefix }) => {
    const t = useGetFixedT(tPrefix);
    const tCommon = useGetFixedT('common.comboTagger');
    const tPage = useTranslationInPageLanguage(tPrefix);
    const { setValue, control, formState: { errors }, } = useFormContext();
    const { isEditMode } = useOptionsContext();
    const values = useWatch({ name: FORM_POSITION_PLACE_REGIONS, control });
    const [errorMessage, setErrorMessage] = useState('');
    const bottomLabel = useTagsLimitLabel(regionPlaceLimit, values.length, tPage);
    const error = getError(errors.positionPlace?.regions) || '';
    useEffect(() => {
        if (errorMessage !== error) {
            setErrorMessage(error);
        }
    }, [error]);
    const tagFieldProps = useTagFieldAdapter(values, (newValues) => {
        if (!error || newValues.length > 0) {
            setValue(FORM_POSITION_PLACE_REGIONS, newValues);
        }
    });
    useEffect(() => {
        if (!isEditMode && (!error || values.length > 0)) {
            setValue(FORM_POSITION_PLACE_REGIONS, []);
        }
    }, []);
    return (_jsx(TagField, { filterSelectedOptions: true, shouldCloseOnLimitReached: true, noOptionsText: tCommon('noOptions'), placeholder: { empty: t('placeholder'), filled: '' }, inputFieldLabelProps: {
            required: true,
        }, options: options, size: "large", limit: regionPlaceLimit, errorMessage: errorMessage, bottomLabel: bottomLabel, ...tagFieldProps }));
};
export default RegionPlaceAutocomplete;
