import Constants from './Constants';
import User from './User';
import { LoginManagerModelType } from '../../interfaces/LoginManagerModelType';
import { ActivePackageType } from '../../interfaces/ModelTypes';
import { CommonObjectType } from '../../interfaces/CommonTypes';

class UserRights {
    static isMenuItemHidden(item: CommonObjectType): boolean {
        return item.hasOwnProperty('visible') && item.visible === false;
    }

    static hasActiveSomeSubscription(loginManagerModel: LoginManagerModelType): boolean {
        const subscription: ActivePackageType | null = loginManagerModel?.activePackage ?? null;

        return this.hasActiveTrialSubscription(subscription)
            || this.hasActiveFreeSubscription(loginManagerModel)
            || this.hasActiveBasicSubscription(subscription)
            || this.hasActiveStandardSubscription(subscription)
            || this.hasActiveProSubscription(subscription);
    }

    static hasActiveTrialSubscription(subscription: ActivePackageType | null = null): boolean {
        return subscription !== null && subscription.serviceId === Constants.SERVICE_ID_TRIAL;
    }

    static hasActiveFreeSubscription(loginManagerModel: LoginManagerModelType): boolean {
        return loginManagerModel.hasOwnProperty('activePackage')
            && (
                loginManagerModel.activePackage === null
                || loginManagerModel.activePackage?.serviceId === Constants.SERVICE_ID_FREE
            )
            && !User.isBasic(loginManagerModel);
    }

    static hasActiveProSubscription(subscription: ActivePackageType | null = null): boolean {
        return subscription !== null && subscription.serviceId === Constants.SERVICE_ID_PRO;
    }

    static hasActiveStandardSubscription(subscription: ActivePackageType | null = null): boolean {
        return subscription !== null && subscription.serviceId === Constants.SERVICE_ID_STANDARD;
    }

    static hasActiveBasicSubscription(subscription: ActivePackageType | null = null): boolean {
        return subscription !== null && [Constants.SERVICE_ID_BASIC, Constants.SERVICE_ID_BASIC_OLD].includes(subscription.serviceId);
    }

    static hasAllowedFeatureFromBasicSubscription(subscription: ActivePackageType | null = null): boolean {
        return this.hasActiveBasicSubscription(subscription)
            || this.hasActiveStandardSubscription(subscription)
            || this.hasActiveProSubscription(subscription)
            || this.hasActiveTrialSubscription(subscription);
    }

    static hasAllowedFeatureFromStandardSubscription(subscription: ActivePackageType | null = null): boolean {
        return this.hasActiveStandardSubscription(subscription)
            || this.hasActiveProSubscription(subscription)
            || this.hasActiveTrialSubscription(subscription);
    }

    static hasAllowedFeatureFromProSubscription(subscription: ActivePackageType | null = null): boolean {
        return this.hasActiveProSubscription(subscription)
            || this.hasActiveTrialSubscription(subscription);
    }

    static hasAllowedAutoAnswers(subscription: ActivePackageType | null = null): boolean {
        return this.hasAllowedFeatureFromBasicSubscription(subscription);
    }

    static hasAllowedQuestionnaire(subscription: ActivePackageType | null = null): boolean {
        return this.hasAllowedFeatureFromBasicSubscription(subscription);
    }

    static hasAllowedEditableEmailTemplates(subscription: ActivePackageType | null = null): boolean {
        return this.hasAllowedFeatureFromBasicSubscription(subscription);
    }

    static hasAllowedEditableAutoAnswerTemplates(subscription: ActivePackageType | null = null): boolean {
        return this.hasAllowedFeatureFromBasicSubscription(subscription);
    }

    static hasAllowedEditableQuestionnaireTemplates(subscription: ActivePackageType | null = null): boolean {
        return this.hasAllowedFeatureFromBasicSubscription(subscription);
    }

    static hasAllowedEditablePipelines(subscription: ActivePackageType | null = null): boolean {
        return this.hasAllowedFeatureFromBasicSubscription(subscription);
    }

    static hasAllowedColleagueInvitationIntoJob(subscription: ActivePackageType | null = null): boolean {
        return this.hasAllowedFeatureFromBasicSubscription(subscription);
    }

    static hasAllowedStatistics(subscription: ActivePackageType | null = null): boolean {
        return this.hasAllowedFeatureFromProSubscription(subscription);
    }

    static hasAllowedExport(subscription: ActivePackageType | null = null): boolean {
        return this.hasAllowedFeatureFromProSubscription(subscription);
    }

    static hasAllowedMultiPipelines(subscription: ActivePackageType | null = null): boolean {
        return this.hasAllowedFeatureFromProSubscription(subscription);
    }

    static getFeaturePermissionClassName(hasAllowedFeature: boolean): string {
        return hasAllowedFeature ? '' : Constants.FEATURE_PERMISSION_CLASS_NAME;
    }

    static isActiveCancellableSubscription(subscription: ActivePackageType | null = null): boolean {
        return subscription !== null && [Constants.SERVICE_ID_BASIC, Constants.SERVICE_ID_PRO].includes(subscription.serviceId);
    }

    static isActiveQuittableSubscription(subscription: ActivePackageType | null = null): boolean {
        return subscription !== null
            && (
                subscription.serviceId === Constants.SERVICE_ID_TRIAL
                || subscription.serviceId === Constants.SERVICE_ID_FREE
            );
    }

    static getPackageWithAllowedTags(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_BASIC,
            name: Constants.MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static getPackageWithAllowedEmails(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_BASIC,
            name: Constants.MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static getPackageWithAllowedAutoAnswers(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_BASIC,
            name: Constants.MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static getPackageWithAllowedQuestionnaire(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_BASIC,
            name: Constants.MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static getPackageWithAllowedStatistics(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_PRO,
            name: Constants.MARK_PRO_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static getPackageWithAllowedExport(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_PRO,
            name: Constants.MARK_PRO_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static getPackageWithAllowedEditableEmailTemplates(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_BASIC,
            name: Constants.MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static getPackageWithAllowedEditableAutoAnswerTemplates(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_BASIC,
            name: Constants.MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static getPackageWithAllowedEditableQuestionnaireTemplates(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_BASIC,
            name: Constants.MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static getPackageWithAllowedMultiPipelines(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_PRO,
            name: Constants.MARK_PRO_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static getPackageWithAllowedEditablePipelines(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_BASIC,
            name: Constants.MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static getPackageWithAllowedColleagueInvitationIntoJob(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_BASIC,
            name: Constants.MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static getPackageWithAllowedCandidatesArchive(): CommonObjectType {
        return {
            serviceId: Constants.SERVICE_ID_BASIC,
            name: Constants.MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME,
        };
    }

    static hasCompanyEverHadPackage(loginManagerModel: LoginManagerModelType): boolean {
        return loginManagerModel?.hasCompanyEverHadPackage ?? false;
    }

    static hasCompanyOnlyExpiredTrialSubscription(loginManagerModel: LoginManagerModelType): boolean {
        return loginManagerModel?.hasCompanyOnlyExpiredTrialSubscription ?? false;
    }

    static isForbiddenToEnterMark(loginManagerModel: LoginManagerModelType): boolean {
        return (User.isBasic(loginManagerModel) && !User.isExternalUser(loginManagerModel)) || !UserRights.hasActiveSomeSubscription(loginManagerModel);
    }

    static isFreeSubscription(loginManagerModel: LoginManagerModelType): boolean {
        return loginManagerModel?.activePackage === null && [Constants.USER_ROLE_MANAGER, Constants.USER_ROLE_RECRUITER].includes(loginManagerModel?.user?.userRole.id ?? 0);
    }

    static hasTalentPool(loginManagerModel: LoginManagerModelType): boolean {
        return loginManagerModel?.talentPool?.hasUserTalentPool === true;
    }
}

export default UserRights;
