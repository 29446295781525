import apiRequest from './apiRequest';
import { CompanyData, CompanyDataPayload } from './types';

const updateCompanyDetails = async (companyData: CompanyData) => {
    const { companyId, businessAreaId, characteristics, languageFlag } = companyData;

    if (!companyId) {
        throw new Error('Company ID is required.');
    }

    const payload: CompanyDataPayload = {
        businessAreaId: businessAreaId,
        shortCharacteristic: characteristics,
        languageFlag: languageFlag,
    };

    try {
        await apiRequest(`company/${companyId}`, {
            method: 'PUT',
            body: JSON.stringify(payload),
        });
    } catch (error) {
        throw new Error('Failed to update company details');
    }
};

export default updateCompanyDetails;
