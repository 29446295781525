import React from 'react';
import { Stack, styled } from '@mui/material';
import { getColor } from '@profesia/adamui/styles/colors';
import SkeletonRectangular from '@profesia/adamui/components/common/skeleton/SkeletonRectangular';

const Header = () => (
    <StyledStack data-testid="job-statistics-header-skeleton">
        <Stack
            flex={1}
            px={4}
            py={3.5}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <SkeletonRectangular width={384} height={24} borderRadius={12} />

            <Stack spacing={2} direction="row">
                {[...Array(2)].map((e, i) => (
                    <SkeletonRectangular width={120} height={36} borderRadius={4} key={i} />
                ))}
            </Stack>
        </Stack>
    </StyledStack>
);

const StyledStack = styled(Stack)(({ theme: { palette } }) => ({
    backgroundColor: getColor(palette.neutralLight1),
}));

export default Header;
