import { jsx as _jsx } from "react/jsx-runtime";
import { get, useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import ComboTagger from '@profesia/adamui/components/common/input/textField/ComboTagger';
import { FORM_SKILLS_OTHERS } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { useGetOtherSkillLevelOptions } from '@profesia/mark-offer-form/codebooks';
import { getSkillListErrors } from '@profesia/mark-offer-form/components/content/Skills/getErrors';
import { SCROLL_TO_ERROR_CLASS } from '@profesia/mark-offer-form/styles/classes';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import useToastWithTranslation from '@profesia/mark-offer-form/services/useToastWithTranslation';
import { getAutocompleteSkills } from '@profesia/mark-offer-form/services';
import { useAsyncOptionsTagFieldAdapter } from '@profesia/mark-offer-form/components/content/common/tagFieldAdapter';
const Skills = () => {
    const t = useGetFixedT('skills.otherSkills');
    const tResponseError = useGetFixedT('common.responseError');
    const { setValue, setError, getValues, control, trigger, formState: { errors }, } = useFormContext();
    const addToast = useToastWithTranslation();
    const values = useWatch({ name: FORM_SKILLS_OTHERS, control }).map((value) => ({
        ...value,
        id: value.id?.startsWith('custom_') ? undefined : value.id,
    }));
    const skillItemErrors = get(errors, FORM_SKILLS_OTHERS);
    const levelOptions = useGetOtherSkillLevelOptions();
    const getSkill = async (args) => {
        try {
            return await getAutocompleteSkills(args.inputValue, values);
        }
        catch (error) {
            setError(FORM_SKILLS_OTHERS, {
                type: 'custom',
                message: tResponseError('otherSkills'),
            });
            addToast({ message: 'common.responseError.otherSkills', variant: 'error' });
            return [];
        }
    };
    const optionsProps = useAsyncOptionsTagFieldAdapter(getSkill);
    return (_jsx(Box, { children: _jsx(ComboTagger, { allowEnter: true, requiredSkillLevel: true, onChange: (newValues) => {
                const currentValues = getValues(FORM_SKILLS_OTHERS);
                const newItemAdded = currentValues && currentValues.length < newValues.length;
                setValue(FORM_SKILLS_OTHERS, newValues);
                if (!newItemAdded && skillItemErrors) {
                    trigger(FORM_SKILLS_OTHERS);
                }
            }, values: values, allowFreeValue: true, label: t('label'), options: [], favouriteItems: [], levelOptions: levelOptions, optionsProps: optionsProps, errors: {
                inputError: skillItemErrors?.message,
                skillErrors: getSkillListErrors(skillItemErrors, values.length),
            }, skillErrorClassName: SCROLL_TO_ERROR_CLASS, displayUnwrapped: true, minimalInputLength: 1 }) }));
};
export default Skills;
