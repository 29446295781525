import {
    RECEIVE_CREATE_JOB_MODEL, REQUEST_CREATE_JOB_MODEL,
    ENABLE_STEP, ACTIVATE_STEP,
    ENABLE_QUESTIONNAIRE, DISABLE_QUESTIONNAIRE,
    ENABLE_AUTO_ANSWER, DISABLE_AUTO_ANSWER,
    UPDATE_JOB_DATA,
    CREATE_JOB, CREATE_JOB_FAILURE, CREATE_JOB_SUCCESS,
    UPDATE_POSITION_DATA, UPDATE_POSITION_SUCCESS, UPDATE_POSITION_FAILURE,
    CREATE_AUTO_ANSWER, CREATE_AUTO_ANSWER_SUCCESS, CREATE_AUTO_ANSWER_FAILURE,
    CREATE_QUESTIONNAIRE_FAILURE, CREATE_QUESTIONNAIRE_SUCCESS, CREATE_QUESTIONNAIRE,
    CREATE_PIPELINE_TEMPLATE, CREATE_PIPELINE_TEMPLATE_FAILURE, CREATE_PIPELINE_TEMPLATE_SUCCESS,
    REMOVE_CONCEPT, REMOVE_CONCEPT_FAILURE, REMOVE_CONCEPT_SUCCESS,
} from '../actions/CreateJobActions';
import {
    UPDATE_JOB, UPDATE_JOB_SUCCESS, UPDATE_JOB_FAILURE,
} from '../actions/JobDetailActions';

export const isFetchingCreateJobModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_CREATE_JOB_MODEL:
            return true;
        case RECEIVE_CREATE_JOB_MODEL:
            return false;
        default:
            return state;
    }
};

export const isSavingJob = (state = false, action) => {
    switch (action.type) {
        case CREATE_JOB:
        case UPDATE_JOB:
        case UPDATE_POSITION_DATA:
        case CREATE_QUESTIONNAIRE:
        case CREATE_AUTO_ANSWER:
        case CREATE_PIPELINE_TEMPLATE:
            return true;
        case CREATE_JOB_SUCCESS:
        case UPDATE_JOB_SUCCESS:
        case CREATE_JOB_FAILURE:
        case UPDATE_JOB_FAILURE:
        case UPDATE_POSITION_SUCCESS:
        case UPDATE_POSITION_FAILURE:
        case CREATE_QUESTIONNAIRE_SUCCESS:
        case CREATE_QUESTIONNAIRE_FAILURE:
        case CREATE_AUTO_ANSWER_SUCCESS:
        case CREATE_AUTO_ANSWER_FAILURE:
        case CREATE_PIPELINE_TEMPLATE_SUCCESS:
        case CREATE_PIPELINE_TEMPLATE_FAILURE:
            return false;
        default:
            return state;
    }
};

export const createJobModel = (state = {jobId: null, isFirstStepFilled: false}, action) => {
    switch (action.type) {
        case REQUEST_CREATE_JOB_MODEL:
            return Object.assign({}, state);
        case RECEIVE_CREATE_JOB_MODEL:
            return {
                ...state,
                ...action.payload,
            };
        case ACTIVATE_STEP:
            return Object.assign({}, state, {
                settings: Object.assign({}, state.settings, {
                    formSteps: state.settings.formSteps.map(step => Object.assign({}, step, {
                        active: action.payload.stepAction === step.action,
                    })),
                }),
            });
        case ENABLE_STEP:
            return Object.assign({}, state, {
                settings: Object.assign({}, state.settings, {
                    formSteps: state.settings.formSteps.map(step => Object.assign({}, step, {
                        enabled: action.payload.stepAction === step.action ? true : step.enabled,
                    })),
                }),
            });
        case ENABLE_QUESTIONNAIRE:
            return Object.assign({}, state, {
                settings: Object.assign({}, state.settings, {
                    isUsingQuestionnaire: true,
                }),
            });
        case DISABLE_QUESTIONNAIRE:
            return Object.assign({}, state, {
                settings: Object.assign({}, state.settings, {
                    isUsingQuestionnaire: false,
                }),
            });
        case ENABLE_AUTO_ANSWER:
            return Object.assign({}, state, {
                settings: Object.assign({}, state.settings, {
                    isUsingAutoAnswer: true,
                }),
            });
        case DISABLE_AUTO_ANSWER:
            return Object.assign({}, state, {
                settings: Object.assign({}, state.settings, {
                    isUsingAutoAnswer: false,
                }),
            });
        case UPDATE_JOB_DATA:
            return {
                ...state,
                ...action.payload,
                isFirstStepFilled: true,
            };

        case REMOVE_CONCEPT:
            return Object.assign({}, state, {
                settings: Object.assign({}, state.settings, {
                    removed: false,
                    removing: true,
                }),
            });
        case REMOVE_CONCEPT_SUCCESS:
            return Object.assign({}, state, {
                settings: Object.assign({}, state.settings, {
                    removed: true,
                    removing: false,
                }),
            });
        case REMOVE_CONCEPT_FAILURE:
            return Object.assign({}, state, {
                settings: Object.assign({}, state.settings, {
                    removed: false,
                    removing: false,
                }),
            });
        case CREATE_AUTO_ANSWER_SUCCESS:
        case CREATE_QUESTIONNAIRE_SUCCESS:
        case CREATE_PIPELINE_TEMPLATE_SUCCESS:
            return Object.assign({}, state, {
                isSuccess: true,
                resultId: action.payload.id,
            });

        case CREATE_JOB_SUCCESS:
            return {
                ...state,
                jobId: action.payload,
            };
        default:
            return state;
    }
};
