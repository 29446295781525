export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MODAL_SECOND_OPEN = 'MODAL_SECOND_OPEN';
export const MODAL_SECOND_CLOSE = 'MODAL_SECOND_CLOSE';
export const MODAL_THIRD_OPEN = 'MODAL_THIRD_OPEN';
export const MODAL_THIRD_CLOSE = 'MODAL_THIRD_CLOSE';
export const NO_MODAL_OPEN = 'NO_MODAL_OPEN';
export const NO_MODAL_CLOSE = 'NO_MODAL_CLOSE';
export const NO_MODAL_SECOND_OPEN = 'NO_MODAL_SECOND_OPEN';
export const NO_MODAL_SECOND_CLOSE = 'NO_MODAL_SECOND_CLOSE';
export const NO_MODAL_DASHBOARD_OPEN = 'NO_MODAL_DASHBOARD_OPEN';
export const NO_MODAL_DASHBOARD_CLOSE = 'NO_MODAL_DASHBOARD_CLOSE';

export const openModal = (modalType, modalProps) => ({
    type: MODAL_OPEN,
    payload: {
        modalType,
        modalProps,
    },
});

export const openModalSecond = (modalType, modalProps) => ({
    type: MODAL_SECOND_OPEN,
    payload: {
        modalType,
        modalProps,
    },
});

export const openModalThird = (modalType, modalProps) => ({
    type: MODAL_THIRD_OPEN,
    payload: {
        modalType,
        modalProps,
    },
});

export const openNoModal = (modalType, modalProps) => ({
    type: NO_MODAL_OPEN,
    payload: {
        modalType,
        modalProps,
    },
});

export const openNoModalSecond = (modalType, modalProps) => ({
    type: NO_MODAL_SECOND_OPEN,
    payload: {
        modalType,
        modalProps,
    },
});

export const openNoModalDashboard = (modalType, modalProps) => ({
    type: NO_MODAL_DASHBOARD_OPEN,
    payload: {
        modalType,
        modalProps,
    },
});

export const closeModal = (onClose = undefined) => ({
    type: MODAL_CLOSE,
    payload: {
        onClose,
    },
});

export const closeModalSecond = () => ({
    type: MODAL_SECOND_CLOSE,
});

export const closeModalThird = () => ({
    type: MODAL_THIRD_CLOSE,
});

export const closeNoModal = () => ({
    type: NO_MODAL_CLOSE,
});

export const closeNoModalSecond = () => ({
    type: NO_MODAL_SECOND_CLOSE,
});

export const closeNoModalDashboard = () => ({
    type: NO_MODAL_DASHBOARD_CLOSE,
});
