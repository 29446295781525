import Translations from '../Translations';
import Constants from '../Constants';
import UserRights from '../UserRights';

export default class CandidatesListColumnsOrderHelper {
    static getDefaultColumnsOrder(
        listType = Constants.CANDIDATES_LIST_TYPE_JOB_DETAIL,
        loginManagerModel = null
    ) {
        const hasActiveFreeSubscription =
            loginManagerModel && UserRights.hasActiveFreeSubscription(loginManagerModel) === true;

        let columns = [
            'name',
            'position',
            'stage',
            'hasDuplicities',
            'addDate',
            'source',
            'lastEmail',
            'score',
            'tags',
            'note',
            'colleaguesNotes',
            'gdprAgreement',
            'attachments',
            'expectedSalary',
            'disposableDate',
            'refusalReason',
        ];

        if (hasActiveFreeSubscription) {
            columns = columns.filter(column => !['score', 'expectedSalary', 'disposableDate'].includes(column));
        }

        return listType === Constants.CANDIDATES_LIST_TYPE_GLOBAL_SEARCH
            ? columns
            : columns.filter(columnName => columnName !== 'position');
    }

    static getExternalVisitorColumns() {
        return ['name', 'source', 'addDate', 'stage', 'score', 'externalUsersNotes'];
    }

    static getColumns(
        userColumnsOrder,
        defaultColumnsOrder,
        isExternalVisitor = false,
        isEditMode = false,
        loginManagerModel = null
    ) {
        if (isExternalVisitor === true) {
            return this.getExternalVisitorColumns();
        }

        const hasActiveFreeSubscription = loginManagerModel !== null && UserRights.hasActiveFreeSubscription(loginManagerModel) === true;

        let columns = isExternalVisitor === true || isEditMode ? [] : ['checkbox'];

        userColumnsOrder.forEach((userColumn) => {
            const index = defaultColumnsOrder.indexOf(userColumn);

            if (index > -1) {
                columns.push(userColumn);
                defaultColumnsOrder.splice(index, 1);
            }
        });

        defaultColumnsOrder.forEach((defaultColumn) => {
            columns.push(defaultColumn);
        });

        if (hasActiveFreeSubscription) {
            columns = columns.filter(
                (column) => !['score', 'expectedSalary', 'disposableDate'].includes(column)
            );
        }

        return columns;
    }

    static getColumnName(key) {
        const names = {
            name: Translations.getStatic('nameAndSurname'),
            position: Translations.getStatic('positionName'),
            stage: Translations.getStatic('selectionProcessStep'),
            hasDuplicities: Translations.getStatic('duplicity'),
            addDate: Translations.getStatic('dateOfReaction'),
            source: Translations.getStatic('source'),
            lastEmail: Translations.getStatic('candidateCommunication'),
            score: Translations.getStatic('questionnaire'),
            tags: Translations.getStatic('tags'),
            note: Translations.getStatic('notes'),
            colleaguesNotes: Translations.getStatic('colleaguesNotes'),
            attachments: Translations.getStatic('attachmentsCount'),
            gdprAgreement: Translations.getStatic('gdprAgreement'),
            expectedSalary: Translations.getStatic('expectedSalary'),
            disposableDate: Translations.getStatic('disposableDate'),
            refusalReason: Translations.getStatic('refusalReason'),
        };

        return names.hasOwnProperty(key) === true ? names[key] : key;
    }
}
