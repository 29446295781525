import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { SimpleLink } from '@profesia/adamui/components/common/Link';
import { SectionWrapperColorVariant } from '@profesia/adamui/components/common/types';
import { useMobile } from '@profesia/adamui/utils/responsive';
import BoxedSectionWrapper from '@profesia/mark-offer-form/components/content/form/components/BoxedSectionWrapper';
import { useGetFixedT, useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { getConfiguration } from '@profesia/mark-offer-form/configuration';
import CompanyModal from './Modal';
import CompanyDetailsContent from './Content';
const CompanyDetails = () => {
    const t = useGetFixedT('companyDetails');
    const pageT = useTranslationInPageLanguage('companyDetails');
    const { form } = getConfiguration();
    const isMobile = useMobile('sm');
    const editLink = pageT(isMobile ? 'links.editLinkShort' : 'links.editLinkFull');
    const { isEditMode } = useOptionsContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
        if (isEditMode) {
            form.openModalSecond();
        }
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
        if (isEditMode) {
            form.closeModalSecond();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(BoxedSectionWrapper, { title: t('title'), endComponent: _jsx(SimpleLink, { variantType: "primary", variant: "body2", onClick: handleOpenModal, children: editLink }), variant: SectionWrapperColorVariant.Dark, children: _jsx(CompanyDetailsContent, {}) }), _jsx(CompanyModal, { onSubmit: handleCloseModal, onClose: handleCloseModal, isOpen: isModalOpen })] }));
};
export default CompanyDetails;
