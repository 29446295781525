import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
const useHandleCloseModal = (onClose) => {
    const t = useTranslationInPageLanguage('common.dialog');
    return (isDirty) => {
        if (!isDirty || confirm(t('unsavedChanges'))) {
            onClose();
        }
    };
};
export default useHandleCloseModal;
