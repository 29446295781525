export const GET_JOB_TITLE_AI_SUGGESTIONS = 'GET_JOB_TITLE_AI_SUGGESTIONS';
export const GENERATE_TEXT_WITH_AI = 'GENERATE_TEXT_WITH_AI';
export const GET_CODEBOOKS = 'GET_CODEBOOKS';
export const GET_BUSINESS_AREAS = 'GET_BUSINESS_AREAS';
export const GET_SALARY_FROM_PLATY = 'GET_SALARY_FROM_PLATY';
export const POST_FORM = 'POST_FORM';
export const UPDATE_FORM = 'UPDATE_FORM';
export const GET_CONTACT_PERSONS = 'GET_CONTACT_PERSONS';
export const UPDATE_CONTACT_PERSON = 'UPDATE_CONTACT_PERSON';
export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';
