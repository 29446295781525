import React from 'react';
import JobStatisticsSkeletonHeader from './Header';
import JobStatisticsSkeletonContent from './Content';

const JobStatisticsSkeleton = () => (
    <>
        <JobStatisticsSkeletonHeader/>
        <JobStatisticsSkeletonContent/>
    </>
);

export default JobStatisticsSkeleton;
