import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { WysiwygEditor } from '@profesia/adamui/components/common/input';
import { useGetFixedT, useGetLocale, useTranslationInPageLanguage, } from '@profesia/mark-offer-form/i18n';
import { FORM_BENEFITS, FORM_JOB_DESCRIPTION, FORM_JOB_TITLE, FORM_SKILLS_PERSONAL, } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { isAiActiveForLanguage, useGenerateTextWithAi, } from '@profesia/mark-offer-form/services/ai';
import useToastWithTranslation from '@profesia/mark-offer-form/services/useToastWithTranslation';
const valuesByField = {
    benefits: [FORM_BENEFITS, 'positionDescription.benefits'],
    jobTasks: [FORM_JOB_DESCRIPTION, 'positionDescription.jobDescription'],
    prerequisites: [FORM_SKILLS_PERSONAL, 'skills.personalSkills'],
};
const WysiwygWithAi = ({ field, errorMessage, onAiOptionClick, onAiDropdownButtonClick, onToolbarButtonClick, onTagClick, tags, required, }) => {
    const [valuePath, keyPrefix] = valuesByField[field];
    const t = useGetFixedT(keyPrefix);
    const currentLanguage = useGetLocale();
    const { setValue, control } = useFormContext();
    const addToast = useToastWithTranslation();
    const value = useWatch({ name: valuePath, control });
    const jobTitle = useWatch({ name: FORM_JOB_TITLE, control });
    const onSuccess = (value) => {
        setValue(valuePath, value);
    };
    const { mutate, isPending, isError } = useGenerateTextWithAi(field, onSuccess);
    const [firstUse, setFirstUse] = useState(true);
    const [currentTags, setCurrentTags] = useState(tags);
    useEffect(() => {
        if (isError) {
            addToast({ message: 'common.responseError.generateTextWithAi', variant: 'error' });
        }
    }, [isError]);
    useEffect(() => {
        if (tags !== currentTags) {
            setCurrentTags(tags);
        }
    }, [tags]);
    const onOptionClick = (tone) => {
        mutate({ jobTitle, userInput: value, tone: tone, language: currentLanguage });
        onAiOptionClick?.(tone, firstUse);
        setFirstUse(false);
    };
    return (_jsx(WysiwygEditor, { label: t('label'), value: value || undefined, onChange: (value) => {
            setValue(valuePath, value);
        }, placeholder: t('placeholder'), aiDropdownProps: {
            showAiDropdown: isAiActiveForLanguage(currentLanguage),
            onOptionClick,
            disabledAiDropdown: Boolean(!jobTitle),
            onAiDropdownButtonClick,
        }, errorMessage: errorMessage, isLoading: isPending, onToolbarButtonClick: onToolbarButtonClick, onTagClick: onTagClick, tags: currentTags, translateFunction: useTranslationInPageLanguage, required: required }));
};
export default WysiwygWithAi;
