// Job Ad Form 1st page:
export const JOB_TITLE_SECTION = 'job-title';
export const POSITION_DESCRIPTION_SECTION = 'position-description';
export const JOB_DESCRIPTION_SECTION = 'job-description';
export const POSITION_LOCATION_SECTION = 'position-location';
export const SALARY_SECTION = 'salary';
export const SALARY_OTHER_INFORMATION_SECTION = 'other-information-salary';
export const EDUCATION_SECTION = 'education';
export const LANGUAGES_SECTION = 'languages';
export const OTHER_SKILLS_SECTION = 'other-skills';
export const EXPERIENCE_SECTION = 'experience';
export const PERSONAL_SKILLS_SECTION = 'personal-skills';
export const SELECTION_PROCESS_INFO_SECTION = 'selection-process-info';
export const JOB_AD_SETTINGS_SECTION = 'job-ad-settings';
export const CONTACT_DETAILS_SECTION = 'contact-details';
export const JOB_TITLE = {
    //SECTION: 'job-title',
    JOB: '[data-testid^="job-title"] input[type="text"]', //'[data-testid="job-title-input"]',
    LANGUAGE: '[data-testid^="job-title"] div[role="button"]', //'[data-testid="select-language-job"]',
};
export const POSITION_DESCRIPTION = {
    //SECTION: 'position-description',
    DESC_INPUT: '[data-testid^="position-description"] input[type="text"]', //'[data-testid="selected-positions-input"]',
};
export const CONTRACT_TYPE = {
    FULL_TIME: 'input[value="full_time"]', //'[data-testid="full-time"]',
    CONTRACT: 'input[value="contract"]', //'[data-testid="contract"]',
};
export const STUDENTS = 'input[value="universityStudents"]'; //'[data-testid="valid-for-graduate"]',
export const JOB_DESCRIPTION = '[data-testid^="job-description"] .tiptap'; //'[data-testid="job-description"]',
export const POSITION_LOCATION = {
    //SECTION: 'position-location',
    DEFAULT: '[data-testid^="position-location"] input[type="radio"]', //:eq(2)' nahradit za '[data-testid="place-remote"]',
    UPDATE: '[data-testid^="position-location"] input[type="radio"]', //:eq(0)' nahradit za '[data-testid="place-onsite"]',
    INPUT: '[data-testid^="position-location"] input[type="text"]', //'[data-testid="place-onsite-input"]',
    BTN: '[data-testid^="position-location"] button', //'[data-testid="start-date-btn"]',
    START_DATE: '[data-testid^="position-location"] input[type="text"]', //'[data-testid="start-date"]',
};
export const SALARY = {
    //SECTION: 'salary',
    DEFAULT: '[data-testid^="salary"] input[type="radio"]', //:eq(0)' nahradit za '[data-testid="salary-min-value"]',
    UPDATE: '[data-testid^="salary"] input[type="radio"]', //:eq(1)' nahradit za '[data-testid="salary-min-max-values"]',
    PUBLISH_MAX: '[data-testid^="salary"] input[type="checkbox"]', // '[data-testid="salary-publish-max-value"]',
};
export const SALARY_OTHER_INFORMATION = {
    //SECTION: 'other-information-salary',
    TEXTAREA: '[data-testid^="other-information-salary"] textarea', // '[data-testid="salary-other-information"]',
};
export const EDUCATION = {
    //SECTION: 'education',
    EDUCATION: `[data-testid^="education"] div[role="button"]`, //po implementovani data-testid, sa toto vymaze a v teste sa musi naparovat spravny variant
    //SECONDARY_WITHOUT_EXAMINATION: '[data-testid^="education"] div[role="button"]', //:eq(2)' nahradit za '[data-testid="education-secondary-without-examination"]',
    //SECONDARY_WITH_EXAMINATION: '[data-testid^="education"] div[role="button"]', //:eq(3)' nahradit za '[data-testid="education-secondary-with-examination"]',
    BTN: '[data-testid^="education"] button[type="button"]', //'[data-testid="education-btn"]',
    INPUT: '[data-testid^="education"] input[type="text"]', //'[data-testid="education-btn-input"]',
};
export const LANGUAGES = {
    //SECTION: 'languages',
    INPUT: `[data-testid^="languages"] input[type="text"]`, //:eq(0) nahradit za '[data-testid="languages-input"]',
    BTN: '[data-testid^="languages"] div[role="button"]', //'[data-testid="languages-btn"]',
};
export const OTHER_SKILLS = {
    //SECTION: 'other-skills',
    INPUT: `[data-testid^="other-skills"] input[type="text"]`, //'[data-testid="other-skills-input"]',
    BTN: '[data-testid^="other-skills"] div[role="button"]', //'[data-testid="other-skills-btn"]',
    ADD_DRIVER_LICENSE: '[data-testid^="other-skills"] button[type="button"]', //'[data-testid="other-skills-btn-driving-licence"]',
    CHECKBOX_OPTION: 'input[value="B"]', // '[data-testid="other-skills-btn-driving-licence-b"]',
};
export const EXPERIENCE = {
    //SECTION: 'experience',
    PRACTICE_YEARS_BTN: `[data-testid^="experience"] button`, //'[data-testid="practice-years-btn"]'
    PRACTICE_YEARS_INPUT: '[data-testid^="experience"] input[type="text"]', //:eq(0) nahradit za '[data-testid="practice-years-input"]'
    PRACTICE_BTN: '[data-testid^="experience"] button', // '[data-testid="practice-btn"]'
    PRACTICE_INPUT: '[data-testid^="experience"] input[type="text"]', //:eq(1) nahradit za '[data-testid="practice-input"]'
};
export const PERSONAL_SKILLS = {
    //SECTION: 'personal-skills',
    INPUT: `[data-testid^="personal-skills"] .tiptap`, //'[data-testid="other-personal-requirements"]'
};
export const SELECTION_PROCESS_INFO = {
    //SECTION: 'selection-process-info',
    TEXTAREA: `[data-testid^="selection-process-info"] textarea`, //'[data-testid="recruitment-note"]'
    NUM_OF_OPENINGS_BTN: '[data-testid^="number-of-openings"] button', //'[data-testid="recruitment-number-of-opening-btn"]'
    NUM_OF_OPENINGS_INPUT: '[data-testid^="number-of-openings"] input[type="text"]', //'[data-testid="recruitment-number-of-opening-input"]'
    APPLICATE_TILL_BTN: '[data-testid^="applicable-till"] button', //'[data-testid="recruitment-applicate-till-btn"]'
    APPLICATE_TILL_INPUT: '[data-testid^="applicable-till"] input[type="text"]' //'[data-testid="recruitment-applicate-till-input"]'
};
export const JOB_AD_SETTINGS = {
    //SECTION: 'job-ad-settings',
    JOB_AD_WITHOUT_CV: '[data-testid="application-without-cv-stack"] input[type="checkbox"]', //'[data-testid="job-ad-without-cv"]'
    CVDB_OPTION: '[data-testid^="job-ad-settings"] input[value="0"]', //'[data-testid="job-ad-cvdb-agree"]'
    REF_NUM_BTN: '[data-testid^="job-ad-settings"] button[type="button"]', //'[data-testid="job-ad-ref-numb-btn"]'
    REF_NUM_INPUT: '[data-testid^="job-ad-settings"] input[type="text"]', //'[data-testid="job-ad-ref-numb-input"]'
};
export const SUBMIT_BTN = 'button[type="submit"]'; //'[data-testid="job-ad-submit"]'
