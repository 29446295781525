import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MarkModal from '../Modal/MarkModal';
import { closeModal } from '../../actions/ModalActions';
import Translations from '../../helpers/Translations';
import ColumnsOrderEditor from '../ColumnsOrder/ColumnsOrderEditor';
import CandidatesListColumnsOrderHelper from '../../helpers/components/CandidatesListColumnsOrderHelper';
import { saveCandidatesListColumnsOrder } from '../../actions/CandidatesListColumnsOrderActions';
import SuccessMessage from '../../helpers/SuccessMessage';

class CandidatesListColumnsOrderEditorModal extends Component {
    state = {
        columns: [],
    };

    componentDidMount() {
        this.setState({
            columns: CandidatesListColumnsOrderHelper.getColumns(
                this.props.userColumnsOrder,
                this.props.defaultColumnsOrder,
                false,
                true,
                this.props.loginManagerModel
            ),
        });
    }

    componentDidUpdate(prevProps) {
        if (
            SuccessMessage.canShowSuccessMessage(
                prevProps,
                this.props,
                'isSavingCandidatesListColumnsOrder'
            ) &&
            this.props.candidatesListColumnsOrderModel.hasOwnProperty('isSuccess') === true &&
            this.props.candidatesListColumnsOrderModel.isSuccess === true
        ) {
            this.props.closeModal();
        }
    }

    closeModal = () => {
        this.props.closeModal();
    };

    setColumnsOrder = (columns) => {
        this.setState({
            columns,
        });
    };

    saveCandidatesListColumnsOrder = () => {
        const formData = new FormData();
        formData.append('columns', JSON.stringify(this.state.columns));
        formData.append('listType', this.props.listType);

        this.props.saveCandidatesListColumnsOrder(
            formData,
            this.state.columns,
            this.props.listType
        );
    };

    render() {
        const { isOpen } = this.props;
        const { columns } = this.state;

        return (
            <MarkModal
                isOpen={isOpen}
                closeModal={this.closeModal}
                actionButton={{
                    text: Translations.getStatic('save'),
                    disabled: this.props.isSavingCandidatesListColumnsOrder,
                    loading: this.props.isSavingCandidatesListColumnsOrder,
                    onClick: () => this.saveCandidatesListColumnsOrder(),
                }}
                title={Translations.getStatic('columnsOrderSettings')}
                content={
                    <ColumnsOrderEditor columns={columns} setColumnsOrder={this.setColumnsOrder} />
                }
                isLoading={false}
            />
        );
    }
}

CandidatesListColumnsOrderEditorModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    userColumnsOrder: PropTypes.array.isRequired,
    defaultColumnsOrder: PropTypes.array.isRequired,
    listType: PropTypes.number.isRequired,
    loginManagerModel: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    isOpen: state.modalLogic.isOpen,
    isSavingCandidatesListColumnsOrder: state.isSavingCandidatesListColumnsOrder,
    candidatesListColumnsOrderModel: state.candidatesListColumnsOrderModel,
    loginManagerModel: state.loginManagerModel,
});

const mapDispatchToProps = {
    closeModal,
    saveCandidatesListColumnsOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesListColumnsOrderEditorModal);
