import { FORM_POSITION_PLACE_REGIONS } from '@profesia/mark-offer-form/components/content/form/valuePaths';
const updateRegionsValues = (setValue, regions, regionValues) => {
    if (regionValues.length === 0) {
        return;
    }
    const regionsMap = new Map(regions.map((pos) => [pos.id, pos]));
    const updatedValues = regionValues
        .map((value) => regionsMap.get(value?.id))
        .filter((value) => value !== undefined);
    setValue(FORM_POSITION_PLACE_REGIONS, updatedValues, { shouldDirty: false });
};
export default updateRegionsValues;
