import {
    RECEIVE_JOB_STATISTICS_MODEL,
    REQUEST_JOB_STATISTICS_MODEL,
} from '../actions/JobStatisticsActions';

export const isFetchingJobStatisticsModel = (state = false, action) => {
    switch (action.type) {
        case REQUEST_JOB_STATISTICS_MODEL:
            return true;
        case RECEIVE_JOB_STATISTICS_MODEL:
            return false;
        default:
            return state;
    }
};

export const jobStatisticsModel = (state = {}, action) => {
    switch (action.type) {
        case REQUEST_JOB_STATISTICS_MODEL:
            return {};
        case RECEIVE_JOB_STATISTICS_MODEL:
            return {
                ...state,
                jobStatistics: action.payload.jobStatistics,
            };
        default:
            return state;
    }
};
