import { useEffect } from 'react';
import { connect } from 'react-redux';
import { requestLoginManagerModel } from '../../actions/LoginActions';
import Url from '../../helpers/Url';
import DataLayer from '../../helpers/DataLayer';
import { useLocation } from 'react-router-dom';
import type { Location } from '@remix-run/router';
import { LoginManagerModelType } from '../../../interfaces/LoginManagerModelType';
import UserRights from '../../helpers/UserRights';
import User from '../../helpers/User';
import Skeletons from '../../helpers/Skeletons';
import loginManagerModelSkeleton from '../../skeletons/models/loginManagerModelSkeleton';

const LoginManager = ({ loginManagerModel, isFetchingLoginManagerModel, requestLoginManagerModel }: LoginManagerProps): null => {
    const location: Location = useLocation();

    const paidMark: boolean = UserRights.hasAllowedFeatureFromBasicSubscription(loginManagerModel?.activePackage ?? null);

    useEffect(() => {
        DataLayer.setDataFromProfesiaCookie();
        //TODO find solution on this problem
        // runGtm();
    }, []);

    useEffect(() => {
        const action: string = Url.getUrlParameter('action');

        if (action !== 'login' && !isFetchingLoginManagerModel && Skeletons.isSkeleton(loginManagerModel)) {
            requestLoginManagerModel();
        }
    }, [location.pathname, isFetchingLoginManagerModel, loginManagerModel]);

    useEffect(() => {
        DataLayer.setParameters({
            paid_mark: paidMark,
            has_ever_refused_candidates_mark: loginManagerModel.user?.hasUserEverRefusedCandidate ?? false,
            has_ever_added_candidate_note_mark: loginManagerModel.user?.hasUserEverAddedCandidateNote ?? false,
            has_ever_sent_email_to_candidate_mark: loginManagerModel.user?.hasUserEverSentEmailToCandidate ?? false,
        });
    }, [paidMark]);

    useEffect(() => {
        if (!isFetchingLoginManagerModel
            && Url.getUrlParameter('action') === 'create-job-ad'
            && !User.isLoggedIn(loginManagerModel)
            && !Skeletons.isSkeleton(loginManagerModel)
        ) {
            Url.redirectTo(Url.buildLoginLink());
        }
    }, [isFetchingLoginManagerModel]);

    return null;
};

interface DispatchProps {
    requestLoginManagerModel: VoidFunction;
}

interface StateProps {
    isFetchingLoginManagerModel: boolean;
    loginManagerModel: LoginManagerModelType;
}

interface LoginManagerProps {
    isFetchingLoginManagerModel: boolean;
    loginManagerModel: LoginManagerModelType;
    requestLoginManagerModel: VoidFunction;
}

const mapStateToProps = (state: StateProps): StateProps => ({
    isFetchingLoginManagerModel: state.isFetchingLoginManagerModel,
    loginManagerModel: Skeletons.getData(state.loginManagerModel, loginManagerModelSkeleton) as LoginManagerModelType,
});

const mapDispatchToProps: DispatchProps = {
    requestLoginManagerModel,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoginManager);
