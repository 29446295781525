import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import Checkbox from '@profesia/adamui/components/common/input/checkbox/Checkbox';
import Item from '../../Item';
import Divider from '../../Divider';
import Icon from '../../Icon';
import { candidateSetNote, requestCandidateHistory } from '../../../actions/CandidateActions';
import { closeSidebarEditPanel } from '../../../actions/SidebarActions';
import Translations from '../../../helpers/Translations';
import Storages from '../../../helpers/Storages';
import { AnyParamsFunctionType } from '../../../../interfaces/CommonTypes';

const CandidateSidebarNoteEdit = (props: {
    note: string;
    isPublic: boolean;
    candidateId: number;
    candidateSetNote: AnyParamsFunctionType;
    closeSidebarEditPanel: AnyParamsFunctionType;
}) => {
    const { candidateId, candidateSetNote, closeSidebarEditPanel } = props;

    const [note, setNote] = useState<string>(props.note);
    const [isPublic, setIsPublic] = useState<boolean>(props.isPublic);

    useEffect(() => {
        setNote(props.note);
    }, [props.note]);

    useEffect(() => {
        setIsPublic(props.isPublic);
    }, [props.isPublic]);

    const handleChangeNote = (note: string) => {
        setNote(note);

        Storages.setLocal(`candidateNoteId_${candidateId}`, { note });
    };

    const handleChangeNoteVisibility = () => setIsPublic(!isPublic);

    const onCandidateSetNote = () => {
        const formData = new FormData();

        formData.append('candidateId', candidateId.toString());
        formData.append('note', note);
        formData.append('isPublic', isPublic ? '1' : '0');

        candidateSetNote(formData, candidateId);
        closeSidebarEditPanel('CandidateSidebarNoteEdit', { candidateId });
    };

    const handleClickSave = () => {
        onCandidateSetNote();
        Storages.removeLocal(`candidateNoteId_${candidateId}`);
    };

    return (
        <>
            <div className="ui items height unstackable">
                <Item>
                    <Icon className="icon-notes_small" size="mini"/>
                    <Item.Content>
                        <Item.Header as="h4">{Translations.getStatic('myNotes')}</Item.Header>
                        <Item.Description>
                            <TextareaAutosize
                                className="short"
                                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChangeNote(e.target.value)}
                                value={note}
                                onPointerEnterCapture={undefined}
                                onPointerLeaveCapture={undefined}
                                style={{ resize: 'none' }}
                            />

                            <Divider hidden/>

                            <Checkbox
                                name="isPublic"
                                label={Translations.getStatic('shareNotesWithColleagues')}
                                checked={isPublic}
                                onChange={handleChangeNoteVisibility}
                            />
                        </Item.Description>
                    </Item.Content>
                </Item>
            </div>

            <button className="ui button float-right secondary-button" onClick={() => closeSidebarEditPanel('CandidateSidebarNoteEdit', { candidateId })}>{Translations.getStatic('cancel')}</button>
            <button className="ui button float-right primary-button" onClick={handleClickSave}>{Translations.getStatic('save')}</button>
        </>
    );
};

const mapStateToProps = (state: { isFetchingCandidateNote: boolean; }) => ({
    isFetchingCandidateNote: state.isFetchingCandidateNote,
});

const mapDispatchToProps = {
    candidateSetNote,
    closeSidebarEditPanel,
    requestCandidateHistory,
};

CandidateSidebarNoteEdit.propTypes = {
    candidateId: PropTypes.number.isRequired,
    note: PropTypes.string.isRequired,
    isPublic: PropTypes.bool.isRequired,
    candidateSetNote: PropTypes.func.isRequired,
    closeSidebarEditPanel: PropTypes.func.isRequired,
    requestCandidateHistory: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSidebarNoteEdit);
