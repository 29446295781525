import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import HiddenFields from '@profesia/adamui/components/common/HiddenFields';
import { InputField } from '@profesia/adamui/components/common/input';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { trackEvent } from '@profesia/mark-offer-form/utils/track/track';
import { LOCATION_JOB_AD, NAME_APPLICABLE_TILL, } from '@profesia/mark-offer-form/utils/track/constants';
import { FORM_APPLICABLE_TILL } from '@profesia/mark-offer-form/components/content/form/valuePaths';
import { APPLICABLE_TILL_MAX_LENGTH, NARROW_INPUT_WIDTH } from '@profesia/mark-offer-form/components/content/form/constants';
const ApplicableTill = () => {
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_APPLICABLE_TILL, control });
    const t = useGetFixedT('selectionProcessInfo.applicableTill');
    return (_jsx(HiddenFields, { buttonLabel: t('label'), shouldBeHidden: !value, children: _jsx(InputField, { label: t('label'), value: value, inputProps: { maxLength: APPLICABLE_TILL_MAX_LENGTH }, inputWidth: NARROW_INPUT_WIDTH, onChange: ({ target }) => setValue(FORM_APPLICABLE_TILL, target.value) }), onClick: () => trackEvent(LOCATION_JOB_AD, NAME_APPLICABLE_TILL, null) }));
};
export default ApplicableTill;
