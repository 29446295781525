import React, { FC } from 'react';
import { Stack } from '@mui/material';
import SkeletonRectangular from '@profesia/adamui/components/common/skeleton/SkeletonRectangular';
import SkeletonCircular from '@profesia/adamui/components/common/skeleton/SkeletonCircular';

interface SkeletonRowsProps {
    rows?: number;
    borderRadius?: number;
    px?: number;
}

const SkeletonRows: FC<SkeletonRowsProps> = ({ rows = 5, borderRadius = 26, px = 0 }) => (
    <Stack flex={1} spacing={3} px={px} data-testid="skeleton-rows-stack">
        {[...Array(rows)].map((_, i) => (
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                key={i}
                data-testid="skeleton-row"
            >
                <SkeletonCircular size={18} />
                <Stack flex={1}>
                    <SkeletonRectangular width="100%" borderRadius={borderRadius} />
                </Stack>
            </Stack>
        ))}
    </Stack>
);

export default SkeletonRows;
