import moment from 'moment';

export const REQUEST_JOB_STATISTICS_MODEL = 'REQUEST_JOB_STATISTICS_MODEL';
export const RECEIVE_JOB_STATISTICS_MODEL = 'RECEIVE_JOB_STATISTICS_MODEL';

export const requestJobStatisticsModel = (jobId, from, to) => ({
    type: REQUEST_JOB_STATISTICS_MODEL,
    meta: {
        isDefaultApiPrefixDisabled: true,
        requestRoute: `/api/v1/statistics/job/${jobId}`,
        requestData: {
            from: from ?? '1970-01-01',
            to: to ?? moment().format('YYYY-MM-DD'),
        },
        onRequestSuccess: RECEIVE_JOB_STATISTICS_MODEL,
    },
});
