import createRequiredError from '@profesia/mark-offer-form/components/content/form/resolver/addError/createRequiredError';
const addError = (field, condition, message) => {
    if (condition) {
        const keys = field.split('.');
        let errors = {};
        let current = errors;
        keys.forEach((key, index) => {
            if (index === keys.length - 1) {
                current[key] = createRequiredError(message);
            }
            else {
                current[key] = current[key] || {};
                current = current[key];
            }
        });
        return errors;
    }
};
export default addError;
