import { jsx as _jsx } from "react/jsx-runtime";
import { Option, AsyncPaper as PaperComponent } from '@profesia/mark-offer-form/components/content/common/tagFieldAdapter';
const useAsyncOptionsTagFieldAdapter = (getOptions) => {
    return {
        renderOption: (props, option) => _jsx(Option, { option: option, liProps: props }, option.id),
        filterOptions: (option) => option,
        getOptions: (inputValue) => {
            return getOptions({ inputValue });
        },
        getOptionLabel: (option) => option.value,
        PaperComponent,
    };
};
export default useAsyncOptionsTagFieldAdapter;
