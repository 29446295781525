import Sort from './../Sort';
import Strings from './../Strings';
import Constants from '../Constants';
import Arrays from '../Arrays';
import Translations from '../Translations';
import DateFormatted from '../DateFormatted';
import moment from 'moment';
import UserRights from '../UserRights';

export default class CandidatesHelper {
    static filterAndSortCandidates(
        candidates,
        filter,
        sort,
        enabledFilters,
        notAnonymizedCandidates = false
    ) {
        if (notAnonymizedCandidates === true) {
            candidates = this.filterNotAnonymizedCandidates(candidates);
        }

        candidates = this.filterCandidates(candidates, filter, enabledFilters);
        candidates = this.sortCandidates(candidates, sort);

        return candidates;
    }

    static filterCandidates(candidates, filter, enabledFilters) {
        if (enabledFilters.includes(Constants.FILTER_BY_CANDIDATE_IDS) === true) {
            const candidateIds =
                filter.hasOwnProperty('candidateIds') && Array.isArray(filter.candidateIds)
                    ? filter.candidateIds
                    : [];

            if (candidateIds.length > 0) {
                candidates = this.filterCandidatesByCandidateIds(candidates, candidateIds);
            }
        }

        if (enabledFilters.includes(Constants.FILTER_BY_NAME) === true) {
            candidates = this.filterCandidatesByName(candidates, filter);
        }

        if (enabledFilters.includes(Constants.FILTER_BY_PHASE) === true) {
            candidates = this.filterCandidatesByPhaseId(candidates, filter);
        }

        if (enabledFilters.includes(Constants.FILTER_BY_STATE) === true) {
            candidates = this.filterCandidatesByState(candidates, filter);
        }

        if (enabledFilters.includes(Constants.FILTER_BY_STATE_WITH_STAGE) === true) {
            candidates = this.filterCandidatesByPropertyCollection(candidates, filter);
        }

        if (
            enabledFilters.includes(Constants.FILTER_BY_ADD_DATE) === true &&
            filter.hasOwnProperty('addDate')
        ) {
            candidates = this.filterCandidatesByAddDate(candidates, filter.addDate);
        }

        if (enabledFilters.includes(Constants.FILTER_BY_TAG) === true) {
            candidates = this.filterCandidatesByTagList(candidates, filter);
        }

        if (enabledFilters.includes(Constants.FILTER_BY_SCORE) === true) {
            candidates = this.filterCandidatesByScoreRange(candidates, filter);
        }

        if (enabledFilters.includes(Constants.FILTER_BY_SOURCE) === true) {
            candidates = this.filterCandidatesBySources(candidates, filter);
        }

        if (enabledFilters.includes(Constants.FILTER_BY_REFUSED_AND_NOT_CONTACTED) === true) {
            candidates = this.filterCandidatesByRefusedAndNotContacted(candidates, filter);
        }

        if (enabledFilters.includes(Constants.FILTER_BY_NOT_CONTACTED) === true) {
            candidates = this.filterCandidatesByNotContacted(candidates, filter);
        }

        if (enabledFilters.includes(Constants.FILTER_BY_NO_GDPR_AGREEMENT) === true) {
            candidates = this.filterCandidatesByNoGdprAgreement(candidates, filter);
        }

        if (
            enabledFilters.includes(Constants.FILTER_BY_NOTE) === true &&
            filter.hasOwnProperty('note')
        ) {
            candidates = this.filterCandidatesByNote(candidates, filter.note);
        }

        return candidates;
    }

    static sortCandidates(candidates, sort) {
        if (sort === null || candidates.length === 0 || Object.keys(sort).length === 0) {
            return candidates;
        }

        const sortType =
            sort.hasOwnProperty('sortType') === false ||
            [
                Constants.SORT_TYPE_NUMERIC,
                Constants.SORT_TYPE_TEXT,
                Constants.SORT_TYPE_OBJECT,
            ].indexOf(sort.sortType) === -1
                ? Constants.SORT_TYPE_TEXT
                : sort.sortType;

        return Sort.byColumn(candidates, sort.sortBy, sort.sortDirection, sortType);
    }

    static createPhoneNumberKeywordsFromKeywords(keywords) {
        let result = [];

        let keyword = keywords.join('');
        keyword = keyword.replace('+', '');

        if (isNaN(keyword) === true) {
            return [];
        }

        result.push(keyword.slice(-9));

        return result;
    }

    static filterCandidatesByAddDate(candidates, addDate) {
        if (addDate.timespan === Constants.CANDIDATES_LIST_FILTER_BY_ADD_DATE_CLEARED) {
            return candidates;
        }

        if (addDate.timespan === Constants.CANDIDATES_LIST_FILTER_BY_ADD_DATE_TIMESPAN_VALUE) {
            const startDate = new Date([addDate.dateFrom, '00:00:00'].join(' '));
            const endDate = new Date([addDate.dateTo, '23:59:59'].join(' '));

            return candidates.filter((candidate) => {
                const date = new Date(candidate.addDate);

                return date >= startDate && date <= endDate;
            });
        }

        return candidates.filter((candidate) => {
            const daysCountFromToday = DateFormatted.getDaysCountFromToday(
                candidate.addDate,
                false
            );

            return 0 - daysCountFromToday <= addDate.timespan;
        });
    }

    static filterCandidatesByName(candidates, filter) {
        const searchText = filter.searchText.trim();

        if (searchText === '') {
            return candidates;
        }

        let keywords = searchText.split(' ');

        if (keywords.length === 0 || candidates.length === 0) {
            return candidates;
        }

        const phoneNumberKeywords = this.createPhoneNumberKeywordsFromKeywords(keywords);

        if (phoneNumberKeywords.length > 0) {
            keywords = phoneNumberKeywords;
        }

        return candidates.filter((candidate) => {
            let candidateName =
                Strings.removeDiacritics(candidate.candidateName.toLowerCase()) +
                candidate.phone +
                candidate.email +
                candidate.phone.replace(/ /g, '');
            let add = true;

            keywords.forEach((keyword) => {
                keyword = Strings.removeDiacritics(keyword.toLowerCase());

                if (keyword !== '' && candidateName.indexOf(keyword) === -1) {
                    add = false;
                }
            });

            return add;
        });
    }

    static filterCandidatesByNote(candidates, note) {
        const searchText = note.trim();

        if (searchText === '') {
            return candidates;
        }

        const keywords = searchText.split(' ');

        if (keywords.length === 0 || candidates.length === 0) {
            return candidates;
        }

        return candidates.filter((candidate) => {
            const notes = candidate.notes.map((note) => note.candidateNoteTextForSearch).join('');
            let add = true;

            keywords.forEach((keyword) => {
                keyword = Strings.removeDiacritics(keyword.toLowerCase());

                if (keyword !== '' && notes.indexOf(keyword) === -1) {
                    add = false;
                }
            });

            return add;
        });
    }

    static filterCandidatesByPhaseId(candidates, filter) {
        if (
            filter.hasOwnProperty('phaseId') === false ||
            filter.phaseId === Constants.CANDIDATES_LIST_FILTER_BY_PHASE_ID_ALL
        ) {
            return candidates;
        }

        return candidates.filter((candidate) => candidate.stage.id === filter.phaseId);
    }

    static filterCandidatesByCandidateIds(candidates, candidateIds) {
        return candidates.filter((candidate) => candidateIds.includes(candidate.id) === true);
    }

    static filterCandidatesByPropertyCollection(candidates, filter) {
        if (
            filter.hasOwnProperty('stageCollectionIds') === false ||
            filter.stageCollectionIds.length === undefined
        ) {
            return candidates;
        }

        return candidates.filter(
            (candidate) =>
                filter.stageCollectionIds.includes(Constants.CANDIDATE_STAGE_UNKNOWN_ID) === true ||
                filter.stageCollectionIds.includes(parseInt(candidate.stage.id)) === true
        );
    }

    static filterCandidatesBySources(candidates, filter) {
        if (
            filter.hasOwnProperty('sourceTypeCollection') === false ||
            filter.sourceTypeCollection.length === undefined
        ) {
            return candidates;
        }

        return candidates.filter(
            (candidate) =>
                filter.sourceTypeCollection.includes(parseInt(candidate.source.id)) === true
        );
    }

    static filterCandidatesByRefusedAndNotContacted(candidates, filter) {
        if (
            filter.hasOwnProperty('isRefusedAndNotContacted') === false ||
            filter.isRefusedAndNotContacted === false
        ) {
            return candidates;
        }

        return candidates.filter((candidate) => candidate.isRefusedAndNotContacted === true);
    }

    static filterCandidatesByState(candidates, filter) {
        if (
            filter.isStateActive === false &&
            filter.isStatePassive === false &&
            filter.isAnonymized === false
        ) {
            return [];
        }

        if (
            filter.isStateActive === true &&
            filter.isStatePassive === true &&
            filter.isAnonymized === true
        ) {
            return candidates.filter(
                (candidate) => parseInt(candidate.stateId) !== Constants.CANDIDATE_STATE_DELETED
            );
        }

        let activeCandidates = [];
        let passiveCandidates = [];

        if (filter.isStateActive === true) {
            activeCandidates = candidates.filter(
                (candidate) => parseInt(candidate.stateId) === Constants.CANDIDATE_STATE_ACTIVE
            );
        }

        if (filter.isStatePassive === true) {
            passiveCandidates = candidates.filter(
                (candidate) => parseInt(candidate.stateId) === Constants.CANDIDATE_STATE_REFUSED
            );
        }

        let filteredCandidatesByState = [...activeCandidates, ...passiveCandidates];

        if (filter.isStatePassive === true || filter.isStateActive === true) {
            return filter.isAnonymized === true
                ? Arrays.removeDuplicates(
                    filteredCandidatesByState,
                    candidates.filter((candidate) => candidate.isAnonymized === true)
                )
                : filteredCandidatesByState.filter((candidate) => candidate.isAnonymized === false);
        } else {
            return filter.isAnonymized === true
                ? candidates.filter((candidate) => candidate.isAnonymized === true)
                : [];
        }
    }

    static filterCandidatesByNotContacted(candidates, filter) {
        if (filter.hasOwnProperty('isNotContacted') === true && filter.isNotContacted === true) {
            return candidates.filter((candidate) => candidate.isContacted === false);
        }

        return candidates;
    }

    static filterCandidatesByNoGdprAgreement(candidates, filter) {
        if (filter.hasOwnProperty('noGdprAgreement') === true && filter.noGdprAgreement === true) {
            return candidates.filter(
                (candidate) => candidate.gdprAgreement.hasConfirmedAgreement === false
            );
        }

        return candidates;
    }

    static filterCandidatesByTagList(candidates, filter) {
        if (filter.hasOwnProperty('tagList') === false || filter.tagList.length === 0) {
            return candidates;
        }

        return candidates.filter((candidate) => {
            const activeTags = filter.tagList.filter((tag) => tag.active === true);

            if (activeTags.length > 0 && candidate.tags.length === 0) {
                return false;
            }

            let bool = true;

            activeTags.forEach((tag) => {
                if (
                    candidate.tags.map((candidateTag) => candidateTag.tagId).includes(tag.tagId) ===
                    false
                ) {
                    bool = false;
                }
            });

            return bool;
        });
    }

    static filterCandidatesByScoreRange = (candidates, filter, loginManagerModel = null) => {
        const hasActiveFreeSubscription =
            loginManagerModel && UserRights.hasActiveFreeSubscription(loginManagerModel) === true;

        if (filter.hasOwnProperty('scoreRange') === false || filter.scoreRange === 0 || hasActiveFreeSubscription) {
            return candidates;
        }

        return candidates.filter(
            (candidate) => parseFloat(candidate.questionnaireResult) >= filter.scoreRange
        );
    };

    static filterCandidatesForPipeline = (candidates, filter, enabledFilters) => {
        let filteredCandidates = candidates;

        if (enabledFilters.includes(Constants.FILTER_BY_STATE) === true) {
            filteredCandidates = CandidatesHelper.filterCandidatesByState(
                filteredCandidates,
                filter
            );
        }

        return filteredCandidates;
    };

    static addCandidatesToStageList = (candidates, stageList = [], filter, enabledFilters) => {
        let filteredCandidates = candidates;

        if (enabledFilters.includes(Constants.FILTER_BY_STATE) === true) {
            filteredCandidates = CandidatesHelper.filterCandidatesByState(
                filteredCandidates,
                filter
            );
        }

        if (enabledFilters.includes(Constants.FILTER_BY_STATE_WITH_STAGE) === true) {
            filteredCandidates = CandidatesHelper.filterCandidatesByPropertyCollection(
                filteredCandidates,
                filter
            );
        }

        stageList.forEach((stage) => {
            stage.candidates = filteredCandidates.filter(
                (candidate) => candidate.stage.id === stage.stageId
            );
        });

        return stageList;
    };

    static getDefaultEnabledFilters(page = '', isExternalVisitor = false) {
        if (isExternalVisitor === true) {
            const commonEnabledFilters = [
                Constants.FILTER_BY_NAME,
                Constants.FILTER_BY_STATE,
                Constants.FILTER_BY_SCORE,
                Constants.FILTER_BY_SOURCE,
            ];

            switch (page) {
                case 'listSearchWithPipeline':
                    return commonEnabledFilters.concat([Constants.FILTER_BY_STATE_WITH_STAGE]);

                default:
                    return commonEnabledFilters.concat([Constants.FILTER_BY_PHASE]);
            }
        }

        const commonEnabledFilters = [
            Constants.FILTER_BY_NAME,
            Constants.FILTER_BY_TAG,
            Constants.FILTER_BY_SCORE,
            Constants.FILTER_BY_SOURCE,
            Constants.FILTER_BY_NOT_CONTACTED,
            Constants.FILTER_BY_NO_GDPR_AGREEMENT,
            Constants.FILTER_BY_CANDIDATE_IDS,
            Constants.FILTER_BY_NOTE,
            Constants.FILTER_BY_GDPR_AGREEMENT,
        ];

        switch (page) {
            case 'globalSearch':
                return commonEnabledFilters.concat([Constants.FILTER_BY_ADD_DATE]);

            case 'listSearchWithPipeline':
                return [
                    Constants.FILTER_BY_REFUSED_AND_NOT_CONTACTED,
                    Constants.FILTER_BY_STATE_WITH_STAGE,
                    Constants.FILTER_BY_STATE,
                    Constants.FILTER_BY_ADD_DATE,
                ].concat(commonEnabledFilters);

            default:
                return [
                    Constants.FILTER_BY_REFUSED_AND_NOT_CONTACTED,
                    Constants.FILTER_BY_PHASE,
                    Constants.FILTER_BY_STATE,
                    Constants.FILTER_BY_NO_GDPR_AGREEMENT,
                ].concat(commonEnabledFilters);
        }
    }

    static getDefaultEnabledColumns(
        page = '',
        activePackage = null,
        isExternalVisitor = false,
        loginManagerModel = null
    ) {
        const hasActiveFreeSubscription = loginManagerModel && UserRights.hasActiveFreeSubscription(loginManagerModel) === true;

        if (isExternalVisitor === true) {
            return ['name', 'source', 'addDate', 'stage', 'score', 'externalUsersNotes'];
        }

        let columns = [
            'stage',
            'score',
            'source',
            'tags',
            'note',
            'colleaguesNotes',
            'attachments',
            'lastEmail',
            'gdprAgreement',
            'expectedSalary',
            'disposableDate',
            'refusalReason',
            ...this.getEnabledColumnsByPackage(activePackage)
        ];

        if (hasActiveFreeSubscription) {
            columns = columns.filter(
                (column) => !['score', 'expectedSalary', 'disposableDate'].includes(column)
            );
        }

        if (page === 'globalSearch') {
            columns = [
                'position',
                ...columns,
            ];
        }

        return columns;
    }

    static getEnabledColumnsByPackage(activePackage) {
        let result = [];

        if (UserRights.hasAllowedQuestionnaire(activePackage) === true) {
            result = result.concat(['expectedSalary', 'disposableDate']);
        }

        return result;
    }

    static getDefaultSortOptions(page = '', isExternalVisitor = false, loginManagerModel = null) {
        const hasActiveFreeSubscription =
            loginManagerModel && UserRights.hasActiveFreeSubscription(loginManagerModel) === true;

        if (isExternalVisitor === true) {
            let options = [
                { id: 'questionnaireResultForSort', label: Translations.getStatic('byScore') },
                { id: 'surname', label: Translations.getStatic('bySurnameAlphabetically') },
                { id: 'addDate', label: Translations.getStatic('byDateOfReaction') },
                { id: 'stage.name', label: Translations.getStatic('byStage') },
                {
                    id: 'source.translatedSourceName',
                    label: Translations.getStatic('bySourceNameAlphabetically'),
                },
            ];

            if (hasActiveFreeSubscription) {
                options = options.filter(
                    (option) => !['questionnaireResultForSort'].includes(option.id)
                );
            }

            return options;
        }

        let options = [];

        switch (page) {
            case 'globalSearch':
                options = [
                    { id: 'questionnaireResultForSort', label: Translations.getStatic('byScore') },
                    { id: 'surname', label: Translations.getStatic('bySurnameAlphabetically') },
                    { id: 'addDate', label: Translations.getStatic('byDateOfReaction') },
                    {
                        id: 'source.translatedSourceName',
                        label: Translations.getStatic('bySourceNameAlphabetically'),
                    },
                    {
                        id: 'lastEmail',
                        label: Translations.getStatic('byCandidateCommunicationDate'),
                    },
                    {
                        id: 'gdprAgreement.agreementExpirationAtForSort',
                        label: Translations.getStatic('byGdprAgreement'),
                    },
                    {
                        id: 'questionnaireResultsData.expectedSalaryForSort',
                        label: Translations.getStatic('byExpectedSalary'),
                    },
                ];
                break;

            default:
                options = [
                    { id: 'questionnaireResultForSort', label: Translations.getStatic('byScore') },
                    { id: 'surname', label: Translations.getStatic('bySurnameAlphabetically') },
                    { id: 'addDate', label: Translations.getStatic('byDateOfReaction') },
                    { id: 'stage.name', label: Translations.getStatic('byStage') },
                    {
                        id: 'source.translatedSourceName',
                        label: Translations.getStatic('bySourceNameAlphabetically'),
                    },
                    {
                        id: 'lastEmail',
                        label: Translations.getStatic('byCandidateCommunicationDate'),
                    },
                    {
                        id: 'gdprAgreement.agreementExpirationAtForSort',
                        label: Translations.getStatic('byGdprAgreement'),
                    },
                    {
                        id: 'questionnaireResultsData.expectedSalaryForSort',
                        label: Translations.getStatic('byExpectedSalary'),
                    },
                ];
                break;
        }

        if (hasActiveFreeSubscription) {
            options = options.filter(
                (option) =>
                    ![
                        'questionnaireResultForSort',
                        'questionnaireResultsData.expectedSalaryForSort',
                    ].includes(option.id)
            );
        }

        return options;
    }

    static getDefaultMultipleActionOptions(activePackage, page = '') {
        const hasAllowedExport = UserRights.hasAllowedExport(activePackage) === true;

        switch (page) {
            case 'globalSearch':
                return [
                    { value: 'sendEmail', text: Translations.getStatic('sendEmail') },
                    { value: 'copy', text: Translations.getStatic('copyToAnotherJob') },
                    { value: 'move', text: Translations.getStatic('moveToAnotherJob') },
                    { value: 'sendToColleagues', text: Translations.getStatic('sendToColleagues') },
                    { value: 'anonymize', text: Translations.getStatic('anonymize') },
                ];

            default: {
                const actions = [
                    { value: 'changeStage', text: Translations.getStatic('changeStage') },
                    { value: 'refuse', text: Translations.getStatic('refuse') },
                    { value: 'sendEmail', text: Translations.getStatic('sendEmail') },
                    { value: 'createEvent', text: Translations.getStatic('createEvent') },
                    { value: 'copy', text: Translations.getStatic('copyToAnotherJob') },
                    { value: 'move', text: Translations.getStatic('moveToAnotherJob') },
                    { value: 'sendToColleagues', text: Translations.getStatic('sendToColleagues') },
                    { value: 'addTag', text: Translations.getStatic('addTag') },
                    { value: 'anonymize', text: Translations.getStatic('anonymize') },
                ];

                if (hasAllowedExport) {
                    actions.push({
                        value: 'export',
                        text: Translations.getStatic('exportCandidates'),
                    });
                }

                return actions;
            }
        }
    }

    static getClosedJobMultipleActionOptions() {
        return [
            { value: 'copy', text: Translations.getStatic('copyToAnotherJob') },
            { value: 'move', text: Translations.getStatic('moveToAnotherJob') },
            { value: 'sendToColleagues', text: Translations.getStatic('sendToColleagues') },
        ];
    }

    static updateCandidatesData(candidatesFromStore, candidateId, newCandidateData) {
        if (candidatesFromStore === undefined) {
            return candidatesFromStore;
        }

        return candidatesFromStore.map((candidate) =>
            parseInt(candidateId) === parseInt(candidate.id)
                ? this.updateCandidateData(candidate, newCandidateData)
                : candidate
        );
    }

    static updateCandidateData(candidate, newCandidateData) {
        const result = { ...candidate };

        Object.keys(newCandidateData).forEach((key) => {
            switch (key) {
                case 'attachmentsCountDesc':
                    if (candidate.hasOwnProperty('attachmentsCount') === true) {
                        result['attachmentsCount'] =
                            candidate['attachmentsCount'] - newCandidateData[key];
                    }
                    break;

                case 'attachmentsCountAsc':
                    if (candidate.hasOwnProperty('attachmentsCount') === true) {
                        result['attachmentsCount'] =
                            candidate['attachmentsCount'] + newCandidateData[key];
                    }
                    break;

                default:
                    if (candidate.hasOwnProperty(key) === true) {
                        result[key] = newCandidateData[key];
                    }
                    break;
            }
        });

        return result;
    }

    static getCurrentBasicCandidateData(props) {
        const basicData = [
            'candidateName',
            'attachmentsCount',
            'questionnaireResult',
            'hasQuestionnaireResult',
        ];

        const getBasicDataFromObject = (object) => {
            let result = {};

            basicData.forEach((attr) => {
                if (object.hasOwnProperty(attr) === true) {
                    result[attr] = object[attr];
                }
            });

            return result;
        };

        if (
            props.hasOwnProperty('candidateModel') === true &&
            props.candidateModel.hasOwnProperty('candidate') === true &&
            props.candidateModel.candidate.id === props.candidateId
        ) {
            return getBasicDataFromObject(props.candidateModel.candidate);
        }

        if (Object.keys(props).length > 0) {
            return getBasicDataFromObject(props);
        }

        return {};
    }

    static filterAndSortEvents(events) {
        const newEvents = events.filter((event) => {
            let todayDateTime = moment();

            todayDateTime.hours(0);
            todayDateTime.minutes(0);
            todayDateTime.seconds(0);

            const eventDateTime = moment(event.startAt).toDate().getTime();

            return eventDateTime >= todayDateTime.toDate().getTime();
        });

        return newEvents.length === 0 ? newEvents : Sort.byColumn(newEvents, 'startAt');
    }

    /**
     * @param candidates
     * @param candidateIds
     * @param destinationStage
     * @param destinationPositionInStage
     * @returns {*}
     */
    static moveCandidatesToPositionInStage(
        candidates,
        candidateIds,
        destinationStage,
        destinationPositionInStage
    ) {
        if (candidates === undefined) {
            return candidates;
        }

        let rearrangedCandidates = candidates;

        for (let i = candidateIds.length - 1; i >= 0; i--) {
            rearrangedCandidates = this.moveCandidateToPositionInStage(
                rearrangedCandidates,
                candidateIds[i],
                destinationStage,
                destinationPositionInStage
            );
        }

        return rearrangedCandidates;
    }

    /**
     * @param candidates
     * @param candidateId
     * @param destinationStage
     * @param destinationPositionInStage
     * @returns {*}
     */
    static moveCandidateToPositionInStage(
        candidates,
        candidateId,
        destinationStage,
        destinationPositionInStage
    ) {
        const sourceCandidate = candidates.find((candidate) => candidate.id === candidateId);

        if (sourceCandidate === undefined) {
            return candidates;
        }

        const sourceStageId = sourceCandidate.stage.id;

        const moveAlsoCandidateDroppedOnto =
            sourceCandidate.positionInStage > destinationPositionInStage ||
            sourceStageId !== destinationStage.stageId;

        const reorderedCandidates = candidates.map((candidate) => {
            let positionInStage = candidate.positionInStage;
            let stage = candidate.stage;

            if (candidate.id === candidateId) {
                positionInStage =
                    moveAlsoCandidateDroppedOnto === true
                        ? destinationPositionInStage
                        : destinationPositionInStage + 1;

                stage = {
                    ...destinationStage,
                    id: destinationStage.stageId,
                };
            } else if (candidate.stage.id === destinationStage.stageId) {
                if (moveAlsoCandidateDroppedOnto === true) {
                    positionInStage =
                        candidate.positionInStage >= destinationPositionInStage
                            ? candidate.positionInStage + 1
                            : candidate.positionInStage;
                } else {
                    positionInStage =
                        candidate.positionInStage > destinationPositionInStage
                            ? candidate.positionInStage + 1
                            : candidate.positionInStage;
                }
            }

            return {
                ...candidate,
                positionInStage,
                stage,
            };
        });

        return this.rearrangeCandidatesPositionInStage(reorderedCandidates, [
            sourceStageId,
            destinationStage.stageId,
        ]);
    }

    /**
     * @param reorderedCandidates
     * @param stagesToUpdate
     * @returns {*}
     */
    static rearrangeCandidatesPositionInStage(reorderedCandidates, stagesToUpdate = []) {
        const candidates = this.sortCandidates(
            reorderedCandidates,
            Constants.CANDIDATES_PIPELINE_SORT_BY_DEFAULT
        );

        const positionInStage = [];

        return candidates.map((candidate) => {
            if (
                stagesToUpdate.length > 0 &&
                stagesToUpdate.includes(candidate.stage.id) === false
            ) {
                return candidate;
            }

            positionInStage['positionInStage' + candidate.stage.id] =
                positionInStage.hasOwnProperty('positionInStage' + candidate.stage.id) === true
                    ? positionInStage['positionInStage' + candidate.stage.id] + 1
                    : 0;

            return {
                ...candidate,
                positionInStage: positionInStage['positionInStage' + candidate.stage.id],
            };
        });
    }

    static mergeFullCandidatesDataIntoState(state, candidatesSelectorFunction, data) {
        if (
            state.hasOwnProperty('fullCandidates') === false ||
            state.fullCandidates === undefined
        ) {
            return {};
        }

        return {
            fullCandidates: state.fullCandidates.map((candidate) =>
                candidatesSelectorFunction(candidate) === true
                    ? this.updateCandidateData(candidate, data)
                    : candidate
            ),
        };
    }

    static mergeBulkFullCandidatesDataIntoState(state, candidatesSelectorFunction) {
        if (
            state.hasOwnProperty('fullCandidates') === false ||
            state.fullCandidates === undefined
        ) {
            return {};
        }

        return {
            fullCandidates: state.fullCandidates.map((candidate) => {
                const candidateData = candidatesSelectorFunction(candidate);

                return candidateData === null
                    ? candidate
                    : this.updateCandidateData(candidate, candidateData);
            }),
        };
    }

    static mergeFullCandidatesDataIntoStateWithCallback(
        state,
        candidatesSelectorFunction,
        dataCallback,
        fullCandidatesParam = 'fullCandidates'
    ) {
        if (state.hasOwnProperty(fullCandidatesParam) === false) {
            return {};
        }

        if (state[fullCandidatesParam] === undefined) {
            return {};
        }

        let result = {};

        result[fullCandidatesParam] = state[fullCandidatesParam].map((candidate) =>
            candidatesSelectorFunction(candidate) === true ? dataCallback(candidate) : candidate
        );

        return result;
    }

    static prepareIsContactedCandidate(payload, candidate) {
        if (payload.hasOwnProperty('sendToCandidates') === false) {
            return {
                isContacted: candidate.isContacted,
            };
        }

        if (payload.sendToCandidates === false) {
            return {
                isContacted: candidate.isContacted,
            };
        }

        return {
            isContacted:
                payload.candidateIds.includes(candidate.id) === true ? true : candidate.isContacted,
        };
    }

    static prepareWasCandidateSentToColleague(payload, candidatesSentToColleague, candidate) {
        return {
            wasSentToColleague: payload.candidateIds.includes(candidate.id) === true
                && candidatesSentToColleague.find(candidateSentToColleague => candidateSentToColleague.candidateId === candidate.id) !== undefined
                    ? candidatesSentToColleague.find(candidateSentToColleague => candidateSentToColleague.candidateId === candidate.id).wasSentToColleague
                    : candidate.wasSentToColleague,
        };
    }

    static getBasicCandidateDataByParams(fullCandidateData, resultParams) {
        let result = {};

        Object.keys(fullCandidateData).forEach((param) => {
            if (resultParams.indexOf(param) > -1) {
                result[param] = fullCandidateData[param];
            }
        });

        return result;
    }

    static getBasicCandidatesDataForJobDetail(fullCandidatesData) {
        const resultParams = [
            'id',
            'tags',
            'stateId',
            'title',
            'firstname',
            'surname',
            'candidateName',
            'email',
            'phone',
            'isAnonymized',
            'questionnaireResult',
            'questionnaireResultForSort',
            'addDate',
            'source',
            'isContacted',
            'lastEmail',
            'isRefusedAndNotContacted',
            'stage',
            'positionInStage',
            'gdprAgreement',
            'isFavorite',
        ];

        return fullCandidatesData.map((data) =>
            this.getBasicCandidateDataByParams(data, resultParams)
        );
    }

    static getBasicCandidatesDataForGlobalSearch(fullCandidatesData) {
        const resultParams = [
            'id',
            'firstname',
            'surname',
            'candidateName',
            'email',
            'phone',
            'addDate',
            'questionnaireResult',
            'questionnaireResultForSort',
            'tags',
            'source',
            'lastEmail',
            'isContacted',
            'gdprAgreement',
            'isFavorite',
        ];

        return fullCandidatesData.map((data) =>
            this.getBasicCandidateDataByParams(data, resultParams)
        );
    }

    /**
     * @param {string} scrollableElementId
     * @param {string} table
     * @param {string} lastLoadedElementClass
     */
    static needLoadMoreCandidatesOnList(
        scrollableElementId = 'scrollableDiv',
        table = '.table-checkboxes',
        lastLoadedElementClass = '.last-loaded-candidate'
    ) {
        const scrollableElement = document.getElementById(scrollableElementId);

        if (scrollableElement === null) {
            return false;
        }

        const lastLoadedElement = scrollableElement.querySelector(lastLoadedElementClass);

        if (lastLoadedElement === null) {
            return false;
        }

        const listTable = scrollableElement.querySelector(table);

        if (listTable === null) {
            return false;
        }

        const scrollableElementRect = scrollableElement.getBoundingClientRect();
        const listTableRect = listTable.getBoundingClientRect();

        return listTableRect.height < scrollableElementRect.height;
    }

    /**
     * @param candidates
     * @returns {*}
     */
    static filterNotAnonymizedCandidates(candidates) {
        return candidates.filter((candidate) => candidate.isAnonymized === false);
    }

    /**
     * @param column
     * @returns {boolean}
     */
    static isQuestionnaireColumn(column) {
        return ['disposableDate', 'expectedSalary'].includes(column) === true;
    }
}
