import { jsx as _jsx } from "react/jsx-runtime";
import { useFormState } from 'react-hook-form';
import { ModalContent } from '@profesia/adamui/components/common/modal';
import Header from '@profesia/mark-offer-form/components/Layout/Header';
import { useOptionsContext } from '@profesia/mark-offer-form/services/OptionsContext';
import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import { useHandleCloseModal } from '@profesia/mark-offer-form/utils';
const Layout = ({ content, onClose }) => {
    const t = useTranslationInPageLanguage('common.actions');
    const { isEditMode, isSingleStep } = useOptionsContext();
    const { isDirty } = useFormState();
    const handleCloseModal = useHandleCloseModal(onClose);
    const showHeader = !isEditMode && isSingleStep;
    return (_jsx(ModalContent, { closeButtonText: t('cancel'), spacing: 0, content: content, onClose: () => handleCloseModal(isDirty), showHeaderInModal: showHeader, header: isEditMode ? null : isSingleStep ? _jsx(Header, {}) : null }));
};
export default Layout;
