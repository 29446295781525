import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { Textarea } from '@profesia/adamui/components/common/input';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_COMPANY_CHARACTERISTICS } from '@profesia/mark-offer-form/components/content/form/valuePaths';
const CompanyCharacteristics = () => {
    const t = useGetFixedT('companyDetails');
    const { setValue, control, formState: { errors }, } = useFormContext();
    const characteristics = useWatch({ name: FORM_COMPANY_CHARACTERISTICS, control });
    return (_jsx(Textarea, { label: t('briefDescription'), value: characteristics, onChange: (value) => {
            setValue(FORM_COMPANY_CHARACTERISTICS, value);
        }, minHeight: 170, required: true, errorMessage: errors?.company?.characteristics?.message }));
};
export default CompanyCharacteristics;
