import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTakeMeTo } from '../actions/TransitionActions';
import Strings from '../helpers/Strings';
import WithRouterProp from '../helpers/WithRouterProp';
import Constants from '../helpers/Constants';

class Transition extends Component {
    state = {
        transitionStage: '',
        delay: Constants.PAGES_TRANSITION_TIME,
        takingYouAway: false,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                takingYouAway: false,
            });
        }

        const {
            transitionLogic: { takeMeTo },
            pathNamesOfTabs,
            location,
        } = this.props;

        const isNextPathDetailJob = Strings.includesStringInArrayOfStrings(
            location.pathname,
            pathNamesOfTabs
        );
        const isCurrentPathDetailJob = Strings.includesStringInArrayOfStrings(
            takeMeTo,
            pathNamesOfTabs
        );

        if (prevProps.transitionLogic.takeMeTo === '' && takeMeTo !== '') {
            if (isNextPathDetailJob !== isCurrentPathDetailJob) {
                this.doTransition('slide');
            } else {
                this.doTransition('fade');
            }
        }
    }

    doTransition = (transitionName) => {
        const {
            transitionLogic: { takeMeTo },
            setTakeMeTo,
            navigate,
        } = this.props;

        const phaseIn = transitionName + 'In';
        const phaseOut = transitionName + 'Out';

        if (this.state.transitionStage !== phaseOut) {
            this.setState({
                transitionStage: phaseOut,
            });
        }

        const timeout = setTimeout(() => {
            if (takeMeTo !== '') {
                navigate(takeMeTo);
                setTakeMeTo('');

                this.setState({
                    takingYouAway: true,
                });
            }

            clearTimeout(timeout);

            if (this.state.transitionStage !== phaseIn) {
                this.setState({
                    transitionStage: phaseIn,
                });

                const timeoutClear = setTimeout(() => {
                    if (this.state.transitionStage === phaseIn) {
                        this.setState({
                            transitionStage: '',
                        });
                    }

                    clearTimeout(timeoutClear);
                }, this.state.delay);
            }
        }, this.state.delay);
    };

    render() {
        const { children } = this.props;

        return (
            <div className={`transition-container ${this.state.transitionStage}`}>
                {this.state.takingYouAway === true ? null : children}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    transitionLogic: state.transitionLogic,
});

const mapDispatchToProps = {
    setTakeMeTo,
};

Transition.defaultProps = {
    pathNamesOfTabs: [
        'pipeline',
        'candidates',
        'detail',
        'candidate-sources',
        'statistics',
        'create-job-ad',
    ],
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouterProp(Transition));
