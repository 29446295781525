import { EMAIL_REGEXP } from '@profesia/mark-offer-form/codebook/validationRules';
import addError from '../addError';
const getContactPersonErrors = ({ contactPerson }, t) => {
    let errors = {};
    const isEmailInvalid = Boolean(contactPerson.email) && !new RegExp(EMAIL_REGEXP).test(contactPerson.email);
    errors = {
        ...addError('contactPerson.email', !contactPerson.email, t('contactPerson.email.required')),
        ...addError('contactPerson.email', isEmailInvalid, t('contactPerson.email.invalid')),
    };
    return errors;
};
export default getContactPersonErrors;
