import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext, useWatch } from 'react-hook-form';
import { RadioButtons } from '@profesia/adamui/components/common/input';
import { useGetFixedT } from '@profesia/mark-offer-form/i18n';
import { FORM_SHOULD_BE_NOTIFIED_ABOUT_NEW_RESUMES } from '@profesia/mark-offer-form/components/content/form/valuePaths';
var CvFromCvdbOption;
(function (CvFromCvdbOption) {
    CvFromCvdbOption[CvFromCvdbOption["SendAllAgents"] = 1] = "SendAllAgents";
    CvFromCvdbOption[CvFromCvdbOption["StoreProfile"] = 0] = "StoreProfile";
})(CvFromCvdbOption || (CvFromCvdbOption = {}));
const CvFromCvdb = () => {
    const { setValue, control } = useFormContext();
    const value = useWatch({ name: FORM_SHOULD_BE_NOTIFIED_ABOUT_NEW_RESUMES, control });
    const t = useGetFixedT('jobSettings.cvFromCvdb');
    const options = [
        {
            id: CvFromCvdbOption.SendAllAgents.toString(),
            label: t('value.sendAllAgents'),
        },
        {
            id: CvFromCvdbOption.StoreProfile.toString(),
            label: t('value.storeProfile'),
        },
    ];
    return (_jsx(RadioButtons, { label: t('label'), options: options, value: Number(value), onChange: (_, requiredOptionId) => {
            setValue(FORM_SHOULD_BE_NOTIFIED_ABOUT_NEW_RESUMES, Boolean(Number(requiredOptionId)));
        } }));
};
export default CvFromCvdb;
