import Storages from './Storages';
import Constants from './Constants';
import Channel from './Channel';

class Language {
    static set(language: string): void {
        if (!Constants.ALLOWED_LANGUAGES.includes(language)) {
            language = this.getDefaultLanguage();
        }

        if (language !== Storages.getLocal('language', '')) {
            Storages.setLocal('language', language);
        }
    }

    static get(): string {
        return Storages.getLocal('language', this.getDefaultLanguage());
    }

    static getDefaultLanguage(): string {
        switch (Channel.getChannel()) {
            case Channel.CHANNEL_SK:
                return Constants.LANGUAGE_SK;

            case Channel.CHANNEL_CZ:
                return Constants.LANGUAGE_CS;

            default:
                return Constants.LANGUAGE_SK;
        }
    }
}

export default Language;
