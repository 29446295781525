import { useTranslationInPageLanguage } from '@profesia/mark-offer-form/i18n';
import getErrors from './getErrors';
const useGetResolver = (validationSchema) => {
    const t = useTranslationInPageLanguage('errors.form');
    return async (values) => {
        const errors = getErrors({ data: values, validationSchema, t });
        return {
            values,
            errors,
        };
    };
};
export default useGetResolver;
