import Translations from './Translations';
import {CommonObjectType} from '../../interfaces/CommonTypes';

const ALLOWED_LANGUAGES: string[]  = ['sk', 'en', 'cs', 'hu'];
const DEFAULT_LANGUAGE: string     = 'sk';
const LANGUAGE_SK: string          = 'sk';
const LANGUAGE_CS: string          = 'cs';
const LANGUAGE_HU: string          = 'hu';
const LANGUAGE_EN: string          = 'en';
const LANGUAGE_DE: string          = 'de';
const LANGUAGE_CS_PROFESIA: string = 'cz';

const DOMAIN_SK: string = 'Profesia.sk';
const DOMAIN_CZ: string = 'Profesia.cz';

const PROFESIA_SK_LOCAL: string      = 'local.profesia.sk';
const PROFESIA_SK_MARK_LOCAL: string = 'mark.local.profesia.sk';
const PROFESIA_CZ_LOCAL: string      = 'mark.local.profesia.cz';
const PROFESIA_SK_TEST: string       = 'test.profesia.sk';
const PROFESIA_CZ_TEST: string       = 'test.profesia.cz';
const PROFESIA_SK_MASTER: string     = 'master.profesia.sk';
const PROFESIA_CZ_MASTER: string     = 'master.profesia.cz';

const SUPER_ADMINS: number[] = [
    193, // Michal Strba
    267, // Stefan Csincsik
    292, // Peter Blazej
    342, // Peter Nagy
    364, // Andrej Mihalik
    374, // Lukas Cisar
    410, // Juraj Pitak
    440, // Martin Stepanek
    471, // Jozef Belusik
    656, // David Reicher
    660, // Branislav Zagrapan
];

const MARK_USER_ID: number = 0;

const JOB_STATE_CONCEPT: number   = 0;
const JOB_STATE_OPEN: number      = 1;
const JOB_STATE_CLOSED: number    = 2;
const JOB_STATE_UNPAID: number    = 3;
const JOB_STATE_INVISIBLE: number = 4;

const CANDIDATE_STAGE_ACCEPTED_ID: number  = 14;
const CANDIDATE_STAGE_NEW_ID: number       = 7;
const CANDIDATE_STAGE_UNKNOWN_ID           = -1;
const CANDIDATE_STAGE_COLLECTED_ID: number = 3;

const CANDIDATE_STATE_REFUSED: number = 0;
const CANDIDATE_STATE_ACTIVE: number  = 1;
const CANDIDATE_STATE_DELETED: number = 2;

const SORT_TYPE_NUMERIC: string = 'numeric';
const SORT_TYPE_TEXT: string    = 'text';
const SORT_TYPE_OBJECT: string  = 'object';

const JOBS_LIST_FILTER_JOB_STATE_DEFAULT: number = 1;
const JOBS_LIST_FILTER_JOB_STATE_ALL             = -1;

const JOBS_LIST_FILTER_JOB_STATE_REASON_DEFAULT = -1;
const JOBS_LIST_FILTER_JOB_STATE_REASON_ALL     = -1;

const JOBS_LIST_FILTER_USER_ID_ALL = -1;

const JOBS_LIST_FILTER_BY_ADD_DATE_TIMESPAN_VALUE = -1;
const JOBS_LIST_FILTER_BY_ADD_DATE_CLEARED        = -2;
const JOBS_LIST_FILTER_BY_ADD_DATE_DEFAULT_VALUE  = JOBS_LIST_FILTER_BY_ADD_DATE_CLEARED;

const CANDIDATES_LIST_FILTER_BY_ADD_DATE_TIMESPAN_VALUE = -1;
const CANDIDATES_LIST_FILTER_BY_ADD_DATE_CLEARED        = -2;
const CANDIDATES_LIST_FILTER_BY_ADD_DATE_DEFAULT_VALUE  = CANDIDATES_LIST_FILTER_BY_ADD_DATE_CLEARED;

const JOBS_LIST_SORT_BY_DEFAULT: CommonObjectType = {
    sortBy: 'countNewApplicants',
    sortDirection: 'DESC',
    sortType: SORT_TYPE_NUMERIC,
};

const JOBS_LIST_SORT_OPTIONS: CommonObjectType = {
    'jobTitle': {direction: 'ASC', type: SORT_TYPE_TEXT},
    'addDate': {direction: 'DESC', type: SORT_TYPE_TEXT},
    'countNewApplicants': {direction: 'DESC', type: SORT_TYPE_NUMERIC},
};

const DASHBOARD_OTHER_JOBS_LIST_SORT_BY_DEFAULT: CommonObjectType = {
    sortBy: 'lastActionAt',
    sortDirection: 'DESC',
    sortType: SORT_TYPE_TEXT,
};

const DASHBOARD_RECENT_JOBS_LIST_SORT_BY_DEFAULT: CommonObjectType = {
    sortBy: 'countNewApplicants',
    sortDirection: 'DESC',
    sortType: SORT_TYPE_NUMERIC,
};

const DASHBOARD_JOBS_LIST_SORT_OPTIONS: CommonObjectType = {
    'countNewApplicants': {direction: 'DESC', type: SORT_TYPE_NUMERIC},
    'addDate': {direction: 'DESC', type: SORT_TYPE_TEXT},
    'jobTitle': {direction: 'ASC', type: SORT_TYPE_TEXT},
    'lastActionAt': {direction: 'DESC', type: SORT_TYPE_TEXT},
};

const CANDIDATES_LIST_TABLE_VISIBLE_COLUMNS: string[] = ['checkbox', 'source', 'tags', 'note', 'score'];

const CANDIDATES_LIST_SORT_BY_DEFAULT: CommonObjectType = {
    sortBy: 'addDate',
    sortDirection: 'DESC',
    sortType: SORT_TYPE_TEXT,
};

const CANDIDATES_PIPELINE_SORT_BY_DEFAULT: CommonObjectType = {
    sortBy: 'positionInStage',
    sortDirection: 'ASC',
    sortType: SORT_TYPE_NUMERIC,
};

const CANDIDATES_LIST_SORT_OPTIONS: CommonObjectType = {
    'questionnaireResultForSort': {direction: 'DESC', type: SORT_TYPE_NUMERIC},
    'surname': {direction: 'ASC', type: SORT_TYPE_TEXT},
    'addDate': {direction: 'DESC', type: SORT_TYPE_TEXT},
    'stage.name': {direction: 'DESC', type: SORT_TYPE_OBJECT},
    'source.translatedSourceName': {direction: 'DESC', type: SORT_TYPE_OBJECT},
    'hasDuplicities': {direction: 'DESC', type: SORT_TYPE_TEXT},
    'lastEmail': {direction: 'DESC', type: SORT_TYPE_TEXT},
    'gdprAgreement.agreementExpirationAtForSort': {direction: 'DESC', type: SORT_TYPE_OBJECT},
    'questionnaireResultsData.expectedSalaryForSort': {direction: 'DESC', type: SORT_TYPE_OBJECT},
};

const QUESTIONNAIRES_LIST_SORT_OPTIONS: CommonObjectType = {
    'name': {direction: 'ASC', type: SORT_TYPE_TEXT},
    'createdAt': {direction: 'DESC', type: SORT_TYPE_TEXT},
    'userName': {direction: 'ASC', type: SORT_TYPE_TEXT},
    'privacyStatus': {direction: 'DESC', type: SORT_TYPE_TEXT},
};

const AUTO_ANSWERS_LIST_SORT_OPTIONS: CommonObjectType = {
    'name': {direction: 'ASC', type: SORT_TYPE_TEXT},
    'createdAt': {direction: 'DESC', type: SORT_TYPE_TEXT},
    'userName': {direction: 'ASC', type: SORT_TYPE_TEXT},
    'privacyStatus': {direction: 'DESC', type: SORT_TYPE_TEXT},
};

const EMAIL_TEMPLATES_LIST_SORT_OPTIONS: CommonObjectType = {
    'name': {direction: 'ASC', type: SORT_TYPE_TEXT},
    'createdAt': {direction: 'DESC', type: SORT_TYPE_TEXT},
    'userName': {direction: 'ASC', type: SORT_TYPE_TEXT},
    'privacyStatus': {direction: 'DESC', type: SORT_TYPE_TEXT},
};

const PIPELINE_TEMPLATES_LIST_SORT_OPTIONS: CommonObjectType = {
    'name': {direction: 'ASC', type: SORT_TYPE_TEXT},
    'createdAt': {direction: 'DESC', type: SORT_TYPE_TEXT},
    'userName': {direction: 'ASC', type: SORT_TYPE_TEXT},
    'privacyStatus': {direction: 'DESC', type: SORT_TYPE_TEXT},
};

const CANDIDATES_LIST_FILTER_BY_PHASE_ID_ALL: string     = 'all';
const CANDIDATES_LIST_FILTER_BY_PHASE_ID_ACTIVE: string  = 'active';
const CANDIDATES_LIST_FILTER_BY_PHASE_ID_PASSIVE: string = 'passive';

const CANDIDATES_LIST_FILTER_BY_STATE_ID_ALL: string     = 'all';
const CANDIDATES_LIST_FILTER_BY_STATE_ID_ACTIVE: string  = 'active';
const CANDIDATES_LIST_FILTER_BY_STATE_ID_PASSIVE: string = 'passive';

const ATTACHMENT_TYPE_CV: number                   = 1;
const ATTACHMENT_TYPE_MOTIVATION_LETTER: number    = 2;
const ATTACHMENT_TYPE_COVER_LETTER: number         = 3;
const ATTACHMENT_TYPE_PHOTO: number                = 4;
const ATTACHMENT_TYPE_OTHER: number                = 5;
const ATTACHMENT_TYPE_RANKING: number              = 32;
const ATTACHMENT_TYPE_QUESTIONNAIRE_RESULT: number = 7;

const AGE_MIN: number = 15;
const AGE_MAX: number = 70;

const QUESTIONNAIRE_QUESTION_TYPE_ONE: number             = 1;
const QUESTIONNAIRE_QUESTION_TYPE_MORE: number            = 2;
const QUESTIONNAIRE_QUESTION_TYPE_TEXT: number            = 3;
const QUESTIONNAIRE_QUESTION_TYPE_LONGTEXT: number        = 4;
const QUESTIONNAIRE_QUESTION_TYPE_SELECTBOX: number       = 7;
const QUESTIONNAIRE_QUESTION_TYPE_SALARY: number          = 11;
const QUESTIONNAIRE_QUESTION_TYPE_DISPOSABLE_DATE: number = 12;

const QUESTIONNAIRE_ANSWER_VALUE_REQUIRED: string = 'required';

const TEMPLATE_PHASE_INVITATION: number        = 2;
const TEMPLATE_PHASE_REFUSED: number           = 6;
const TEMPLATE_PHASE_SEND_TO_COLLEAGUE: number = 10;
const TEMPLATE_PHASE_SEND_TO_APPLICANT: number = 11;

const APPLICANTS_LIST_TYPE_APPLICATION: string = 'application';
const APPLICANTS_LIST_TYPE_DUPLICITIES: string = 'duplicities';
const APPLICANTS_LIST_TYPE_SEARCH: string      = 'search';
const APPLICANTS_LIST_TYPE_DATABASE: string    = 'database';
const APPLICANTS_LIST_TYPE_PHASE: string       = 'phase';
const APPLICANTS_LIST_TYPE_UNKNOWN: string     = 'unknown';

const LIST_PAGE_SIZE: number = 35;

const LOADING_BOX_TYPE_SPINNER: string       = 'spinner';
const LOADING_BOX_TYPE_SPINNER_SMALL: string = 'spinnerSmall';
const LOADING_BOX_TYPE_PROGRESSBAR: string   = 'progressbar';

const GLOBAL_SEARCH_TYPE_CANDIDATE: string   = 'candidate';
const GLOBAL_SEARCH_TYPE_GLOBAL_LINK: string = 'globalLink';
const GLOBAL_SEARCH_TYPE_EMPTY: string       = 'empty';
const GLOBAL_SEARCH_RESULTS_COUNT: number    = 5;

const DEFAULT_DATETIME: string = '0000-00-00 00:00:00';
const DEFAULT_DATE: string     = '0000-00-00';

const FILTER_BY_NAME: string                      = 'byName';
const FILTER_BY_STATE_WITH_STAGE: string          = 'byStateWithStage';
const FILTER_BY_STATE: string                     = 'byState';
const FILTER_BY_PHASE: string                     = 'byPhase';
const FILTER_BY_TAG: string                       = 'byTag';
const FILTER_BY_SOURCE: string                    = 'bySource';
const FILTER_BY_REFUSED_AND_NOT_CONTACTED: string = 'byRefusedAndNotContacted';
const FILTER_BY_SCORE: string                     = 'byScore';
const FILTER_BY_ANONYMIZED: string                = 'byAnonymized';
const FILTER_BY_NOT_CONTACTED: string             = 'byNotContacted';
const FILTER_BY_NO_GDPR_AGREEMENT: string         = 'byNoGdprAgreement';
const FILTER_BY_ADD_DATE: string                  = 'byAddDate';
const FILTER_BY_CANDIDATE_IDS: string             = 'byCandidateIds';
const FILTER_BY_NOTE: string                      = 'byNote';
const FILTER_BY_GDPR_AGREEMENT: string            = 'byGdprAgreement';

const FILTER_NAME_CANDIDATE_STATE: string       = 'candidatesState';
const FILTER_NAME_CANDIDATE_SOURCES: string     = 'candidateSource';
const FILTER_NAME_CANDIDATE_STAGE: string       = 'candidatesInStage';
const FILTER_NAME_CANDIDATE_STATUS: string      = 'candidateStatus';
const FILTER_NAME_QUESTIONNAIRE_RESULTS: string = 'questionnaireResults';
const FILTER_NAME_PROCESS_STEP: string          = 'processStep';
const FILTER_NAME_TAGS: string                  = 'tags';
const FILTER_NAME_OTHER: string                 = 'other';
const FILTER_NAME_PERIOD: string                = 'period';
const FILTER_NAME_GDPR_AGREEMENT: string        = 'gdprAgreement';

const USER_ROLE_BASIC: number            = 0;
const USER_ROLE_MANAGER: number          = 1;
const USER_ROLE_RECRUITER: number        = 2;
const USER_ROLE_EXTERNAL_EDITOR: number  = 3;
const USER_ROLE_EXTERNAL_VISITOR: number = 4;

const EMAIL_EDITOR_FIELD_SEND_AT: string         = 'sendAt';
const EMAIL_EDITOR_FIELD_SENDER_INFO: string     = 'senderInfo';
const EMAIL_EDITOR_FIELD_SENDER: string          = 'sender';
const EMAIL_EDITOR_FIELD_RECIPIENTS_INFO: string = 'recipientsInfo';
const EMAIL_EDITOR_FIELD_BCC: string             = 'bcc';
const EMAIL_EDITOR_FIELD_RECIPIENT: string       = 'recipient';
const EMAIL_EDITOR_FIELD_RECIPIENTS: string      = 'recipients';

const EMAIL_TYPE_TO_COLLEAGUE: number   = 1;
const EMAIL_TYPE_TO_CANDIDATE: number   = 2;
const EMAIL_TYPE_AUTO_REPLY: number     = 3;
const EMAIL_TYPE_FROM_CANDIDATE: number = 4;
const EMAIL_TYPE_OUT_OF_OFFICE: number  = 5;

const TEMPLATE_TYPE_AUTO_ANSWER: string   = 'autoAnswer';
const TEMPLATE_TYPE_QUESTIONNAIRE: string = 'questionnaire';
const TEMPLATE_TYPE_EMAIL: string         = 'email';
const TEMPLATE_TYPE_PIPELINE: string      = 'pipeline';

const ATTACHMENT_ASSISTANT_CLASS: string = 'attachment-assistant';

const PACKAGE_PRO: string      = 'pro';
const PACKAGE_STANDARD: string = 'standard';
const PACKAGE_ECONOMY: string  = 'economy';
const PACKAGE_CREDIT: string   = 'credit';
const PACKAGE_NONE: string     = 'none';

const SOURCE_ID_REACTION_FORM: number       = 3;
const SOURCE_ID_PROFESIA: number            = 1;
const SOURCE_ID_FACEBOOK: number            = 4;
const SOURCE_ID_LINKEDIN: number            = 5;
const SOURCE_ID_TWITTER: number             = 6;
const SOURCE_ID_RECOMMENDATION_FORM: number = 8;
const SOURCE_ID_MOVED: number               = 9;

const GOOGLE_TAG_MANAGER_ID: string = 'GTM-KQ9S7D';

const REFUSAL_REASON_EMPTY_ID: number          = 1;
const REFUSAL_REASON_OTHER_ID: number          = 3;
const REFUSAL_REASON_WITHOUT_REASON_ID: number = 1;
const REFUSAL_REASON_UNSUITABLE_ID: number     = 2;

const CANDIDATE_EVENT_TYPE_OTHER: number           = 0;
const CANDIDATE_EVENT_TYPE_INTERVIEW: number       = 1;
const CANDIDATE_EVENT_TYPE_PHONE_INTERVIEW: number = 2;
const CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW: number = 3;

const CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_ID_SK: number = 16;
const CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_ID_EN: number = 17;
const CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_ID_DE: number = 18;
const CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_ID_CS: number = 19;
const CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_ID_HU: number = 20;

const CANDIDATES_CHUNK_SIZE: number = 100;
const POST_CHUNK_SIZE: number       = 20;

const SERVICE_ID_TRIAL: number     = 2290;
const SERVICE_ID_FREE: number      = 2600;
const SERVICE_ID_BASIC_OLD: number = 2601;
const SERVICE_ID_BASIC: number     = 2790;
const SERVICE_ID_STANDARD: number  = 2602;
const SERVICE_ID_PRO: number       = 2603;

const CANDIDATE_OUTPUT_FLAG_HAS_EVENT: number             = 13;
const CANDIDATE_OUTPUT_FLAG_IS_CONTACTED: number          = 14;
const CANDIDATE_OUTPUT_FLAG_STAGE: number                 = 16;
const CANDIDATE_OUTPUT_FLAG_BASIC_INFO: number            = 22;
const CANDIDATE_OUTPUT_FLAG_JOB_HAS_QUESTIONNAIRE: number = 24;

const ALL_CANDIDATE_OUTPUT_FLAGS: number[] = [
    CANDIDATE_OUTPUT_FLAG_HAS_EVENT,
    CANDIDATE_OUTPUT_FLAG_BASIC_INFO,
    CANDIDATE_OUTPUT_FLAG_JOB_HAS_QUESTIONNAIRE,
];

const WIDTH_MODAL: number    = 704;
const WIDTH_NO_MODAL: number = 968;

const RESOLUTION_MOBILE: number  = 768;
const RESOLUTION_DESKTOP: number = 1128;

const RESOLUTION_MOBILE_PROFESIA: number  = 767;
const RESOLUTION_TABLET_PROFESIA: number  = 991;
const RESOLUTION_DESKTOP_PROFESIA: number = 1279;

const TEMPLATES_FILTER_ALL: number = -1;

const EMAIL_TEMPLATE_TYPE_EVENT: number                 = 1;
const EMAIL_TEMPLATE_TYPE_REFUSING: number              = 2;
const EMAIL_TEMPLATE_TYPE_COLLEAGUE_FORWARDING: number  = 3;
const EMAIL_TEMPLATE_TYPE_OTHER: number                 = 0;
const EMAIL_TEMPLATE_TYPE_QUESTIONNAIRE_REQUEST: number = 4;

const CANDIDATES_REDUCER_TYPE_DASHBOARD: string     = 'dashboard';
const CANDIDATES_REDUCER_TYPE_B2B_DASHBOARD: string = 'b2bDashboard';
const CANDIDATES_REDUCER_TYPE_GLOBAL_SEARCH: string = 'globalSearch';
const CANDIDATES_REDUCER_TYPE_JOB_DETAIL: string    = 'jobDetail';

const FEATURE_PERMISSION_CLASS_NAME: string = ' locked-feature';

const MARK_TRIAL_SUBSCRIPTION_PACKAGE_NAME: string = 'TRIAL';
const MARK_FREE_SUBSCRIPTION_PACKAGE_NAME: string  = 'FREE';
const MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME: string = 'BASIC';
const MARK_PRO_SUBSCRIPTION_PACKAGE_NAME: string   = 'PRO';

const ATTACHMENT_USE_TYPE_CANDIDATE: string   = 'candidate';
const ATTACHMENT_USE_TYPE_FILE_UPLOAD: string = 'fileUpload';

const SEND_EMAIL_IMMEDIATELY: string = 'immediately';
const SEND_EMAIL_LATER: string       = 'later';

const SCHEDULED_EMAIL_ACTION_TYPE_SCHEDULED: number = 1;
const SCHEDULED_EMAIL_ACTION_TYPE_SENT: number      = 2;
const SCHEDULED_EMAIL_ACTION_TYPE_CANCELLED: number = 3;
const SCHEDULED_EMAIL_ACTION_TYPE_ERROR: number     = 4;

const CANDIDATES_LIST_TYPE_JOB_DETAIL: number    = 1;
const CANDIDATES_LIST_TYPE_GLOBAL_SEARCH: number = 2;

const TEMPLATE_ASSISTANT_SAVE_AS_NEW_OPTION: number     = 1;
const TEMPLATE_ASSISTANT_SAVE_AS_CHANGED_OPTION: number = 2;

const TEMPLATE_ASSISTANT_ALL_SAVING_OPTIONS: number[] = [
    TEMPLATE_ASSISTANT_SAVE_AS_NEW_OPTION,
    TEMPLATE_ASSISTANT_SAVE_AS_CHANGED_OPTION,
];

const JOB_VIEW_AUTOMATIC: number = 0;
const JOB_VIEW_PIPELINE: number  = 1;
const JOB_VIEW_LIST: number      = 2;

const ACTION_PIPELINE: string   = 'pipeline';
const ACTION_CANDIDATES: string = 'candidates';
const ACTION_JOBS: string       = 'jobs';

const AGREEMENT_CONFIRMATION_ALIAS: string = 'agreementConfirmationText';

const RECOMMENDATION_TAG_INTERNAL: string = 'internal';

const CLOSE_JOB_EMAIL_SENDING_ACTIVE: number  = 1;
const CLOSE_JOB_EMAIL_SENDING_REFUSED: number = 2;
const CLOSE_JOB_EMAIL_SENDING_ALL: number     = 3;
const CLOSE_JOB_EMAIL_SENDING_CUSTOM: number  = 4;

const HISTORY_TYPE_EMAIL: string = 'email';
const HISTORY_TYPE_MOVE: string  = 'move';

const DEFAULT_PUBLIC_NOTES_OFF: number = 0;
const DEFAULT_PUBLIC_NOTES_ON: number  = 1;

const SHOW_IN_MOBILE_SIDEBAR: string = 'mobile';

const USER_ACTION_IGNORE: string = 'ignore';
const USER_ACTION_OK: string     = 'ok';
const USER_ACTION_UPDATE: string = 'update';

const WEBSOCKET_TIME_DO_NOT_CLOSE: number        = 0;
const WEBSOCKET_TIME_CLOSE_AUTOMATICALLY: number = 5000;

const CHUNK_LOAD_ERROR: string = 'ChunkLoadError';

const HISTORY_ATTACHMENT_ADDED: number = 1;

const INCORRECT_PASSWORD_IN_CV_VIEWER_ID: number = 2;

const NOTIFICATION_TYPE_EMAIL_RECEIVED: string = 'notificationTypeEmailReceived';
const NOTIFICATION_TYPE_SYSTEM: string         = 'notificationTypeSystem';

const VARIABLE_ACTION_TYPE_CANDIDATE: number        = 1;
const VARIABLE_ACTION_TYPE_EVENT: number            = 2;
const VARIABLE_ACTION_TYPE_USER: number             = 3;
const VARIABLE_ACTION_TYPE_QUESTIONNAIRE: number    = 4;
const VARIABLE_ACTION_TYPE_OUT_OF_OFFICE: number    = 5;
const VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW: number  = 6;
const VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD: number = 7;
const VARIABLE_ACTION_TYPE_OTHER: number            = 8;
const VARIABLE_ACTION_TYPE_POSITION: number         = 9;
const VARIABLE_OUT_OF_OFFICE_START_AT_ID: number    = 18;

const INTERNAL_INFO_LOCAL_STORAGE_KEY: string         = 'activeInternalInfo';
const TRANSLATION_KEYS_INFO_LOCAL_STORAGE_KEY: string = 'activeTranslationKeysInfo';

const TESTING_TYPE_GDPR_AGREEMENT_FILTER: string       = 'gdprAgreementFilter';
const TESTING_GDPR_AGREEMENT_FILTER_ANSWER_YES: number = 1;
const TESTING_GDPR_AGREEMENT_FILTER_ANSWER_NO: number  = 0;
const TESTING_TYPE_DASHBOARD_STATISTICS: string        = 'dashboardStatistics';
const TESTING_DASHBOARD_STATISTICS_ANSWER_YES: number  = 1;
const TESTING_DASHBOARD_STATISTICS_ANSWER_NO: number   = 0;
const TESTING_TYPE_DASHBOARD_NEWS: string              = 'dashboardNews';
const TESTING_DASHBOARD_NEWS_ANSWER_YES: number        = 1;
const TESTING_DASHBOARD_NEWS_ANSWER_NO: number         = 0;

const QUESTION_TYPE_TEXT_LENGTH_LIMIT: number = 5000;

const PAGES_TRANSITION_TIME: number = 350;

const OFFER_WORKPLACE_TYPE_ONSITE: number = 1;
const OFFER_WORKPLACE_TYPE_TRAVEL: number = 2;
const OFFER_WORKPLACE_TYPE_REMOTE: number = 3;
const OFFER_WORKPLACE_TYPE_HYBRID: number = 4;

const B2B_DASHBOARD_USER_STORAGE_KEY: string = 'b2bDashboardUser';

const MARK_2604: boolean = true;

const UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_SK: number = 21;
const UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_CS: number = 22;
const UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_EN: number = 23;
const UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_DE: number = 48229;
const UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_HU: number = 48230;

class Constants
{
    static get MARK_USER_ID(): number {
        return MARK_USER_ID;
    }

    static get PACKAGE_PRO(): string {
        return PACKAGE_PRO;
    }

    static get PACKAGE_STANDARD(): string {
        return PACKAGE_STANDARD;
    }

    static get PACKAGE_ECONOMY(): string {
        return PACKAGE_ECONOMY;
    }

    static get PACKAGE_CREDIT(): string {
        return PACKAGE_CREDIT;
    }

    static get PACKAGE_NONE(): string {
        return PACKAGE_NONE;
    }

    static get ALLOWED_LANGUAGES(): string[] {
        return ALLOWED_LANGUAGES;
    }

    static get DEFAULT_LANGUAGE(): string {
        return DEFAULT_LANGUAGE;
    }

    static get LANGUAGE_SK(): string {
        return LANGUAGE_SK;
    }

    static get LANGUAGE_CS(): string {
        return LANGUAGE_CS;
    }

    static get LANGUAGE_HU(): string {
        return LANGUAGE_HU;
    }

    static get LANGUAGE_EN(): string {
        return LANGUAGE_EN;
    }

    static get LANGUAGE_DE(): string {
        return LANGUAGE_DE;
    }

    static get LANGUAGE_CS_PROFESIA(): string {
        return LANGUAGE_CS_PROFESIA;
    }

    static get DOMAIN_SK(): string {
        return DOMAIN_SK;
    }

    static get DOMAIN_CZ(): string {
        return DOMAIN_CZ;
    }

    static get JOBS_LIST_FILTER_JOB_STATE_DEFAULT(): number {
        return JOBS_LIST_FILTER_JOB_STATE_DEFAULT;
    }

    static get JOBS_LIST_FILTER_JOB_STATE_ALL(): number {
        return JOBS_LIST_FILTER_JOB_STATE_ALL;
    }

    static get JOBS_LIST_FILTER_JOB_STATE_REASON_DEFAULT(): number {
        return JOBS_LIST_FILTER_JOB_STATE_REASON_DEFAULT;
    }

    static get JOBS_LIST_FILTER_JOB_STATE_REASON_ALL(): number {
        return JOBS_LIST_FILTER_JOB_STATE_REASON_ALL;
    }

    static get JOBS_LIST_SORT_BY_DEFAULT(): CommonObjectType {
        return JOBS_LIST_SORT_BY_DEFAULT;
    }

    static get JOBS_LIST_SORT_OPTIONS(): CommonObjectType {
        return JOBS_LIST_SORT_OPTIONS;
    }

    static get DASHBOARD_OTHER_JOBS_LIST_SORT_BY_DEFAULT(): CommonObjectType {
        return DASHBOARD_OTHER_JOBS_LIST_SORT_BY_DEFAULT;
    }

    static get DASHBOARD_RECENT_JOBS_LIST_SORT_BY_DEFAULT(): CommonObjectType {
        return DASHBOARD_RECENT_JOBS_LIST_SORT_BY_DEFAULT;
    }

    static get DASHBOARD_JOBS_LIST_SORT_OPTIONS(): CommonObjectType {
        return DASHBOARD_JOBS_LIST_SORT_OPTIONS;
    }

    static get JOBS_LIST_FILTER_BY_ADD_DATE_DEFAULT_VALUE(): number {
        return JOBS_LIST_FILTER_BY_ADD_DATE_DEFAULT_VALUE;
    }

    static get JOBS_LIST_FILTER_BY_ADD_DATE_TIMESPAN_VALUE(): number {
        return JOBS_LIST_FILTER_BY_ADD_DATE_TIMESPAN_VALUE;
    }

    static get JOBS_LIST_FILTER_BY_ADD_DATE_CLEARED(): number {
        return JOBS_LIST_FILTER_BY_ADD_DATE_CLEARED;
    }

    static get JOBS_LIST_FILTER_USER_ID_ALL(): number {
        return JOBS_LIST_FILTER_USER_ID_ALL;
    }

    static get JOB_STATE_CLOSED(): number {
        return JOB_STATE_CLOSED;
    }

    static get JOB_STATE_OPEN(): number {
        return JOB_STATE_OPEN;
    }

    static get JOB_STATE_CONCEPT(): number {
        return JOB_STATE_CONCEPT;
    }

    static get JOB_STATE_UNPAID(): number {
        return JOB_STATE_UNPAID;
    }

    static get JOB_STATE_INVISIBLE(): number {
        return JOB_STATE_INVISIBLE;
    }

    static get CANDIDATE_STAGE_ACCEPTED_ID(): number {
        return CANDIDATE_STAGE_ACCEPTED_ID;
    }

    static get CANDIDATE_STAGE_NEW_ID(): number {
        return CANDIDATE_STAGE_NEW_ID;
    }

    static get CANDIDATE_STAGE_UNKNOWN_ID(): number {
        return CANDIDATE_STAGE_UNKNOWN_ID;
    }

    static get CANDIDATE_STAGE_COLLECTED_ID(): number {
        return CANDIDATE_STAGE_COLLECTED_ID;
    }

    static get CANDIDATE_STATE_ACTIVE(): number {
        return CANDIDATE_STATE_ACTIVE;
    }

    static get CANDIDATE_STATE_DELETED(): number {
        return CANDIDATE_STATE_DELETED;
    }

    static get CANDIDATE_STATE_REFUSED(): number {
        return CANDIDATE_STATE_REFUSED;
    }

    static get SORT_TYPE_NUMERIC(): string {
        return SORT_TYPE_NUMERIC;
    }

    static get SORT_TYPE_TEXT(): string {
        return SORT_TYPE_TEXT;
    }

    static get SORT_TYPE_OBJECT(): string {
        return SORT_TYPE_OBJECT;
    }

    static get ATTACHMENT_TYPE_PHOTO(): number {
        return ATTACHMENT_TYPE_PHOTO;
    }

    static get ATTACHMENT_TYPE_OTHER(): number {
        return ATTACHMENT_TYPE_OTHER;
    }

    static get ATTACHMENT_TYPE_QUESTIONNAIRE_RESULT(): number {
        return ATTACHMENT_TYPE_QUESTIONNAIRE_RESULT;
    }

    static get ATTACHMENT_TYPE_RANKING(): number {
        return ATTACHMENT_TYPE_RANKING;
    }

    static get AGE_MIN(): number {
        return AGE_MIN;
    }

    static get AGE_MAX(): number {
        return AGE_MAX;
    }

    static get QUESTIONNAIRE_QUESTION_TYPE_ONE(): number {
        return QUESTIONNAIRE_QUESTION_TYPE_ONE;
    }

    static get QUESTIONNAIRE_QUESTION_TYPE_MORE(): number {
        return QUESTIONNAIRE_QUESTION_TYPE_MORE;
    }

    static get QUESTIONNAIRE_QUESTION_TYPE_TEXT(): number {
        return QUESTIONNAIRE_QUESTION_TYPE_TEXT;
    }

    static get QUESTIONNAIRE_QUESTION_TYPE_LONGTEXT(): number {
        return QUESTIONNAIRE_QUESTION_TYPE_LONGTEXT;
    }

    static get QUESTIONNAIRE_QUESTION_TYPE_SELECTBOX(): number {
        return QUESTIONNAIRE_QUESTION_TYPE_SELECTBOX;
    }

    static get QUESTIONNAIRE_QUESTION_TYPE_SALARY(): number {
        return QUESTIONNAIRE_QUESTION_TYPE_SALARY;
    }

    static get QUESTIONNAIRE_QUESTION_TYPE_DISPOSABLE_DATE(): number {
        return QUESTIONNAIRE_QUESTION_TYPE_DISPOSABLE_DATE;
    }

    static get QUESTIONNAIRE_ANSWER_VALUE_REQUIRED(): string {
        return QUESTIONNAIRE_ANSWER_VALUE_REQUIRED;
    }

    static get TEMPLATE_PHASE_INVITATION(): number {
        return TEMPLATE_PHASE_INVITATION;
    }

    static get TEMPLATE_PHASE_REFUSED(): number {
        return TEMPLATE_PHASE_REFUSED;
    }

    static get TEMPLATE_PHASE_SEND_TO_COLLEAGUE(): number {
        return TEMPLATE_PHASE_SEND_TO_COLLEAGUE;
    }

    static get TEMPLATE_PHASE_SEND_TO_APPLICANT(): number {
        return TEMPLATE_PHASE_SEND_TO_APPLICANT;
    }

    static get CANDIDATES_LIST_TABLE_VISIBLE_COLUMNS(): string[] {
        return CANDIDATES_LIST_TABLE_VISIBLE_COLUMNS;
    }

    static get CANDIDATES_LIST_SORT_BY_DEFAULT(): CommonObjectType {
        return CANDIDATES_LIST_SORT_BY_DEFAULT;
    }

    static get CANDIDATES_PIPELINE_SORT_BY_DEFAULT(): CommonObjectType {
        return CANDIDATES_PIPELINE_SORT_BY_DEFAULT;
    }

    static get CANDIDATES_LIST_SORT_OPTIONS(): CommonObjectType {
        return CANDIDATES_LIST_SORT_OPTIONS;
    }

    static get QUESTIONNAIRES_LIST_SORT_OPTIONS(): CommonObjectType {
        return QUESTIONNAIRES_LIST_SORT_OPTIONS;
    }

    static get EMAIL_TEMPLATES_LIST_SORT_OPTIONS(): CommonObjectType {
        return EMAIL_TEMPLATES_LIST_SORT_OPTIONS;
    }

    static get AUTO_ANSWERS_LIST_SORT_OPTIONS(): CommonObjectType {
        return AUTO_ANSWERS_LIST_SORT_OPTIONS;
    }

    static get PIPELINE_TEMPLATES_LIST_SORT_OPTIONS(): CommonObjectType {
        return PIPELINE_TEMPLATES_LIST_SORT_OPTIONS;
    }

    static get CANDIDATES_LIST_FILTER_BY_PHASE_ID_ALL(): string {
        return CANDIDATES_LIST_FILTER_BY_PHASE_ID_ALL;
    }

    static get CANDIDATES_LIST_FILTER_BY_PHASE_ID_ACTIVE(): string {
        return CANDIDATES_LIST_FILTER_BY_PHASE_ID_ACTIVE;
    }

    static get CANDIDATES_LIST_FILTER_BY_PHASE_ID_PASSIVE(): string {
        return CANDIDATES_LIST_FILTER_BY_PHASE_ID_PASSIVE;
    }

    static get CANDIDATES_LIST_FILTER_BY_STATE_ID_ALL(): string {
        return CANDIDATES_LIST_FILTER_BY_STATE_ID_ALL;
    }

    static get CANDIDATES_LIST_FILTER_BY_STATE_ID_ACTIVE(): string {
        return CANDIDATES_LIST_FILTER_BY_STATE_ID_ACTIVE;
    }

    static get CANDIDATES_LIST_FILTER_BY_STATE_ID_PASSIVE(): string {
        return CANDIDATES_LIST_FILTER_BY_STATE_ID_PASSIVE;
    }

    static get APPLICANTS_LIST_TYPE_APPLICATION(): string {
        return APPLICANTS_LIST_TYPE_APPLICATION;
    }

    static get APPLICANTS_LIST_TYPE_DATABASE(): string {
        return APPLICANTS_LIST_TYPE_DATABASE;
    }

    static get APPLICANTS_LIST_TYPE_DUPLICITIES(): string {
        return APPLICANTS_LIST_TYPE_DUPLICITIES;
    }

    static get APPLICANTS_LIST_TYPE_PHASE(): string {
        return APPLICANTS_LIST_TYPE_PHASE;
    }

    static get APPLICANTS_LIST_TYPE_SEARCH(): string {
        return APPLICANTS_LIST_TYPE_SEARCH;
    }

    static get APPLICANTS_LIST_TYPE_UNKNOWN(): string {
        return APPLICANTS_LIST_TYPE_UNKNOWN;
    }

    static get LIST_PAGE_SIZE(): number {
        return LIST_PAGE_SIZE;
    }

    static get LOADING_BOX_TYPE_SPINNER(): string {
        return LOADING_BOX_TYPE_SPINNER;
    }

    static get LOADING_BOX_TYPE_SPINNER_SMALL(): string {
        return LOADING_BOX_TYPE_SPINNER_SMALL;
    }

    static get LOADING_BOX_TYPE_PROGRESSBAR(): string {
        return LOADING_BOX_TYPE_PROGRESSBAR;
    }

    static get DEFAULT_DATETIME(): string {
        return DEFAULT_DATETIME;
    }

    static get DEFAULT_DATE(): string {
        return DEFAULT_DATE;
    }

    static get GLOBAL_SEARCH_TYPE_CANDIDATE(): string {
        return GLOBAL_SEARCH_TYPE_CANDIDATE;
    }

    static get GLOBAL_SEARCH_RESULTS_COUNT(): number {
        return GLOBAL_SEARCH_RESULTS_COUNT;
    }

    static get GLOBAL_SEARCH_TYPE_GLOBAL_LINK(): string {
        return GLOBAL_SEARCH_TYPE_GLOBAL_LINK;
    }

    static get GLOBAL_SEARCH_TYPE_EMPTY(): string {
        return GLOBAL_SEARCH_TYPE_EMPTY;
    }

    static get FILTER_BY_NAME(): string {
        return FILTER_BY_NAME;
    }

    static get FILTER_BY_STATE_WITH_STAGE(): string {
        return FILTER_BY_STATE_WITH_STAGE;
    }

    static get FILTER_BY_STATE(): string {
        return FILTER_BY_STATE;
    }

    static get FILTER_BY_NOTE(): string {
        return FILTER_BY_NOTE;
    }

    static get FILTER_BY_PHASE(): string {
        return FILTER_BY_PHASE;
    }

    static get FILTER_BY_TAG(): string {
        return FILTER_BY_TAG;
    }

    static get FILTER_BY_SOURCE(): string {
        return FILTER_BY_SOURCE;
    }

    static get FILTER_BY_REFUSED_AND_NOT_CONTACTED(): string {
        return FILTER_BY_REFUSED_AND_NOT_CONTACTED;
    }

    static get FILTER_BY_ANONYMIZED(): string {
        return FILTER_BY_ANONYMIZED;
    }

    static get FILTER_BY_NOT_CONTACTED(): string {
        return FILTER_BY_NOT_CONTACTED;
    }

    static get FILTER_BY_NO_GDPR_AGREEMENT(): string {
        return FILTER_BY_NO_GDPR_AGREEMENT;
    }

    static get FILTER_BY_ADD_DATE(): string {
        return FILTER_BY_ADD_DATE;
    }

    static get FILTER_BY_SCORE(): string {
        return FILTER_BY_SCORE;
    }

    static get FILTER_BY_CANDIDATE_IDS(): string {
        return FILTER_BY_CANDIDATE_IDS;
    }

    static get FILTER_BY_GDPR_AGREEMENT(): string {
        return FILTER_BY_GDPR_AGREEMENT;
    }

    static get FILTER_NAME_CANDIDATE_STATE(): string {
        return FILTER_NAME_CANDIDATE_STATE;
    }

    static get FILTER_NAME_CANDIDATE_SOURCES(): string {
        return FILTER_NAME_CANDIDATE_SOURCES;
    }

    static get FILTER_NAME_CANDIDATE_STATUS(): string {
        return FILTER_NAME_CANDIDATE_STATUS;
    }

    static get FILTER_NAME_CANDIDATE_STAGE(): string {
        return FILTER_NAME_CANDIDATE_STAGE;
    }

    static get FILTER_NAME_QUESTIONNAIRE_RESULTS(): string {
        return FILTER_NAME_QUESTIONNAIRE_RESULTS;
    }

    static get FILTER_NAME_PROCESS_STEP(): string {
        return FILTER_NAME_PROCESS_STEP;
    }

    static get FILTER_NAME_TAGS(): string {
        return FILTER_NAME_TAGS;
    }

    static get FILTER_NAME_OTHER(): string {
        return FILTER_NAME_OTHER;
    }

    static get FILTER_NAME_PERIOD(): string {
        return FILTER_NAME_PERIOD;
    }

    static get FILTER_NAME_GDPR_AGREEMENT(): string {
        return FILTER_NAME_GDPR_AGREEMENT;
    }

    static get USER_ROLE_MANAGER(): number {
        return USER_ROLE_MANAGER;
    }

    static get USER_ROLE_RECRUITER(): number {
        return USER_ROLE_RECRUITER;
    }

    static get USER_ROLE_BASIC(): number {
        return USER_ROLE_BASIC;
    }

    static get ATTACHMENT_TYPE_CV(): number {
        return ATTACHMENT_TYPE_CV;
    }

    static get ATTACHMENT_TYPE_MOTIVATION_LETTER(): number {
        return ATTACHMENT_TYPE_MOTIVATION_LETTER;
    }

    static get ATTACHMENT_TYPE_COVER_LETTER(): number {
        return ATTACHMENT_TYPE_COVER_LETTER;
    }

    static get EMAIL_EDITOR_FIELD_SEND_AT(): string {
        return EMAIL_EDITOR_FIELD_SEND_AT;
    }

    static get EMAIL_EDITOR_FIELD_SENDER_INFO(): string {
        return EMAIL_EDITOR_FIELD_SENDER_INFO;
    }

    static get EMAIL_EDITOR_FIELD_SENDER(): string {
        return EMAIL_EDITOR_FIELD_SENDER;
    }

    static get EMAIL_EDITOR_FIELD_BCC(): string {
        return EMAIL_EDITOR_FIELD_BCC;
    }

    static get EMAIL_EDITOR_FIELD_RECIPIENTS_INFO(): string {
        return EMAIL_EDITOR_FIELD_RECIPIENTS_INFO;
    }

    static get EMAIL_EDITOR_FIELD_RECIPIENT(): string {
        return EMAIL_EDITOR_FIELD_RECIPIENT;
    }

    static get EMAIL_EDITOR_FIELD_RECIPIENTS(): string {
        return EMAIL_EDITOR_FIELD_RECIPIENTS;
    }

    static get EMAIL_TYPE_TO_CANDIDATE(): number {
        return EMAIL_TYPE_TO_CANDIDATE;
    }

    static get EMAIL_TYPE_FROM_CANDIDATE(): number {
        return EMAIL_TYPE_FROM_CANDIDATE;
    }

    static get EMAIL_TYPE_OUT_OF_OFFICE(): number {
        return EMAIL_TYPE_OUT_OF_OFFICE;
    }

    static get EMAIL_TYPE_AUTO_REPLY(): number {
        return EMAIL_TYPE_AUTO_REPLY;
    }

    static get EMAIL_TYPE_TO_COLLEAGUE(): number {
        return EMAIL_TYPE_TO_COLLEAGUE;
    }

    static get TEMPLATE_TYPE_AUTO_ANSWER(): string {
        return TEMPLATE_TYPE_AUTO_ANSWER;
    }

    static get TEMPLATE_TYPE_EMAIL(): string {
        return TEMPLATE_TYPE_EMAIL;
    }

    static get TEMPLATE_TYPE_QUESTIONNAIRE(): string {
        return TEMPLATE_TYPE_QUESTIONNAIRE;
    }

    static get TEMPLATE_TYPE_PIPELINE(): string {
        return TEMPLATE_TYPE_PIPELINE;
    }

    static get ATTACHMENT_ASSISTANT_CLASS(): string {
        return ATTACHMENT_ASSISTANT_CLASS;
    }

    static get SUPER_ADMINS(): number[] {
        return SUPER_ADMINS;
    }

    static get SOURCE_ID_REACTION_FORM(): number {
        return SOURCE_ID_REACTION_FORM;
    }

    static get SOURCE_ID_RECOMMENDATION_FORM(): number {
        return SOURCE_ID_RECOMMENDATION_FORM;
    }

    static get SOURCE_ID_MOVED(): number {
        return SOURCE_ID_MOVED;
    }

    static get SOURCE_ID_PROFESIA(): number {
        return SOURCE_ID_PROFESIA;
    }

    static get SOURCE_ID_FACEBOOK(): number {
        return SOURCE_ID_FACEBOOK;
    }

    static get SOURCE_ID_LINKEDIN(): number {
        return SOURCE_ID_LINKEDIN;
    }

    static get SOURCE_ID_TWITTER(): number {
        return SOURCE_ID_TWITTER;
    }

    static get GOOGLE_TAG_MANAGER_ID(): string {
        return GOOGLE_TAG_MANAGER_ID;
    };

    static get REFUSAL_REASON_EMPTY_ID(): number {
        return REFUSAL_REASON_EMPTY_ID;
    };

    static get REFUSAL_REASON_OTHER_ID(): number {
        return REFUSAL_REASON_OTHER_ID;
    };

    static get REFUSAL_REASON_WITHOUT_REASON_ID(): number {
        return REFUSAL_REASON_WITHOUT_REASON_ID;
    };

    static get REFUSAL_REASON_UNSUITABLE_ID(): number {
        return REFUSAL_REASON_UNSUITABLE_ID;
    };

    static get CANDIDATES_LIST_FILTER_BY_ADD_DATE_TIMESPAN_VALUE(): number {
        return CANDIDATES_LIST_FILTER_BY_ADD_DATE_TIMESPAN_VALUE;
    }

    static get CANDIDATES_LIST_FILTER_BY_ADD_DATE_CLEARED(): number {
        return CANDIDATES_LIST_FILTER_BY_ADD_DATE_CLEARED;
    }

    static get CANDIDATES_LIST_FILTER_BY_ADD_DATE_DEFAULT_VALUE(): number {
        return CANDIDATES_LIST_FILTER_BY_ADD_DATE_DEFAULT_VALUE;
    }

    static get CANDIDATE_EVENT_TYPE_INTERVIEW(): number {
        return CANDIDATE_EVENT_TYPE_INTERVIEW;
    }

    static get CANDIDATE_EVENT_TYPE_PHONE_INTERVIEW(): number {
        return CANDIDATE_EVENT_TYPE_PHONE_INTERVIEW;
    }

    static get CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW(): number {
        return CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW;
    }

    static get CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_IDS(): CommonObjectType {
        return [
            {languageCode: LANGUAGE_SK, templateId: CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_ID_SK},
            {languageCode: LANGUAGE_EN, templateId: CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_ID_EN},
            {languageCode: LANGUAGE_DE, templateId: CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_ID_DE},
            {languageCode: LANGUAGE_CS, templateId: CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_ID_CS},
            {languageCode: LANGUAGE_HU, templateId: CANDIDATE_VIDEO_INTERVIEW_DEFAULT_TEMPLATE_ID_HU},
        ];
    }

    static get CANDIDATE_EVENT_TYPE_OTHER(): number {
        return CANDIDATE_EVENT_TYPE_OTHER;
    }

    static get CANDIDATE_EVENT_TYPES_DROPDOWN_OPTIONS(): CommonObjectType {
        return [
            {label: Translations.getStatic('stageId2'), id: CANDIDATE_EVENT_TYPE_INTERVIEW},
            {label: Translations.getStatic('phoneInterview'), id: CANDIDATE_EVENT_TYPE_PHONE_INTERVIEW},
            {label: Translations.getStatic('videoInterview'), id: CANDIDATE_EVENT_TYPE_VIDEO_INTERVIEW},
            {label: Translations.getStatic('other'), id: CANDIDATE_EVENT_TYPE_OTHER},
        ];
    }

    static get CANDIDATES_CHUNK_SIZE(): number {
        return CANDIDATES_CHUNK_SIZE;
    }

    static get CANDIDATE_OUTPUT_FLAG_BASIC_INFO(): number {
        return CANDIDATE_OUTPUT_FLAG_BASIC_INFO;
    };

    static get CANDIDATE_OUTPUT_FLAG_HAS_EVENT(): number {
        return CANDIDATE_OUTPUT_FLAG_HAS_EVENT;
    };

    static get CANDIDATE_OUTPUT_FLAG_JOB_HAS_QUESTIONNAIRE(): number {
        return CANDIDATE_OUTPUT_FLAG_JOB_HAS_QUESTIONNAIRE;
    };

    static get CANDIDATE_OUTPUT_FLAG_STAGE(): number {
        return CANDIDATE_OUTPUT_FLAG_STAGE;
    };

    static get CANDIDATE_OUTPUT_FLAG_IS_CONTACTED(): number {
        return CANDIDATE_OUTPUT_FLAG_IS_CONTACTED;
    };

    static get ALL_CANDIDATE_OUTPUT_FLAGS(): number[] {
        return ALL_CANDIDATE_OUTPUT_FLAGS;
    };

    static get POST_CHUNK_SIZE(): number {
        return POST_CHUNK_SIZE;
    };

    static get SERVICE_ID_TRIAL(): number {
        return SERVICE_ID_TRIAL;
    };

    static get SERVICE_ID_FREE(): number {
        return SERVICE_ID_FREE;
    };

    static get SERVICE_ID_BASIC_OLD(): number {
        return SERVICE_ID_BASIC_OLD;
    };

    static get SERVICE_ID_BASIC(): number {
        return SERVICE_ID_BASIC;
    };

    static get SERVICE_ID_STANDARD(): number {
        return SERVICE_ID_STANDARD;
    };

    static get SERVICE_ID_PRO(): number {
        return SERVICE_ID_PRO;
    };

    static get TEMPLATES_FILTER_ALL(): number {
        return TEMPLATES_FILTER_ALL;
    };

    static get EMAIL_TEMPLATE_TYPE_EVENT(): number {
        return EMAIL_TEMPLATE_TYPE_EVENT;
    };

    static get EMAIL_TEMPLATE_TYPE_COLLEAGUE_FORWARDING(): number {
        return EMAIL_TEMPLATE_TYPE_COLLEAGUE_FORWARDING;
    };

    static get WIDTH_MODAL(): number {
        return WIDTH_MODAL;
    }

    static get WIDTH_NO_MODAL(): number {
        return WIDTH_NO_MODAL;
    }

    static get RESOLUTION_MOBILE(): number {
        return RESOLUTION_MOBILE;
    }

    static get RESOLUTION_DESKTOP(): number {
        return RESOLUTION_DESKTOP;
    }

    static get RESOLUTION_MOBILE_PROFESIA(): number {
        return RESOLUTION_MOBILE_PROFESIA;
    }

    static get RESOLUTION_TABLET_PROFESIA(): number {
        return RESOLUTION_TABLET_PROFESIA;
    }

    static get RESOLUTION_DESKTOP_PROFESIA(): number {
        return RESOLUTION_DESKTOP_PROFESIA;
    }

    static get EMAIL_TEMPLATE_TYPE_REFUSING(): number {
        return EMAIL_TEMPLATE_TYPE_REFUSING;
    };

    static get EMAIL_TEMPLATE_TYPE_OTHER(): number {
        return EMAIL_TEMPLATE_TYPE_OTHER;
    };

    static get EMAIL_TEMPLATE_TYPE_QUESTIONNAIRE_REQUEST(): number {
        return EMAIL_TEMPLATE_TYPE_QUESTIONNAIRE_REQUEST;
    };

    static get CANDIDATES_REDUCER_TYPE_JOB_DETAIL(): string {
        return CANDIDATES_REDUCER_TYPE_JOB_DETAIL;
    };

    static get CANDIDATES_REDUCER_TYPE_DASHBOARD(): string {
        return CANDIDATES_REDUCER_TYPE_DASHBOARD;
    };

    static get CANDIDATES_REDUCER_TYPE_B2B_DASHBOARD(): string {
        return CANDIDATES_REDUCER_TYPE_B2B_DASHBOARD;
    };

    static get CANDIDATES_REDUCER_TYPE_GLOBAL_SEARCH(): string {
        return CANDIDATES_REDUCER_TYPE_GLOBAL_SEARCH;
    };

    static get FEATURE_PERMISSION_CLASS_NAME(): string {
        return FEATURE_PERMISSION_CLASS_NAME;
    };

    static get MARK_TRIAL_SUBSCRIPTION_PACKAGE_NAME(): string {
        return MARK_TRIAL_SUBSCRIPTION_PACKAGE_NAME;
    };

    static get MARK_FREE_SUBSCRIPTION_PACKAGE_NAME(): string {
        return MARK_FREE_SUBSCRIPTION_PACKAGE_NAME;
    };

    static get MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME(): string {
        return MARK_BASIC_SUBSCRIPTION_PACKAGE_NAME;
    };

    static get MARK_PRO_SUBSCRIPTION_PACKAGE_NAME(): string {
        return MARK_PRO_SUBSCRIPTION_PACKAGE_NAME;
    };

    static get ATTACHMENT_USE_TYPE_CANDIDATE(): string {
        return ATTACHMENT_USE_TYPE_CANDIDATE;
    };

    static get ATTACHMENT_USE_TYPE_FILE_UPLOAD(): string {
        return ATTACHMENT_USE_TYPE_FILE_UPLOAD;
    };

    static get SEND_EMAIL_IMMEDIATELY(): string {
        return SEND_EMAIL_IMMEDIATELY;
    };

    static get SEND_EMAIL_LATER(): string {
        return SEND_EMAIL_LATER;
    };

    static get SCHEDULED_EMAIL_ACTION_TYPE_SCHEDULED(): number {
        return SCHEDULED_EMAIL_ACTION_TYPE_SCHEDULED;
    };

    static get SCHEDULED_EMAIL_ACTION_TYPE_SENT(): number {
        return SCHEDULED_EMAIL_ACTION_TYPE_SENT;
    };

    static get SCHEDULED_EMAIL_ACTION_TYPE_CANCELLED(): number {
        return SCHEDULED_EMAIL_ACTION_TYPE_CANCELLED;
    };

    static get SCHEDULED_EMAIL_ACTION_TYPE_ERROR(): number {
        return SCHEDULED_EMAIL_ACTION_TYPE_ERROR;
    };

    static get CANDIDATES_LIST_TYPE_JOB_DETAIL(): number {
        return CANDIDATES_LIST_TYPE_JOB_DETAIL;
    };

    static get CANDIDATES_LIST_TYPE_GLOBAL_SEARCH(): number {
        return CANDIDATES_LIST_TYPE_GLOBAL_SEARCH;
    };

    static get TEMPLATE_ASSISTANT_SAVE_AS_NEW_OPTION(): number {
        return TEMPLATE_ASSISTANT_SAVE_AS_NEW_OPTION;
    };

    static get TEMPLATE_ASSISTANT_SAVE_AS_CHANGED_OPTION(): number {
        return TEMPLATE_ASSISTANT_SAVE_AS_CHANGED_OPTION;
    };

    static get TEMPLATE_ASSISTANT_ALL_SAVING_OPTIONS(): number[] {
        return TEMPLATE_ASSISTANT_ALL_SAVING_OPTIONS;
    };

    static get AGREEMENT_CONFIRMATION_ALIAS(): string {
        return AGREEMENT_CONFIRMATION_ALIAS;
    };

    static get JOB_VIEW_AUTOMATIC(): number {
        return JOB_VIEW_AUTOMATIC;
    };

    static get JOB_VIEW_PIPELINE(): number {
        return JOB_VIEW_PIPELINE;
    };

    static get JOB_VIEW_LIST(): number {
        return JOB_VIEW_LIST;
    };

    static get ACTION_PIPELINE(): string {
        return ACTION_PIPELINE;
    };

    static get ACTION_CANDIDATES(): string {
        return ACTION_CANDIDATES;
    };

    static get ACTION_JOBS(): string {
        return ACTION_JOBS;
    }

    static get RECOMMENDATION_TAG_INTERNAL(): string {
        return RECOMMENDATION_TAG_INTERNAL;
    };

    static get CLOSE_JOB_EMAIL_SENDING_ACTIVE(): number {
        return CLOSE_JOB_EMAIL_SENDING_ACTIVE;
    };

    static get CLOSE_JOB_EMAIL_SENDING_REFUSED(): number {
        return CLOSE_JOB_EMAIL_SENDING_REFUSED;
    };

    static get CLOSE_JOB_EMAIL_SENDING_ALL(): number {
        return CLOSE_JOB_EMAIL_SENDING_ALL;
    };

    static get CLOSE_JOB_EMAIL_SENDING_CUSTOM(): number {
        return CLOSE_JOB_EMAIL_SENDING_CUSTOM;
    };

    static get HISTORY_TYPE_EMAIL(): string {
        return HISTORY_TYPE_EMAIL;
    };

    static get HISTORY_TYPE_MOVE(): string {
        return HISTORY_TYPE_MOVE;
    };

    static get DEFAULT_PUBLIC_NOTES_ON(): number {
        return DEFAULT_PUBLIC_NOTES_ON;
    };

    static get DEFAULT_PUBLIC_NOTES_OFF(): number {
        return DEFAULT_PUBLIC_NOTES_OFF;
    };

    static get SHOW_IN_MOBILE_SIDEBAR(): string {
        return SHOW_IN_MOBILE_SIDEBAR;
    };

    static get USER_ACTION_IGNORE(): string {
        return USER_ACTION_IGNORE;
    };

    static get USER_ACTION_OK(): string {
        return USER_ACTION_OK;
    };

    static get USER_ACTION_UPDATE(): string {
        return USER_ACTION_UPDATE;
    };

    static get WEBSOCKET_TIME_DO_NOT_CLOSE(): number {
        return WEBSOCKET_TIME_DO_NOT_CLOSE;
    };

    static get WEBSOCKET_TIME_CLOSE_AUTOMATICALLY(): number {
        return WEBSOCKET_TIME_CLOSE_AUTOMATICALLY;
    };

    static get CHUNK_LOAD_ERROR(): string {
        return CHUNK_LOAD_ERROR;
    };

    static get USER_ROLE_EXTERNAL_EDITOR(): number {
        return USER_ROLE_EXTERNAL_EDITOR;
    };

    static get USER_ROLE_EXTERNAL_VISITOR(): number {
        return USER_ROLE_EXTERNAL_VISITOR;
    };

    static get HISTORY_ATTACHMENT_ADDED(): number {
        return HISTORY_ATTACHMENT_ADDED;
    };

    static get INCORRECT_PASSWORD_IN_CV_VIEWER_ID(): number {
        return INCORRECT_PASSWORD_IN_CV_VIEWER_ID;
    };

    static get NOTIFICATION_TYPE_EMAIL_RECEIVED(): string {
        return NOTIFICATION_TYPE_EMAIL_RECEIVED;
    };

    static get NOTIFICATION_TYPE_SYSTEM(): string {
        return NOTIFICATION_TYPE_SYSTEM;
    };

    static get VARIABLE_ACTION_TYPE_CANDIDATE(): number {
        return VARIABLE_ACTION_TYPE_CANDIDATE;
    };

    static get VARIABLE_ACTION_TYPE_EVENT(): number {
        return VARIABLE_ACTION_TYPE_EVENT;
    };

    static get VARIABLE_ACTION_TYPE_OUT_OF_OFFICE(): number {
        return VARIABLE_ACTION_TYPE_OUT_OF_OFFICE;
    };

    static get VARIABLE_ACTION_TYPE_QUESTIONNAIRE(): number {
        return VARIABLE_ACTION_TYPE_QUESTIONNAIRE;
    };

    static get VARIABLE_ACTION_TYPE_USER(): number {
        return VARIABLE_ACTION_TYPE_USER;
    };

    static get VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW(): number {
        return VARIABLE_ACTION_TYPE_VIDEO_INTERVIEW;
    };

    static get VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD(): number {
        return VARIABLE_ACTION_TYPE_MULTIPLE_FORWARD;
    };

    static get VARIABLE_ACTION_TYPE_OTHER(): number {
        return VARIABLE_ACTION_TYPE_OTHER;
    };

    static get VARIABLE_ACTION_TYPE_POSITION(): number {
        return VARIABLE_ACTION_TYPE_POSITION;
    };

    static get INTERNAL_INFO_LOCAL_STORAGE_KEY(): string {
        return INTERNAL_INFO_LOCAL_STORAGE_KEY;
    };

    static get TRANSLATION_KEYS_INFO_LOCAL_STORAGE_KEY(): string {
        return TRANSLATION_KEYS_INFO_LOCAL_STORAGE_KEY;
    };

    static get PROFESIA_SK_LOCAL(): string {
        return PROFESIA_SK_LOCAL;
    };

    static get PROFESIA_SK_MARK_LOCAL(): string {
        return PROFESIA_SK_MARK_LOCAL;
    };

    static get PROFESIA_CZ_LOCAL(): string {
        return PROFESIA_CZ_LOCAL;
    };

    static get PROFESIA_SK_TEST(): string {
        return PROFESIA_SK_TEST;
    };

    static get PROFESIA_CZ_TEST(): string {
        return PROFESIA_CZ_TEST;
    };

    static get PROFESIA_SK_MASTER(): string {
        return PROFESIA_SK_MASTER;
    };

    static get PROFESIA_CZ_MASTER(): string {
        return PROFESIA_CZ_MASTER;
    };

    static get TESTING_TYPE_GDPR_AGREEMENT_FILTER(): string {
        return TESTING_TYPE_GDPR_AGREEMENT_FILTER;
    };

    static get TESTING_GDPR_AGREEMENT_FILTER_ANSWER_YES(): number {
        return TESTING_GDPR_AGREEMENT_FILTER_ANSWER_YES;
    };

    static get TESTING_GDPR_AGREEMENT_FILTER_ANSWER_NO(): number {
        return TESTING_GDPR_AGREEMENT_FILTER_ANSWER_NO;
    };

    static get TESTING_TYPE_DASHBOARD_STATISTICS(): string {
        return TESTING_TYPE_DASHBOARD_STATISTICS;
    };

    static get TESTING_DASHBOARD_STATISTICS_ANSWER_YES(): number {
        return TESTING_DASHBOARD_STATISTICS_ANSWER_YES;
    };

    static get TESTING_DASHBOARD_STATISTICS_ANSWER_NO(): number {
        return TESTING_DASHBOARD_STATISTICS_ANSWER_NO;
    };

    static get TESTING_TYPE_DASHBOARD_NEWS(): string {
        return TESTING_TYPE_DASHBOARD_NEWS;
    };

    static get TESTING_DASHBOARD_NEWS_ANSWER_YES(): number {
        return TESTING_DASHBOARD_NEWS_ANSWER_YES;
    };

    static get TESTING_DASHBOARD_NEWS_ANSWER_NO(): number {
        return TESTING_DASHBOARD_NEWS_ANSWER_NO;
    };

    static get QUESTION_TYPE_TEXT_LENGTH_LIMIT(): number {
        return QUESTION_TYPE_TEXT_LENGTH_LIMIT;
    };

    static get PAGES_TRANSITION_TIME(): number {
        return PAGES_TRANSITION_TIME;
    };

    static get OFFER_WORKPLACE_TYPE_ONSITE(): number {
        return OFFER_WORKPLACE_TYPE_ONSITE;
    };

    static get OFFER_WORKPLACE_TYPE_HYBRID(): number {
        return OFFER_WORKPLACE_TYPE_HYBRID;
    };

    static get OFFER_WORKPLACE_TYPE_TRAVEL(): number {
        return OFFER_WORKPLACE_TYPE_TRAVEL;
    };

    static get OFFER_WORKPLACE_TYPE_REMOTE(): number {
        return OFFER_WORKPLACE_TYPE_REMOTE;
    };

    static get B2B_DASHBOARD_USER_STORAGE_KEY(): string {
        return B2B_DASHBOARD_USER_STORAGE_KEY;
    };

    static get VARIABLE_OUT_OF_OFFICE_START_AT_ID(): number {
        return VARIABLE_OUT_OF_OFFICE_START_AT_ID;
    };

    static get MARK_2604(): boolean {
        return MARK_2604;
    };

    static get UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_SK(): number {
        return UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_SK;
    }

    static get UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_CS(): number {
        return UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_CS;
    }

    static get UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_EN(): number {
        return UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_EN;
    }

    static get UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_DE(): number {
        return UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_DE;
    }

    static get UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_HU(): number {
        return UNSUITABLE_CANDIDATE_EMAIL_TEMPLATE_HU;
    }
}

export default Constants;
