import React from 'react';
import Grid from '../Grid';
import Divider from '../Divider';
import NotificationMessage from '../NotificationMessage';
import Translations from '../../helpers/Translations';

const SendEmailVideoInterviewInfo: React.FC = () => {
    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <NotificationMessage
                            title={Translations.getStatic('videoInterviewByProfesia')}
                            description={Translations.getStatic('videoInterviewByProfesiaInfo')}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider hidden />
        </>
    );
};

export default SendEmailVideoInterviewInfo;
