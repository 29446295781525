import { FormT } from '@profesia/mark-offer-form/components/content/form/types';
import { SuitableFor, LanguageRequirements } from '@profesia/mark-offer-form/codebooks';
import { PostData } from './types';

export const mapFormToPostData = (data: FormT): PostData => {
    const salaryValue =
        data.salary?.type === 'fixed'
            ? data.salary?.value?.toString()
            : data.salary?.from?.toString();
    const hasAddress = (data: FormT) => !!data.positionPlace?.address;

    return {
        title: data.jobTitle,
        language: data.language,
        positions: data.jobPositions.map((position: any) => ({
            positionId: Number(position.id),
            categoryId: Number(position.categoryId),
        })),
        jobTypes: data.employmentTypes,
        description: data.jobDescription,
        educationLevel: Number(data.educationLevel),
        workplaceTypeId: data.positionPlace?.type,
        place: hasAddress(data) ? data.positionPlace?.address[0]?.value : undefined,
        startDate: data.positionPlace?.startDate,
        skills: data.skills?.others.map((item: any) => ({
            skillId: Number(item.id),
            levelId: Number(item.level),
            skillName: item.value,
        })),
        regions: data.positionPlace?.regions?.map((region: any) => ({
            regionId: Number(region.id),
        })),
        personalityRequirements: data.skills?.personalSkills,
        benefits: data.benefits,
        educationSpecialization: data.educationSpecialization,
        refugeeFriendly: data.offerSuitableFor?.includes(SuitableFor.Refugees) ?? false,
        suitableForHighSchoolStudent:
            data.offerSuitableFor?.includes(SuitableFor.HighSchoolStudents) ?? false,
        suitableForUniversityStudent:
            data.offerSuitableFor?.includes(SuitableFor.UniversityStudents) ?? false,
        validForGraduate: data.offerSuitableFor?.includes(SuitableFor.Graduates) ?? false,
        drivingLicences: data.skills?.drivingLicence,
        experienceInField: data.skills?.experienceAtPosition,
        experienceYears: data.skills?.yearsOfExperience,
        googlePlaceId: hasAddress(data) ? data.positionPlace?.address[0]?.id : null,
        allLanguagesRequired: data.languages?.required === LanguageRequirements.All,
        languageSkills: data.languages?.items.map((item: any) => ({
            languageId: Number(item.id),
            levelId: Number(item.level),
        })),
        salary: {
            currencyId: Number(data.salary?.currency),
            salaryPeriodId: Number(data.salary?.period),
            minimumValue: salaryValue,
            maximumValue: data.salary?.type === 'fixed' ? null : data.salary?.to?.toString(),
            publishMaximumValue: data.salary?.type !== 'fixed' && data.salary?.showTo,
            additionalInformation: data.salary?.otherInformation ?? null,
        },
        noteForCandidate: data.noteForCandidate,
        numberOfOpenings: data.numberOfOpenings ? Number(data.numberOfOpenings) : 1,
        applicableUntil: data.applicableTill,
        applyWithoutResume: data.isApplicableWithoutCv,
        newCandidatesNotification: data.shouldBeNotifiedAboutNewResumes,
        referenceNumber: data.referenceNumber,
        ...(data.contactPersonId && { userId: data.contactPersonId }),
    };
};
