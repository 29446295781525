import React from 'react';
import PropTypes from 'prop-types';
import Link from '../helpers/overridden/Link';

const Header4MenuCreateForm = props => {
    if (props.isHidden === true) {
        return null;
    }

    return(
        <div className="template-header header-basic" style={{height: props.headerHeight}}>
            <div className="header__row">
                <div className="col-left width_full">
                    <div className="square-button">
                        {
                            props.backAction.hasOwnProperty('function') === true
                            && props.backAction.function !== null
                                ? <div role="link" onClick={props.backAction.function}>{props.backAction.text}</div>
                                : <Link to={props.backAction.action} gtmTag="create-job-link-to-jobs-list">{props.backAction.text}</Link>
                        }
                    </div>
                    <div className="header__title">
                        <h3 className="ellipsis">{props.headerText}</h3>
                    </div>
                </div>
            </div>
        </div>
    )
};

Header4MenuCreateForm.propTypes = {
    headerText: PropTypes.string,
    isHidden: PropTypes.bool,
    backAction: PropTypes.shape({
        action: PropTypes.string,
        function: PropTypes.func,
        icon: PropTypes.string,
        text: PropTypes.string,
    }),

};

Header4MenuCreateForm.defaultProps = {
    headerText: '',
    isHidden: false,
    backAction: {
        action: 'jobs',
        icon: 'cancel',
        text: 'Cancel',
        function: null,
    },
};

export default Header4MenuCreateForm;
